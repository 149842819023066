<template>
  <div class="container" ref="container" :class="{ oversize: isOversize, fill: !isOversize, expand: expand }" :style="styles"  @mouseover="hover" @mouseout="hout" @click="toggleExpand">
    <div class="sense" ref="sense" :style="{ height: maxHeight }"></div>
    <span class="image" :class="{ animate: animate }">
      <img ref="actualImage" :src="backendService.getBannerImage(banner, size)" />
    </span>
    <div class="overlay" v-if="isOversize">
      <i class="fas fa-arrow-down" />
    </div>
  </div>
</template>

<script lang="ts">

import { IBanner } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  name: 'BannerImage',

  inject: [ BACKEND_KEY ],

  components: {
  },  

  computed: {
    isOversize(): boolean {
      if (!this.auto) return false;
      return this.imageHeight > this.senseHeight;
    },
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },
    styles(): any {
      if (!this.auto) return {};
      if (this.expand) return {};
      return this.isOversize
        ? { maxHeight: this.maxHeight }
        : this.fillHeight ? { height: this.maxHeight } : {}
    }

  },

  data() {
    return {
      anim: null as Animation | null,
      expand: false,
      animate: false,
      senseHeight: 0,
      containerHeight: 0,
      imageHeight: 0,
      rsz: null as any | null,
      containerEl: null as HTMLElement | null,
      imageEl: null as HTMLElement | null,
      hoverTimeout: null as any
    }
  },

  mounted() {
    let el = this.containerEl = this.$refs.container as HTMLElement;
    let imageEl = this.imageEl = this.$refs.actualImage as HTMLElement;
    let senseEl = this.$refs.sense as HTMLElement;
    const rz = (<any>window).ResizeObserver;

    const check = () => {
      //console.log(el.clientHeight, el.scrollHeight);
      this.senseHeight = senseEl.clientHeight;
      this.containerHeight = el.clientHeight;
      this.imageHeight = el.scrollHeight;
      //console.log(this.isOversize, this.imageHeight, this.senseHeight, this.containerHeight);
    }

    let rsz = this.rsz = new rz((entries: any) => {
      check();
    });

    rsz.observe(el);
    rsz.observe(imageEl);

  },

  beforeUnmount() {
    if (this.rsz && this.containerEl) {
      this.rsz?.unobserve(this.containerEl);
      this.rsz?.unobserve(this.imageEl);
    }
  },

  props: {
    auto: {
      type: Boolean,
      default: false
    },
    fillHeight: {
      type: Boolean,
      default: true
    },
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    },
    size: { 
      type: String,
      default: 'small'
    },
    maxHeight: {
      type: String,
      default: '10em'
    }
  },

  methods: {

    stopAnim() {
      if (this.anim != null) {
        this.anim.pause();
        this.anim.currentTime = 0;
        this.anim = null;
      }
    },

    hover() {
      if (!this.isOversize) return;
      //console.log("hover!")
      this.hoverTimeout = setTimeout(() => {
        this.animate = true;
        //this.expand = true;
        this.hoverTimeout = false;
        // console.log("start animate..");

        let scrollSpeed = this.containerHeight / 3;
        let scrollHeight = Math.abs(this.containerHeight - this.imageHeight);
        let durScroll = scrollHeight / scrollSpeed;
        let durPause = 1;
        let dur = durScroll * 2 + durPause * 2;
        let t = durPause / dur;

        this.stopAnim();

        this.anim = this.imageEl!.animate(
          [
            { transform: 'translateY(0)', offset: 0 },
            { transform: `translateY(-${scrollHeight}px)`, offset: 0.5 - t },
            { transform: `translateY(-${scrollHeight}px)`, offset: 0.5 },
            { transform: 'translateY(0)', offset: 1 - t },
          ], {
            duration: 1000 * dur,
            iterations: Infinity
          }
        );

      }, 250);
    },

    hout() {
      // console.log("end animate..")
      this.stopAnim();
      this.animate = false;
      //this.expand = false;
      if (this.hoverTimeout) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
      }
    },

    toggleExpand() {
      this.expand = !this.expand;
      this.stopAnim();
    }
  }


})

</script>

<style lang="scss" scoped>

.container {

  position: relative;

  .sense {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }

  &.fill {
    &.alignBottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &.oversize {
    overflow: hidden;

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
      height: 2em;
      text-align: center;
      color: var(--text-color);
    }

    &.expand {
      z-index: 1;
      overflow: visible;
      .overlay {
        opacity: 0;
      }
    }
  }

}

@keyframes test {
  0%  { transform: translateY(0); }
  40% { transform: translateY(-100%); }
  50% { transform: translateY(-100%); }
  90% { transform: translateY(0); }
}

span.image {
  
  width: 100%;
  display: inline-block;
  img {
    width: 100%;
  }

  &.animate {
    // animation-name: "test";
    // animation-duration: 15s;
    // animation-iteration-count: infinite;
    // animation-timing-function: linear;
  }

}

</style>