<template>

  <div v-if="banner">
    <p>Download:</p>
    <ul>
      <li><a :href="`/static/banners/${banner.guid}.json`">JSON</a></li>
      <li><a :href="`/static/banners/${banner.guid}.kml`">KML</a></li>
      <li><a :href="`/static/banners/${banner.guid}.gpx`">GPX</a></li>
    </ul>
  </div>
  
</template>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner } from '../../../shared/src/types';

export default defineComponent({
  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },
  data() {
    return {
      icons: Object.freeze(icons)
    }
  },
  methods: {
  }
});

</script>

<style lang="scss" scoped>


</style>