
// https://element-plus.org/#/en-US/component/icon

export const icons = {

  share: 'fas fa-link',

  map: 'fas fa-map',

  geo: 'fas fa-map',

  agent: 'fas fa-user',

  rating: 'fas fa-star-half-alt',

  completed: 'far fa-check-square',
  completedTrue: 'fas fa-check-square',
  completedFalse: 'far fa-square',
  // completed: 'far fa-check-circle',
  // completedTrue: 'fas fa-check-circle',
  // completedFalse: 'far fa-circle',

  favorite: 'far fa-star',
  favoriteTrue: 'fas fa-star',
  favoriteFalse: 'far fa-star',

  bookmark: 'far fa-bookmark',
  bookmarkTrue: 'fas fa-bookmark',
  bookmarkFalse: 'far fa-bookmark',

  todo: 'far fa-calendar',
  todoTrue: 'fas fa-calendar-check',
  todoFalse: 'far fa-calendar',

  order: 'fas fa-sort-numeric-down',

  banner: 'fas fa-th',

  //mission: 'fas fa-route',
  //mission: 'fas fa-flag',
  //mission: 'fas fa-square',
  mission: 'fas fa-map-signs',

  location: 'fas fa-location-arrow',

  waypoint: 'fas fa-map-marker-alt',
  // waypoint: 'el-icon-location',

  //distance: 'fas fa-ruler-horizontal',
  //distance: 'fas fa-arrow-alt-h',
  distance: 'fas fa-drafting-compass',

  time: 'fas fa-clock',

  efficiency: 'fas fa-tachometer-alt',

  //uniques: 'fas fa-medal'
  uniques: 'fas fa-fingerprint'

}