<template>

  <div>

    <div class="p-grid">
      <div class="p-col-6 p-fluid">
        <small>Number of missions</small><br>
        <p-input-number v-model="bannerData.numMissions" :min="1" />
      </div>
      <div class="p-col-6 p-fluid">
        <small>Missions per row</small><br>
        <p-input-number :min="1" :max="6" v-model="bannerData.rowSize" />
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12">
        <small>
        Title: 
        <span class="selectHelper" v-if="!selectTitleFromMission" @click="selectTitleFromMission = true">select from missions..</span>
        <span class="selectHelper" v-if="selectTitleFromMission" @click="selectTitleFromMission = false">cancel/done</span>          
        </small>
      </div>
    </div>

    <div class="p-grid">

      <p-input-text
        class="p-col-12"
        v-if="!selectTitleFromMission"
        v-model="bannerData.title"
        placeholder="Enter a description of the banner"
        />

      <p-dropdown
        placeholder="Select"
        editable
        class="p-col-12"
        ref="selectMissionDescription"
        v-if="selectTitleFromMission"
        v-model="bannerData.title"
        :options="bannerData.missions"
        optionLabel="title"
        optionValue="title"
        @change="selectTitleFromMission = false"
        />        

    </div>

    <div class="tip"><small>The title should not include any #of# or #/# sequence information!</small></div>

    <div class="p-grid space-above">
      <div class="p-col-12">
        <small>Description:
        <span class="selectHelper" v-if="!selectDescriptionFromMission" @click="selectDescriptionFromMission = true">select from missions..</span>
        <span class="selectHelper" v-if="selectDescriptionFromMission" @click="selectDescriptionFromMission = false">cancel/done</span>          
        </small>
      </div>
    </div>

    <div class="p-grid">

      <p-text-area
        class="p-col-12"
        v-if="!selectDescriptionFromMission"
        type="textarea"
        :autoResize="true"
        :rows="1"
        v-model="bannerData.description"
        placeholder="Enter a description of the banner"
        />

      <p-dropdown
        placeholder="Select"
        class="p-col-12"
        ref="selectMissionDescription"
        v-if="selectDescriptionFromMission"
        v-model="bannerData.description"
        :options="bannerData.missions"
        optionLabel="description"
        optionValue="description"
        @change="selectDescriptionFromMission = false"
        />
    </div>

  </div>

</template>

<script lang="ts">

import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';

export default defineComponent({

  inject: [ BACKEND_KEY, 'state' ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {
      loading: false,
      icons: Object.freeze(icons),
      bannerData: {} as IBanner,
      selectTitleFromMission: false,
      selectDescriptionFromMission: false,
      rowSizes: [
        { value: 6, label: '6 - Regular' },
        { value: 5, label: '5' },
        { value: 4, label: '4' },
        { value: 3, label: '3 - Half' },
        { value: 2, label: '2' },
        { value: 1, label: '1' },
      ],
    }
  }

});

</script>

<style lang="scss" scoped>

.space-above {
  padding-top: 1em;
}

.selectHelper {
  color: var(--text-color-secondary);
  cursor: pointer;
  font-style: italic;
}

</style>