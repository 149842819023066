<template>
  <div ref="map" class="map">
  </div>
  <div style="display: none">
    <div ref="popup" class="popupBanner">
      <div v-if="popupBanner">
        <div class="title">{{ popupBanner.title }}</div>
        <img class="image" :key="`image-${popupBanner.guid}`" :src="`/static/images/banner-${popupBanner.guid}-tiny.jpg`">
        <div class="tools">
          <!-- <i class="fas fa-folder-plus" /> add-->
          <a :href="`/banner/${ popupBanner.slug || popupBanner.guid }`"><i class="fas fa-info" /> details</a>
          &middot;
          <a @click="toggleRoute(popupBanner)"><i class="fas fa-route" /> route</a>
        </div>
        <div class="stats">
          <i :class="icons.mission" /> {{ popupBanner.numMissions }} m 
          &middot;
          <i :class="icons.mission" /> {{ popupBanner.numWaypoints }} wp 
          &middot;
          <i :class="icons.distance" /> {{ formatDistance(popupBanner.distance )}} 
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

function iconCreateFunction(cluster: any) {
  var childCount = cluster.getChildCount();
  // console.log("iconCreateFunction", cluster);
  var c = 'marker-cluster-';
  if (childCount < 5) {
    c += 'small';
  }
  else if (childCount < 10) {
    c += 'medium';
  }
  else {
    c += 'large';
  }

  return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>',
    className: 'marker-cluster ' + c, iconSize: new L.Point(40, 40) });
}

import { formatDistance } from '../lib/utils';
import { icons } from '../lib/icons';

import * as L from 'leaflet';

import "leaflet/dist/leaflet.css";

import "leaflet.markercluster/dist/MarkerCluster.css";
// import "leaflet.markercluster/dist/MarkerCluster.Default.css";

const { MarkerCluster, MarkerClusterGroup } = require("leaflet.markercluster");

const icon = require('leaflet/dist/images/marker-icon.png');
const iconShadow = require('leaflet/dist/images/marker-shadow.png');

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

import { defineComponent } from 'vue';
import { filterValidWaypoints, findFirstWaypoint, findLastWaypoint, IBanner, IMissionWaypointEx } from '../../../shared/src/types';
import { TileDataState, ITileData, MercatorTileLoader } from '../lib/mercator-tile-loader';

const TileColors: {[key in TileDataState]: string} = {
  [TileDataState.Pending]: 'orange',
  [TileDataState.Loading]: 'yellow',
  [TileDataState.Failed]: 'red',
  [TileDataState.Aborted]: 'orange',
  [TileDataState.Loaded]: 'green'
}

type BannerTile = ITileData<IBanner>;

import { BACKEND_KEY, BackendService } from '../services/backend-service';

interface IBannerDetails {
  banner: IBanner;
  routeLayerGroup: L.LayerGroup<any>;
  routeVisible: boolean;
}

const colorRange = require("colour-range").default;

export default defineComponent({

  inject: [ BACKEND_KEY, "state" ],

  data() {
    return {

      icons,

      cachedIcons: {} as { [key: string]: L.Icon },

      bannerDetails: {} as {[guid: string]: IBannerDetails},

      popupVisible: false,

      tiles: [] as BannerTile[],
      banners: [] as IBanner[],
      popupBanner: undefined as IBanner | undefined,

      moveTimeout: null as any,

      center: new L.LatLng(59.91838719629693, 10.747590065002443),
      zoom: 15,

      TileColors,
      loader: undefined! as MercatorTileLoader<IBanner>,

      frozen: {
        map: null! as L.Map,
        bannerMarkersLayerGroup: null! as L.LayerGroup<L.Marker>,
        tilePolysLayerGroup: null! as L.LayerGroup<L.Polygon>,
        tilePolys: {} as {[key: string]: L.Polygon},
        bannerMarkers: {} as {[key: string]: L.Marker}
      },
    }
  },

  mounted() {

    this.setupLoader();

    if (this.$route.params.lat && this.$route.params.lng) {
      this.center = new L.LatLng(
        parseFloat(<any>this.$route.params.lat),
        parseFloat(<any>this.$route.params.lng)
      );
    }

    if (this.$route.params.zoom) {
      this.zoom = parseFloat(<any>this.$route.params.zoom);
    }

    let mapEl = this.$refs.map as HTMLElement;
    let map = L.map(mapEl).setView(this.center, this.zoom);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 19,
    }).addTo(map);

    let bannerMarkersLayerGroup = new MarkerClusterGroup({
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      // disableClusteringAtZoom: 16, // this is a problem: if 2 missions start on the same portal, they are put on top of eachother
      //maxClusterRadius: 30,
      iconCreateFunction
    });
    //this.frozen.bannerMarkersLayerGroup = L.layerGroup([]);
    bannerMarkersLayerGroup.addTo(map);

    let tilePolysLayerGroup = L.layerGroup([]);
    tilePolysLayerGroup.addTo(map);

    map.on('movestart', ev => {
      clearTimeout(this.moveTimeout);
      // console.log("move start");
    })

    map.on('moveend', ev => {
      // console.log("move end");
      this.center = map.getCenter();
      this.updateData();
    })

    map.on('zoomstart', ev => {
      map.closePopup();
      clearTimeout(this.moveTimeout);
      // console.log("zoom start", ev);
    })

    map.on('zoomend', ev => {
      // console.log("zoom end", ev);
      this.zoom = map.getZoom();
      this.updateData();
    })

    this.frozen = Object.freeze({
      map,
      tilePolysLayerGroup,
      bannerMarkersLayerGroup,
      tilePolys: {} as {[key: string]: L.Polygon},
      bannerMarkers: {} as {[key: string]: L.Marker}
    });

    this.updateData(true);
  },

  computed: {
    // map(): L.Map {
    //   return this.frozen.map;
    // },  
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  methods: {

    formatDistance,

    async toggleRoute(banner: IBanner) {

      let b = this.bannerDetails[banner.guid!];
      if (!b) {
        let bb = await this.backendService.getBanner(banner.guid!);
        b = this.bannerDetails[banner.guid!] = {
          banner: bb,
          routeLayerGroup: null!,
          routeVisible: false
        };
      }

      if (b.routeLayerGroup == null) {
        let lg = new L.LayerGroup<any>();
        let p: IMissionWaypointEx | undefined = undefined;

        let colors = colorRange(b.banner.missions!.length).map((c: string) => '#' + c);

        let lines: any[] = [];
        let connectors: any[] = [];

        b.banner.missions?.forEach((mission, i) => {
          let f = findFirstWaypoint(mission);
          if (p != null) {
            connectors.push([
              [ p!.point!.lat, p!.point!.lng ],
              [ f!.point!.lat, f!.point!.lng ]
            ]);
          }

          let waypoints = filterValidWaypoints(mission);
          let coords = waypoints.map(w => [ w.point.lat, w.point.lng ]);
          lines.push(coords);

          p = findLastWaypoint(mission);
        })

        let weight = 2;
        let weightExtra = 4;
        let radius = 4;
        let radiusExtra = 2;
        let bgColor = "black";
        lines.forEach((coords, i) => {
          let line = new L.Polyline(coords, { color: bgColor, weight: weight+weightExtra });
          lg.addLayer(line);
          let start = new L.CircleMarker(coords[0], {
            radius: radius+radiusExtra, color: bgColor, fillColor: bgColor, fill: true, fillOpacity: 1
          })
          lg.addLayer(start);
        });

        connectors.forEach(coords => {
          let cline = new L.Polyline(coords, { color: '#999', weight: weight, dashArray: '4,2' });
          lg.addLayer(cline);
        })

        lines.forEach((coords, i) => {
          let mission = b.banner.missions![i];
          let color = colors[i]
          let line = new L.Polyline(coords, { color: color, weight: weight });
          lg.addLayer(line);
          (<any>line).bindTooltip(mission.title);
          let start = new L.CircleMarker(coords[0], {
            radius: radius, color: color, fillColor: color, fill: true, fillOpacity: 1
          })
          lg.addLayer(start);
        });

        lg.addTo(this.frozen.map);
        b.routeLayerGroup = lg;
      } else {
        this.frozen.map.removeLayer(b.routeLayerGroup);
        b.routeLayerGroup = null!;
      }

    },

    setupLoader() {

      let cache: {[id: string]: BannerTile} = {};

      let makeTileKey = (tile: BannerTile): string => `${tile.x}_${tile.y}_${tile.z}`;

      const updateTilePoly = (tile: BannerTile) => {
        let key = makeTileKey(tile);
        let poly = this.frozen.tilePolys[key];
        if (poly) {
          if (tile.state == TileDataState.Loaded) {
            this.frozen.tilePolysLayerGroup.removeLayer(poly);
          }
          poly.setStyle({ fillColor: this.TileColors[tile.state], stroke: false })
        }
      }
      
      const addBanner = (banner: IBanner) => {

        let m = L.marker({
          lat: banner.point!.lat,
          lng: banner.point!.lng
        }, {
          title: banner.title,
          icon: this.getIcon(banner)
        });
        m.on('popupopen', ev => {
          this.popupBanner = banner;
        })
        m.bindPopup(<any>this.$refs.popup);

        // m.on('click', ev => {
        //   this.popupVisible = false;
        //   this.popupBanner = banner;
        //   this.$nextTick(() => {
        //     let popup = L.popup()
        //       .setLatLng({
        //         lat: banner.point!.lat,
        //         lng: banner.point!.lng
        //       }).setContent('<div id="leafletPopup"></div>')
        //       .openOn(this.map);
        //       this.$nextTick(() => {
        //         this.popupVisible = true;
        //       })
        //   })
        // });

        // console.log("add marker", guid, m);
        this.frozen.bannerMarkersLayerGroup.addLayer(m);

        this.frozen.bannerMarkers[banner.guid!] = m;
      }

      this.loader = new MercatorTileLoader({

        size: 256,
        concurrency: 10,
        minZoom: 2,
        maxZoom: 16,

        openRequest: tile => {
          let u = `/api/b/map/${tile.z}/${tile.x}/${tile.y}`;
          tile.xhr!.open("GET", u, true);
        },

        checkCache: tile => {
          let key = makeTileKey(tile);
          let cached = cache[key];
          // console.log("served from cache:", cached);
          return cached;
        },

        onTiles: tiles => {

          this.frozen.tilePolysLayerGroup.clearLayers();
          Object.keys(this.frozen.tilePolys).forEach(k => delete this.frozen.tilePolys[k]);
          //this.frozen.tilePolys = {};
          tiles.forEach(tile => {
            let key = makeTileKey(tile);
            let poly = new L.Polygon(this.makeTilePoly(tile), { color: "red" });
            this.frozen.tilePolysLayerGroup.addLayer(poly);
            this.frozen.tilePolys[key] = poly;
            updateTilePoly(tile);
          })

          //console.log("[ev] got tiles", tiles);
          this.tiles = tiles;
        },

        onResults: all => {

          this.frozen.tilePolysLayerGroup.clearLayers();

          // console.log("[ev] got complete result", all);
          this.banners = all;

          let prevMarkers = { ...this.frozen.bannerMarkers };

          all.forEach(banner => {
            let guid = banner.guid!;
            let m = this.frozen.bannerMarkers[guid];
            if (m) {
              // console.log("keep marker", guid, m);
              delete prevMarkers[banner.guid!];
            } else {
              addBanner(banner);
            }
          });

          Object.keys(prevMarkers).forEach(guid => {
              let m = prevMarkers[guid];
              // console.log("remove marker", guid, m);
              this.frozen.bannerMarkersLayerGroup.removeLayer(m);
              delete this.frozen.bannerMarkers[guid];
            })

        },

        onTileStatus: tile => {
          updateTilePoly(tile);
        },

        onTileResults: tile => {
          let key = makeTileKey(tile);
          // console.log("[ev] got individual tile", tile);
          if (tile.state == TileDataState.Loaded) {
            if (cache[key] != tile) {
              // console.log("stored in cache:", tile);
              cache[key] = tile;
            }
          }

          tile.data.forEach(banner => {
            let x = this.banners.find(y => y.guid == banner.guid);
            if (!x) {
              this.banners.push(banner);
              let guid = banner.guid!;
              let m = this.frozen.bannerMarkers[guid];
              if (!m) {
                addBanner(banner);
              }
            }
          })
        }
      });
    },

    updateData(immediate: boolean = false) {

      let z = this.frozen.map.getZoom();
      let b = this.frozen.map.getBounds();
      let c = this.frozen.map.getCenter();
      history.replaceState(null, '', `/map/${c.lat}/${c.lng}/${z}`);

      clearTimeout(this.moveTimeout);
      this.moveTimeout = setTimeout(() => {
        let [ n, e, s, w ] = [ b.getNorth(), b.getEast(), b.getSouth(), b.getWest() ];
        this.loader.update(n, e, s, w, z);
      }, immediate ? 0 : 500);
    },

    makeTilePoly(tile: ITileData<IBanner>) {
      return [
        [ tile.sw.lat, tile.sw.lng ], // sw
        [ tile.ne.lat, tile.sw.lng ], // se
        [ tile.ne.lat, tile.ne.lng ], // ne
        [ tile.sw.lat, tile.ne.lng ], // nw
      ]
    },

    getIcon(banner: IBanner): L.Icon {
      let sz = 35;

      let completed = banner.userData && banner.userData.completedBy;
      let todo = banner.userData && banner.userData.toDoBy;
      let favorited = banner.userData && banner.userData.favoritedBy;

      let type = completed ? "completed" : todo ? "todo" : favorited ? "favorited" : "regular";
      let key = `${type}_${banner.numMissions}`;
      let cached = this.cachedIcons[key];
      if (cached) return cached;

      // let fillColor = "hsl(0, 0%, 16%)";
      // let strokeColor = "hsl(170, 0%, 53%)";
      // let textColor = "hsl(170, 0%, 90%)";

      let hue = 0;
      let sat = 1;

      if (completed) {
        hue = 170;
      } else if (todo) {
        hue = 0;
      } else if (favorited) {
        hue = 45;
        sat = 0;
      } else {
        sat = 0;
      }

      let fillColor = `hsl(${hue}, ${27*sat}%, 45%)`;
      let strokeColor = `hsl(${hue}, ${27*sat}%, 75%)`;
      let textColor = `hsl(${hue}, ${69*sat}%, 100%)`;

      let svg = `<svg width="${sz}" height="${sz}" viewbox="-10 -10 120 120">
        <polygon
          points="50,0 0,25 0,75 50,100 100,75 100,25"
          style="fill: black; stroke: black; stroke-width: 20"
          />
        <polygon
          points="50,0 0,25 0,75 50,100 100,75 100,25"
          style="fill: ${fillColor}; stroke: ${strokeColor}; stroke-width: 10"
          />
        <text
          x="50"
          y="65"
          style="font: 45px sans-serif; fill: ${textColor}; text-anchor: middle"
          >${banner.numMissions}</text>
      </svg>`;

      var myIcon = L.divIcon({
        className: 'my-div-icon',
        iconSize: [ sz, sz ],
        iconAnchor: [ sz/2, sz/2 ],
        html: svg
      });

      this.cachedIcons[key] = myIcon;

      return myIcon;
    },

  }
})

</script>

<style lang="scss" scoped>

.map {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.popupBanner {
  width: 210px;
  .title {
    font-weight: bold;
    font-size: 1.2em;
  }
  .image {
    padding: 4px;
    background-color: black;
    max-height: 200px;
    overflow: hidden;
  }
  .tools {
    padding-top: 0.5em;
    font-size: 1.1em;
  }
  .stats {
    padding-top: 0.5em;
    font-size: 0.9em;
    color: #666;
  }
}
</style>

<style lang="scss">

.marker-cluster {
  text-shadow: 1px 1px 4px #000000;
  // -webkit-text-stroke-width: 2px;
  // -webkit-text-stroke-color: black;
}

.marker-cluster-large {
  background-color: rgba(218, 94, 94, 0.6);
  div {
    background-color: rgba(226, 36, 36, 0.6);

    // border-radius: 30px;
    // width: 60px;
    // height: 60px;
    // text-align: center;
    // box-sizing: border-box;
    // margin-left: -15px;
    // margin-top: -15px;
    // padding-top: 15px;

  }
}

.marker-cluster-medium {
  background-color: rgba(253, 156, 115, 0.6);
  div {
    background-color: rgba(241, 128, 23, 0.6);

    // border-radius: 30px;
    // width: 60px;
    // height: 60px;
    // text-align: center;
    // box-sizing: border-box;
    // margin-left: -15px;
    // margin-top: -15px;
    // padding-top: 15px;

  }
}

.marker-cluster-small {
  background-color: rgba(241, 211, 87, 0.6);
  div {
    background-color: rgba(240, 194, 12, 0.6);
  }
}

</style>