

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IAgent, IBanner, ICompletionReview, IUser, filterCommentReviews } from '../../../shared/src/types';

import { format, formatDistanceToNow } from 'date-fns'

export default defineComponent({
  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },
  computed: {
    comments(): ICompletionReview[] {
      return filterCommentReviews(this.banner?.reviews);
    }
  },
  data() {
    return {
      icons: Object.freeze(icons)
    }
  },
  methods: {

    formatText(s: string): string {
      return (s || "").replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/(\r\n|\r|\n)/g, "<br>");
    },

    formatDate(n: number): string {
      return formatDistanceToNow(n) + " ago";
    },

    lookupUser(userId: string): IAgent {
      let user: IUser = this.banner.$refs?.users?.[userId]!;
      if (user) {
        return <IAgent>{
          nickname: user.agentName,
          team: user.agentFaction
        }
      } else {
        return <IAgent>{}
      }
    }
  }
});

