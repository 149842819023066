

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutoScaleImage',
  props: {
    src: {
      type: String,
      required: true
    }
  }
})

