

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  props: {
    latLng: {
      type: Object as PropType<L.LatLngExpression>,
      required: true
    }
  },

  data() {
    return {
      frozen: { 
        marker: null! as L.Marker
      }
    }
  },

  mounted() {
    console.log("marker loaded");
    let layer = (<any>this).layer() as L.LayerGroup<any>;
    let marker = L.marker(this.latLng);
    layer.addLayer(marker);
    this.frozen = Object.freeze({ marker });
  },

  beforeUnmount() {
    console.log("marker unloaded");
    let layer = (<any>this).layer() as L.LayerGroup<any>;
    layer.removeLayer(this.frozen.marker);
  }

})

