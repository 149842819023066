<template>
  <div style="_background-color: red; width: 100%; height: 100%; overflow: hidden">
    <div style="display: flex; flex-direction: column; height: 100%; overflow: hidden">
      <div style="flex: 0; _background-color: orange">
        <slot name="top"></slot>
      </div>
      <div style="flex: 1; _background-color: gold; height: 100%; display: flex; flex-direction: row; overflow: hidden">
        <div :style="{ flex: left }" style="height: 100%; _background-color: green; overflow-y: auto">
          <slot name="left"></slot>
        </div>
        <div :style="{ flex: right }" style="height: 100%; width: 100%; _background-color: green; overflow-y: auto; overflow-x: hidden; padding-left: 20px">
          <slot name="right"></slot>
        </div>
      </div>
      <div style="flex: 0">
        <slot name="bottom"></slot>
      </div>
    </div>  
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TwoColumnLayout',
  props: {
    left: {
      type: Number,
      default: 1
    },
    right: {
      type: Number,
      default: 1
    }
  }
})

</script>
