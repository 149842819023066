const SphericalMercator = require('@mapbox/sphericalmercator');
const Queue = require('queue');

export enum TileDataState {
  Pending,
  Loading,
  Loaded,
  Failed,
  Aborted
}

export interface ITileData<T> {
  x: number;
  y: number;
  z: number;
  timestamp?: number;
  state: TileDataState;
  ne: L.LatLng;
  sw: L.LatLng;
  xhr?: XMLHttpRequest;
  data: Array<T>;
  promise?: Promise<ITileData<T>>;
}

export interface IMercatorTileLoaderSettings<T> {
  size: number;
  concurrency: number;
  minZoom: number;
  maxZoom: number;
  openRequest: (tile: ITileData<T>) => void;
  checkCache?: (tile: ITileData<T>) => ITileData<T> | undefined;
  onTileResults: (tile: ITileData<T>) => void;
  onTileStatus: (tile: ITileData<T>) => void;
  onResults: (results: T[]) => void;
  onTiles: (tiles: ITileData<T>[]) => void;
}

export class MercatorTileLoader<T> {

  tiles = [] as ITileData<T>[];

  tileQueue = Queue();

  options: IMercatorTileLoaderSettings<T>
  merc = undefined as any;

  constructor(options: IMercatorTileLoaderSettings<T>) {
    this.options = options;
    this.merc = new SphericalMercator({ size: options.size });
  }

  update(n: number, e: number, s: number, w: number, z: number) {

    const { merc } = this;

    if (z > this.options.maxZoom) z = this.options.maxZoom;
    else if (z < this.options.minZoom) z = this.options.minZoom;

    let xyz = merc.xyz([w,s,e,n], z);

    let currentTiles: ITileData<T>[] = [];
    for (let x = xyz.minX; xyz.maxX >= x; x++) {
      for (let y = xyz.minY; xyz.maxY >= y; y++) {
        let bbox = merc.bbox(x, y, z);
        let tile: ITileData<T> = {
          x,
          y,
          z,
          timestamp: Date.now(),
          sw: new L.LatLng(bbox[1], bbox[0]),
          ne: new L.LatLng(bbox[3], bbox[2]),
          data: [],
          state: TileDataState.Pending
        }
        if (this.options.checkCache) {
          let cachedTile = this.options.checkCache(tile);
          if (cachedTile) tile = cachedTile;
        }
        currentTiles.push(tile);
      }
    }

    // find out which - if any - of the currently loading tiles can be re-used
    let removeIndices: Array<number> = [];
    this.tiles.forEach((tile, i) => {
      let curTile = currentTiles.find(t => t.x == tile.x && t.y == tile.y && t.z == tile.z);
      if (curTile) {
        //console.log("[map] reusing tile", tile);
        currentTiles = currentTiles.map(et => et == curTile ? tile : et); // replace it
        removeIndices.push(i);
      }
    })
    removeIndices.reverse().map(i => {
      this.tiles.splice(i, 1); // delete
    })

    this.tiles = currentTiles;

    this.tileQueue.end(new Error("bounds updated"));

    let curQueue = this.tileQueue = Queue({ concurrency: this.options.concurrency, autostart: false });
    this.tiles.forEach(tile => {
      curQueue.push(() => {
        if (tile.promise) {
          //console.log("[map] already loading tile: %o", tile);
          tile.promise.then(() => {
            this.options.onTileResults(tile);
          })
          return tile.promise;
        }
        let p = new Promise<ITileData<T>>((resolve, reject) => {
          //console.log("[map] loading tile: %o", tile);
//          let u = `/api/b/map/${tile.z}/${tile.x}/${tile.y}`;
          let xhr = tile.xhr = new XMLHttpRequest();
          tile.state = TileDataState.Loading;
          this.options.onTileStatus(tile);
          this.options.openRequest(tile);
//          xhr.open("GET", u, true);
          xhr.onerror = (err) => {
            //console.error("[map] tile failed", tile);
            tile.state = TileDataState.Failed;
            this.options.onTileStatus(tile);
            resolve(tile);
          }
          xhr.onabort = () => {
            tile.state = TileDataState.Aborted;
            this.options.onTileStatus(tile);
            resolve(tile);
          }
          xhr.onreadystatechange = () => {
            if (xhr.readyState == 4) {
              //console.log("[map] tile completed", tile, xhr);
              tile.state = TileDataState.Loaded;
              this.options.onTileStatus(tile);
              tile.xhr = undefined;
              let body = xhr.response;
              if (typeof body !== 'object' || body == null) {
                try {
                  body = JSON.parse(xhr.responseText);
                } catch (err) {
                  //console.error("JSON parse error", err, xhr.response, xhr);
                  body = { results: [] };
                }
              }
              tile.data = body.results;
              this.options.onTileResults(tile);
              resolve(tile);
            }
          }
          xhr.send();
        })
        tile.promise = p;
        return p;
      })
    })

    this.options.onTiles(this.tiles);

    curQueue.on('end', (err: any) => {
      //console.log("[map] queue ended", err);

        if (curQueue.ended) return;
        curQueue.ended = true;

        if (err) {
          currentTiles.forEach(tile => {
            if (tile.xhr) {
              //console.warn("aborting tile", tile);
              tile.xhr.onreadystatechange = null;
              tile.xhr.abort();
            } else if (tile.state == TileDataState.Loaded) {
              //console.log("  was ready", tile);
            } else {
              //console.log("  was other state", tile);
            }
            tile.xhr = undefined;
            if (tile.state != TileDataState.Loaded) {
              tile.state = TileDataState.Aborted;
              this.options.onTileStatus(tile);
            }
          })
        }

        let chunks = currentTiles.map(c => c.data || []);
        let all = (<T[]>[]).concat(...chunks);
        this.options.onResults(all);
    })

    curQueue.start();
  }

}
