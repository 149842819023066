// https://lh3.googleusercontent.com/EJ07WCv6MbFIN0eWZHZya6vYd9ofL33MHB6l715eX6Wl-tmuTHbQP90VCqGRmArh8-tGORPJG1sVT1MlkOgJrSB5-4TVacY-5g=s50-c=w40

const url = require('url');

export function stripGoogleImageParams(imageUrl: string, replacement: string = ""): string {
  let u = url.parse(imageUrl);
  if (u.hostname?.toLowerCase().endsWith(".googleusercontent.com")) {
    delete (<any>u).path;    
    let m = u.pathname?.match(/^(.*?)=([^=]*)$/);
    if (m) {
      u.pathname = m[1] + replacement;
      return url.format(u);
    } else {
      u.pathname += replacement;
      return url.format(u);
    }
    // s## width/height
    // w## width
    // h## height
    // c   exact size
    // p   keep center
    // no  ?
  }
  return imageUrl;
}