<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SeparatedList',
  props: {
    separator: {
      type: String,
      default: ' > '
    },
    items: {
      type: Array,
      required: true
    }
  }
});

</script>

<template>
  <span class="list">
    <span v-for="(item, i) in items">
      <slot v-if="i > 0" name="separator">
        <span class="separator">{{separator}}</span>
      </slot>
      <slot :item="item" @click.prevent.stop="$emit('clicked', { item, i })">
        <span class="item">{{ item }}</span>
      </slot>
    </span>
  </span>
</template>

<style lang="scss" scoped>

.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    cursor: pointer;
  }
  .separator {
    color: #999;
    padding: 0 0.25em;
  }
}

</style>