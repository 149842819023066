import { App, defineComponent, h, watch, reactive } from 'vue';

let separator = " | ";

export interface ITitlePluginOptions {
  separator?: string;
}

interface ITitle {
  title: string;
  replace: boolean;
}

let state = reactive({
  stack: [] as ITitle[]
})

function updateTitle() {
  document.title = state.stack.map(x => x.title).join(separator);
}

watch(state, (to, from) => {
  //console.log("[title] stack changed!", to, from);
  updateTitle();
}, { deep: true })

const SetTitleComponent = defineComponent({
  
  name: 'set-title',
  
  render() {
    return h('span', { style: 'display: none' });
  },

  props: {
    title: {
      type: String,
      default: null
    },
    replace: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      cur: {} as ITitle
    }
  },

  mounted() {
    this.cur = {
      title: this.title == null ? <unknown>this.$route.meta.title as string : this.title,
      replace: this.replace
    };
    //console.log("[title] push", this.cur);
    state.stack.unshift(this.cur);
    updateTitle();
  },

  beforeUnmount() {
    //console.log("[title] pop", this.cur);
    state.stack = state.stack.filter(x => x != this.cur);
    updateTitle();
  }

})

export default {
  install: (app: App, options?: ITitlePluginOptions) => {
    //console.log("[title] installed");
    if (options && options.separator) separator = options.separator;
    app.component('set-title', SetTitleComponent);
  }
}