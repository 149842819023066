<template>
  <div style="display: none;">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { DomEvent } from 'leaflet'
import * as L from 'leaflet';

export default defineComponent({
  props: {
    options: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  async mounted() {
    const { MarkerCluster, MarkerClusterGroup } = await import("leaflet.markercluster");
    //this.mapObject = new MarkerClusterGroup(this.options);
    this.mapObject = new MarkerClusterGroup({
      iconCreateFunction(cluster) {
	      return L.divIcon({ html: '<b>' + cluster.getChildCount() + '</b>' });
      }
    })
    this.parentContainer = this.$parent.leafletObject;
    this.parentContainer.addLayer(this.mapObject);
    this.ready = true;
    this.$nextTick(() => {
      this.$emit('ready', this.mapObject);
    });
  },
  beforeUnmount() {
    this.parentContainer?.removeLayer(this.mapObject);
  },
  methods: {
    addLayer(layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject);
      }
    },
    removeLayer(layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject);
      }
    }
  }
})
</script>