

import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IMissionInfoDetailed, IBanner } from '../../../shared/src/types';
import { IState } from '../state';

export default defineComponent({

  inject: [ 'state', BACKEND_KEY ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },
  },

  props: {
    step: {
      type: Number
    },
    banner: {
      type: Object as PropType<IBanner>,
      //required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {
      loading: false,
      icons: Object.freeze(icons),
      bannerData: {} as IBanner,
      mapFocusMission: null as IMissionInfoDetailed | null,
      savedGuid: '',
      showSaved: false,
      showError: false,
      error: ''
    }
  },

  methods: {

    afterSave() {
      this.$router.push({ name: 'ViewBanner', params: { bannerId: this.savedGuid }});
    },

    async save() {

      let b = this.bannerData;

      let titles = b.missions!.map(m => m.title);
      if (titles.length > 1 && titles.indexOf(b.title) >= 0) {
        if (!confirm("⚠ WARNING ⚠\n\nThe banner title is the same as one of the mission titles - are you sure you remembered to remove any '2/24' or similar sequence information from the title?\n\n" + b.title + "\n\nContinue?"))
          return;
      }

      if (!confirm(`Are you sure you are ready to submit the banner?\nMake sure you have removed any sequence numbers from the title!\n\n${b.title}\n\n${b.description}`))
        return;

      b.missionGuids = b.missions!.map(m => m.guid);
      this.$emit('update:banner', b);

      b = { ... b};
      delete b.missions;
      //delete b.comments;

      try {
        this.loading = true;

        let b2 = (b.guid == null)
          ? await this.backendService?.createBanner(b)
          : await this.backendService?.updateBanner(b);

        console.log("SAVED BANNER ->", b2);

        let state = (<any>this).state as IState;
        state.editBanner = undefined;

        this.loading = false;

        this.showSaved = true;
        this.savedGuid = b2.slug! || b2.guid!;

      } catch (err) {

        this.loading = false;
        this.error = err.message;
        this.showError = true;

      }
    },

  },

});

