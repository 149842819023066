<template>
  <slot v-if="isReady"></slot>
</template>

<script lang="ts">

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';

function iconCreateFunction(cluster: any) {
  var childCount = cluster.getChildCount();
  // console.log("iconCreateFunction", cluster);
  var c = 'marker-cluster-';
  if (childCount < 5) {
    c += 'small';
  }
  else if (childCount < 10) {
    c += 'medium';
  }
  else {
    c += 'large';
  }

  return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>',
    className: 'marker-cluster ' + c, iconSize: new L.Point(40, 40) });
}

import "leaflet.markercluster/dist/MarkerCluster.css";
// import "leaflet.markercluster/dist/MarkerCluster.Default.css";

const { MarkerCluster, MarkerClusterGroup } = require("leaflet.markercluster");

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  provide() {
    return {
      layer: () => {
        return this.frozen.layerGroup;
      }
    }
  },

  props: {
    options: {
      type: Object,
      default: ({}),
    }
  },

  data() {
    return {
      isReady: false,
      frozen: { 
        layerGroup: null! as L.LayerGroup<any>
      }
    }
  },

  mounted() {
    console.log("markerclustergroup loaded");
    let map = (<any>this).map() as L.Map;

    let layerGroup = new MarkerClusterGroup({
      iconCreateFunction,
      ...this.options
    });

    layerGroup.addTo(map);
    this.frozen = Object.freeze({ layerGroup });
    this.isReady = true;
  },

  beforeUnmount() {
    console.log("markerclustergroup unloaded");
    let map = (<any>this).map() as L.Map;
    map.removeLayer(this.frozen.layerGroup);
  }

})

</script>


<style lang="scss">

.marker-cluster {
  text-shadow: 1px 1px 4px #000000;
  // -webkit-text-stroke-width: 2px;
  // -webkit-text-stroke-color: black;
}

.marker-cluster-large {
  background-color: rgba(218, 94, 94, 0.6);
  div {
    background-color: rgba(226, 36, 36, 0.6);

    // border-radius: 30px;
    // width: 60px;
    // height: 60px;
    // text-align: center;
    // box-sizing: border-box;
    // margin-left: -15px;
    // margin-top: -15px;
    // padding-top: 15px;

  }
}

.marker-cluster-medium {
  background-color: rgba(253, 156, 115, 0.6);
  div {
    background-color: rgba(241, 128, 23, 0.6);

    // border-radius: 30px;
    // width: 60px;
    // height: 60px;
    // text-align: center;
    // box-sizing: border-box;
    // margin-left: -15px;
    // margin-top: -15px;
    // padding-top: 15px;

  }
}

.marker-cluster-small {
  background-color: rgba(241, 211, 87, 0.6);
  div {
    background-color: rgba(240, 194, 12, 0.6);
  }
}

</style>