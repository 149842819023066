<template>
  <el-container>
    <el-main>
      <div v-if="state && state.user">please wait, logging you out..</div>
    </el-main>
  </el-container>
</template>

<script lang="ts">

import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Login',
  inject: [ 'state', BACKEND_KEY ],
  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },
  data() {
    return {
      user: null as any
    }
  },
  async mounted() {
    try {
      await this.backendService.logOut();
      (<any>this).state.user = null;
      this.$router.push({ path: '/' });

    } catch (err) {

      alert("ERROR: " + err);

    }
  }
});
</script>