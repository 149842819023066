

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionWaypointEx, WaypointObjectiveTextsShort, findFirstWaypoint, IMissionInfoDetailed } from '../../../shared/src/types';
import { formatDistance, formatTime, formatRating } from '../lib/utils';

export default defineComponent({

  computed: {
    missionRows(): Array<{ type: string, mission: IMissionInfoDetailed }> {
      if (this.banner) {
        let list: any = [];
        this.banner.missions?.forEach((m,i) => {
          list.push({ type: 'master', mission: m, index: i + 1 });
          list.push({ type: 'detail', mission: m, index: i + 1 });
        })
        //console.dir(list);
        return list;
      } else {
        return []
      }
    }
  },

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  data() {
    return {
      icons: Object.freeze(icons),
      intelLinks: false,
      revealWaypoints: false,
      expanded: {} as {[id: string]: boolean},
      WaypointObjectiveTextsShort,
    }
  },

  methods: {

    openWaypointInIntel(w: IMissionWaypointEx): void {
      window.open(`https://intel.ingress.com/ll=${w.point.lat},${w.point.lng}&pll=${w.point.lat},${w.point.lng}&z=17`);
    },

    toggleExpandAll() {
      let expand = true;
      let keys = Object.keys(this.expanded);
      if (keys.length > 0) expand = !this.expanded[keys[0]];
      this.expanded ={};
      this.banner?.missions?.forEach(m => this.expanded[m.guid] = expand);
    },

    formatDistance,
    formatTime,
    formatRating,
    findFirstWaypoint

  }

});

