

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Login',
  inject: ['state'],
  data() {
    return {
    }
  },
  methods: {
    login() {
      window.location.replace('/auth/google');
    }
  }
});
