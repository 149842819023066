

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';

function iconCreateFunction(cluster: any) {
  var childCount = cluster.getChildCount();
  // console.log("iconCreateFunction", cluster);
  var c = 'marker-cluster-';
  if (childCount < 5) {
    c += 'small';
  }
  else if (childCount < 10) {
    c += 'medium';
  }
  else {
    c += 'large';
  }

  return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>',
    className: 'marker-cluster ' + c, iconSize: new L.Point(40, 40) });
}

import "leaflet.markercluster/dist/MarkerCluster.css";
// import "leaflet.markercluster/dist/MarkerCluster.Default.css";

const { MarkerCluster, MarkerClusterGroup } = require("leaflet.markercluster");

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  provide() {
    return {
      layer: () => {
        return this.frozen.layerGroup;
      }
    }
  },

  props: {
    options: {
      type: Object,
      default: ({}),
    }
  },

  data() {
    return {
      isReady: false,
      frozen: { 
        layerGroup: null! as L.LayerGroup<any>
      }
    }
  },

  mounted() {
    console.log("markerclustergroup loaded");
    let map = (<any>this).map() as L.Map;

    let layerGroup = new MarkerClusterGroup({
      iconCreateFunction,
      ...this.options
    });

    layerGroup.addTo(map);
    this.frozen = Object.freeze({ layerGroup });
    this.isReady = true;
  },

  beforeUnmount() {
    console.log("markerclustergroup unloaded");
    let map = (<any>this).map() as L.Map;
    map.removeLayer(this.frozen.layerGroup);
  }

})

