

const nomar = require('nomar');
const sprintf = require('sprintf-js').sprintf;
const chineseNumbersConverter = require("chinese-numbers-converter");

import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';
import { parseSequence } from '../lib/parseSequence';

import { stripGoogleImageParams } from '../../../shared/src/googleImageParams';

export default defineComponent({

  inject: [ BACKEND_KEY, 'state' ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },
    reversedMissions: {
      get(): IMissionInfoDetailed[] {
        return [ ...this.bannerData.missions! ].reverse();
      },
      set(values: IMissionInfoDetailed[]) {
        this.bannerData.missions = [ ...values ].reverse();
      }
    }
  },

  props: {
    step: {
      type: Number
    },
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {
      loading: false,
      icons: Object.freeze(icons),
      bannerData: {} as IBanner,
      selectTitleFromMission: false,
      selectDescriptionFromMission: false,
      fixRoman: false,
      fixChinese: false,
      rowSizes: [
        { value: 6, label: '6 - Regular' },
        { value: 5, label: '5' },
        { value: 4, label: '4' },
        { value: 3, label: '3 - Half' },
        { value: 2, label: '2' },
        { value: 1, label: '1' },
      ],
    }
  },

  methods: {

    stripGoogleImageParams,

    async reloadMissions() {
    // reload missions with all details
      this.loading = true;
      try {
        let missions = await this.backendService?.getMissionsByGuids(this.bannerData.missions!.map(m => m.guid));
        //console.dir(missions);
        missions?.forEach(m2 => {
          let m = this.bannerData.missions!.find(x => x.guid == m2.guid);
          //console.log("upgrade: %o -> %o", m, m2);
          if (m != null) {
            Object.assign(m, m2); // will this work with reactivity?
          } else {
            console.error("could not find mission in addedMission:", m2);
          }
        })
      } finally {
        setTimeout(() => {
          //console.log("done loading")
          this.loading = false
        }, 500);
      }

      if (this.bannerData.description == null || this.bannerData.description == "") this.bannerData.description = this.bannerData.missions?.[0].description as string;
      if (this.bannerData.title == null || this.bannerData.title == "") this.bannerData.title = this.bannerData.missions?.[0].title as string;
    },

    sortAlpha(naturalize: boolean = false) {
      let list = [ ...this.bannerData.missions! ].sort((a, b) =>
        this.normalizeTitle(a.title, naturalize)
        .toLocaleLowerCase()
        .localeCompare(this.normalizeTitle(b.title, naturalize).toLocaleLowerCase()));
      if (confirm("Does this look correct?\n\n" + list.map(m => m.title).join("\n"))) {
        this.bannerData.missions = list;
      }
    },

    normalizeTitle(title: string, naturalize: boolean): string {
      // parse roman numerals
      if (this.fixRoman) {
        title = title.replace(/(\bM{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,4})(IL|IX|IV|V?I{0,4})\b)/g, x => {
          let n = x == "" ? "" : nomar(x);
          console.log(`roman ${x} -> arabic ${n}`)
          return n;
        })
      }
      // parse chinese numerals
      if (this.fixChinese) {
        let regex = new RegExp('([' + chineseNumbersConverter.characterList + ']+)', 'g');
        title = title.replace(regex, x => {
          if (x == "") return x;
          try {
            let n = new chineseNumbersConverter(x).toInteger();
            return n;
          } catch (err) {
            console.error("chinese number parsing failed", { x, err });
            return x;
          }
        })
      }
      // preprocess numbers
      if (naturalize) {
        title = title.replace(/(\d+)/g, x => sprintf("%010d", x));
      }
      // condense multiple spaces
      title = title.replace(/\s+/g, ' ');
      return title;
    },

    sortSmart() {
      let sequences = this.bannerData.missions!.map(m => {
        let title = this.normalizeTitle(m.title, false);
        return parseSequence(title);
      });
      let previewOrder = this.bannerData.missions!.map((m, i) => ({ m, i, seq: sequences[i]?.seq }));
      previewOrder.sort((a, b) => a.seq! - b.seq!);
      
      if (confirm("Does this look correct?\n\n" + previewOrder.map(m => m.m.title).join("\n"))) {
        this.bannerData.missions = previewOrder.map(x => x.m);
      }
    },

  },

  mounted() {
    this.reloadMissions();
  }

});

