<template>

  <TwoColumnLayout v-if="step == 0" :left="1" :right="0">

    <template #top></template>

    <template #left>

      <div class="instructions appContent">

        <p>Adding a banner requires that the data for each banner has been indexed first, a process that can be a bit cumbersome at times..</p>
        
        <p>This is in principle similar to the old method used with IngressMosaik:</p>

        <ol>
          <li class="x-space-below">
            Install and configure IITC 0.31.1 BETA or later - <a href="https://iitc.app">IITC-CE</a><br>
            <b>⚠ The old IITC from iitc.me does NOT work! ⚠</b>
          </li>

          <li class="x-space-below">
            Install or enable the <b>Missions plugin</b> in IITC

            <details>
              <summary>Optional step - gray area inside!</summary>
              <ul>
                <li>
                  Install <a href="https://softspot.nl/ingress/">DanielOnDiordna's Missions add-on plugin</a> - this makes finding mission start portals easier<br>
                  <a href="https://softspot.nl/ingress/plugins/iitc-plugin-missions-addon.user.js">iitc-plugin-missions-addon.user.js</a><br>
                  <b>⚠ Beware that this plug-in does several more Intel API calls than a normal Intel session or "missions in view" dialog would! Zoom in on a smaller area when using it to avoid Intel cool-downs!</b>
                </li>
              </ul>
            </details>
          </li>

          <li class="x-space-below">Install a special data-gathering plugin for IITC, which will record any mission data seen by IITC in the "Missions in view" dialog<br>
            <a href="/assets/iitc-plugin-specops-quest-helper.user.js">iitc-plugin-specops-quest-helper.user.js</a>
          </li>

          <li class="x-space-below">Browse the intel map and locate all <i>mission start portals</i>, i.e. use "Missions in view"</li>
          <li class="x-space-below">
          Then, to fetch mission details (waypoints) use the <b>🚩specops.quest</b> dialog to search for missions you have seen in the "missions in view" dialog. You will hopefully find them there, along with the indexing status. Use the 🔃 button if a mission is not indexed (✅).
          <ul>
            <li>If you can't find a mission, try zooming out and search again, or uncheck the "in bounds" checkbox and try searching by name</li>
          </ul>
          </li>

          <li>You can then use Step 2 here to search and add them to the banner</li>
        </ol>

        Ideally this should be combined into a single, more user-friendly plugin without the need for 2 windows. This is a get-it-done-quickly first take..

        <!--
        <h3>Method 2 - via Missions Creator</h3>
        <p>(not yet available)</p>
        <p>This is limited to mission you have created yourself!</p>
        <ol>
          <li>Install the TamperMonkey browser extension</li>
          <li>Install a TamperMonkey script for extracting mission data from the Missions Creator - <a href="/assets/missionscreator-extract-data.user.js">missionscreator-extract-data.user.js</a></li>
          <li>Go to the <a href="https://missions.ingress.com">Missions Creator</a></li>
          <li>Use the extended interface offered by the TamperMonkey script to export your missions</li>
        </ol>
        -->

        <p>
          Ready? Let's go!<br>
          <input type="checkbox" v-model="state.hideAddInstructions" id="hideAddInstructions"> <label for="hideAddInstructions">Skip these instructions the next time</label>
        </p>

      </div>

    </template>

    <template #right></template>
    
    <template #bottom>
      <div class="p-grid" style="padding: 1em">
        <div class="p-col-12 p-text-right">
          <p-button @click="$emit('goto-missions')" label="Next" icon="fas fa-arrow-right" />
        </div>
      </div>
    </template>
    
  </TwoColumnLayout>
  
</template>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner } from '../../../shared/src/types';

export default defineComponent({
  inject: [ 'state' ],
  props: {
    step: {
      type: Number
    },
    banner: {
      type: Object as PropType<IBanner>,
      //required: true
    }
  },
  data() {
    return {
      icons: Object.freeze(icons)
    }
  },
  methods: {
  }
});

</script>

<style lang="scss" scoped>

.fs {
  box-sizing: border-box;
  border: 1px solid green;
}

.instructions {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  line-height: 2em;

}

</style>