

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TwoColumnLayout',
  props: {
    left: {
      type: Number,
      default: 1
    },
    right: {
      type: Number,
      default: 1
    }
  }
})

