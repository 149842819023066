

import { defineComponent } from 'vue';
import { IBanner } from '../../../shared/src/types';
import BannerEditor from '../components/BannerEditor.vue';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { IState } from '../state';

export default defineComponent({

  name: 'AddBanner',

  inject: [ 'state', BACKEND_KEY ],

  components: {
    BannerEditor,
  },

  props: {
    mode: {
      type: String,
      default: 'add'
    },
    bannerId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      banner: {
        title: '',
        description: '',
        rowSize: 6,
        missions: [],
        missionGuids: [],
      } as IBanner
    }
  },

  computed: {

    title(): string {
      let mode = this.mode == 'add' ? 'Add banner' : 'Edit banner';
      let state = (<any>this).state as IState;
      let banner = state?.editBanner;
      if (banner) {
        let title = banner.title;
        return title == null || title != ""
          ? mode + ': ' + banner.title
          : mode;
      } else return mode;
    },

    basePath(): string {
      return this.mode == 'add' ? '/add' : '/edit/' + this.$route.params.bannerId;
    },

    steps(): Array<any> {
      return [
        {
          label: 'Instructions',
          to: this.basePath + '/instructions'
        },
        {
          label: 'Select missions',
          to: this.basePath + '/missions'
        },
        {
          label: 'Arrange and describe',
          to: this.basePath + '/details'
        },
        {
          label: 'Preview and save',
          to: this.basePath + '/preview'
        }
      ]
    },

    hasAccess(): boolean {
      let state = (<any>this).state;
      return state.user && state.user.roles && state.user.roles.indexOf('contributor') >= 0;
    },

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
      //return inject(BACKEND_KEY);
    },

  },

  async mounted() {

    // console.log("MOOOOUNTED", this.mode, this.hasAccess);

    if (this.hasAccess) {

      let state = (<any>this).state as IState;

      // console.log("BANNER - CREATED HOOK", this.mode)

      if (this.mode == 'edit') {

        let b = await this.backendService.getBanner(this.$route.params.bannerId as string);
        this.banner = state.editBanner = b;
        // console.log("LOADED BANNER", this.banner);

      } else {

        if (state.editBanner == undefined || state.editBanner.guid) {
          state.editBanner = {
            title: '',
            description: '',
            rowSize: 6,
            missions: [],
            missionGuids: [],
          }
        }
        this.banner = state.editBanner!;

      }
    }
  },

  methods: {

    gotoInstructions() {
      this.$router.push(this.basePath + '/instructions');
    },

    gotoMissions() {
      this.$router.push(this.basePath + '/missions');
    },

    gotoDetails() {
      this.$router.push(this.basePath + '/details');
    },

    gotoPreview() {
      this.$router.push(this.basePath + '/preview');
    },

    async save(banner: IBanner) {

      // console.log("SAVE -> %o", banner);

      // TODO: blocking progress modal..

      try {

        let b2 = await this.backendService?.createBanner(banner);

        let state = (<any>this).state as IState;
        state.editBanner = undefined;

        // TODO: better success dialog
        alert("Banner saved - image will be updated shortly..");

        this.$router.push({ name: 'ViewBanner', params: { bannerId: b2.guid! }});

      } catch (err) {

        // TODO: better error dialog
        alert("ERROR: " + err.message);

      }
    }

  }
});
