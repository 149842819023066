

import { defineComponent, PropType, watchEffect, WatchStopHandle } from 'vue';
import { IMissionInfoDetailed } from '../../../shared/src/types';

function drawCircleImage(image: HTMLImageElement, width?: number, height?: number): HTMLCanvasElement {
  let canvas = document.createElement("canvas");
  if (width == undefined) width = image.width;
  if (height == undefined) height = image.height;
  canvas.width = width;
  canvas.height = height;
  let ctx = canvas.getContext("2d");
  ctx!.drawImage(image, 0, 0, width, height);
  ctx!.globalCompositeOperation = "destination-in";
  ctx!.fillStyle = "#000";
  ctx!.beginPath();
  ctx!.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI);
  ctx!.fill();
  ctx!.globalCompositeOperation = "source-over";
  return canvas;
}

type ImageMap = {[url: string]: HTMLImageElement};

export default defineComponent({
  name: 'BannerMosaicPreview',
  props: {
    'missions': {
      type: Object as PropType<IMissionInfoDetailed[]>,
      required: true
    },
    'rowSize': {
      type: Number,
      default: 6
    },
    'tileSize': {
      type: Number,
      default: 32
    },
    'circle': {
      type: Boolean,
      default: false
    },
    'outline': {
      type: Number,
      default: 0
    },
    'padding': {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: true,
      loadProgress: 0,
      seq: '',
      updateNow: [] as Array<any>,
      stopWatcher: null as WatchStopHandle | null,
      cachedImages: {} as {[url: string]: HTMLImageElement}
    }
  },
  methods: {
    loadImage(url: string): Promise<HTMLImageElement> {
      let cached = this.cachedImages[url];
      if (cached) return Promise.resolve(cached);

      return new Promise<HTMLImageElement>((resolve, reject) => {
        console.log("loading image: " + url);
        let im = new Image();
        im.onload = () => {
          this.cachedImages[url] = im;
          resolve(im);
        }
        im.onerror = err => reject(err);
        im.src = url;
      });
    },

    async redraw() {
      //console.log("redraw", this.missions);
      const { tileSize, rowSize, missions, padding, outline } = this;
      let canvas = this.$refs.canvas as HTMLCanvasElement;
      if (missions && canvas) {
        let width = rowSize * (tileSize + padding * 2);
        let rows = (missions.length % rowSize)
          ? Math.ceil(missions.length / rowSize)
          : (missions.length / rowSize);
        let height = rows * (tileSize + padding * 2)

        canvas.width = width;
        canvas.height = height;

        let ctx = canvas.getContext("2d");
        ctx!.fillStyle = "black";
        ctx!.fillRect(0, 0, width, height);

        this.loading = true;
        this.loadProgress = 0;
        //let imagePromises = missions.map(m => this.loadImage(m.image));
        let imagePromises = missions.map(async m => {
          let im = this.loadImage(m.image);
          await im;
          this.loadProgress++;
          return im;
        });
        let images = await Promise.all(imagePromises);
        this.loading = false;

        images.reverse().forEach((image, index) => {
          let dy = Math.floor(index / rowSize);
          let dx = index % rowSize;

          let im: HTMLImageElement | HTMLCanvasElement;

          let x = dx * (tileSize + padding * 2) + padding;
          let y = dy * (tileSize + padding * 2) + padding;

          if (this.circle) {
            im = drawCircleImage(image);
          } else {
            im = image;
          }

          ctx!.drawImage(im, 0, 0, im.width, im.height, x, y, tileSize, tileSize);          

          if (outline > 0 && this.circle) {
            ctx!.beginPath();
            ctx!.lineWidth = outline;
            ctx!.arc(x + tileSize/2, y + tileSize/2, tileSize/2, 0, Math.PI*2);
            ctx!.strokeStyle = "#DFB975";
            ctx!.stroke();
          }

        })

      } else {
        console.error("Something is missing!", canvas, missions)
      }
    },
  },
  watch: {
    'missions': {
      immediate: true,
      deep: true,      
      handler(to) {
        console.log("missions changed", Date.now());
        this.seq = to ? to.map((m: IMissionInfoDetailed) => m.guid).join(",") : "";
      }
    },
    'updateNow': {
      immediate: true,
      handler() {
        this.redraw();
      }
    }
  },
  mounted() {
    this.redraw();
    this.stopWatcher = watchEffect(() => {      
      this.updateNow = [ this.seq, this.tileSize, this.rowSize, this.circle, this.outline ];
      //console.log("watch effect", Date.now(), this.seq);
      // if (dummy) dummy = [];
      //if (dummy) this.redraw();
    })
  },
  beforeUnmount() {
    this.stopWatcher?.();
  }
});

