<template>
  <div class="appContent">

    <h1>Edit profile</h1>
    <set-title title="Edit profile" />

    <div v-if="state.user && profile">

      <p-panel header="Profile" class="p-fluid">

        <!--
        <div class="p-field p-grid">
          <label :class="labelClass" for="userId">User ID</label>
          <div :class="valueClass">
            {{ profile._id }}
          </div>
        </div>
        -->

        <div class="p-field p-grid">
          <label :class="labelClass" for="created">Registered</label>
          <div :class="valueClass">{{ new Date(profile.created).toString() }}</div>
        </div>

        <div class="p-field p-grid">
          <label :class="labelClass" for="googleAccount">Google account</label>
          <div :class="valueClass">
            {{ profile.googleProfile.displayName }} ({{ profile.googleProfile.emails[0].value }})
          </div>
        </div>

      </p-panel>

      <p-panel header="Agent info" class="p-fluid space-above">

        <div class="p-field p-grid" v-if="haveAgentDetails">
          <label :class="labelClass"></label>
          <div :class="valueClass">
            <p-button @click="unlockAgentDetails" class="btn" label="Edit" />
          </div>
        </div>

        <div class="p-field p-grid">
          <label :class="labelClass" for="agentName">Agent name</label>
          <div :class="valueClass">
            <p-input-text v-model="profile.agentName" placeholder="Ingress agent name" :disabled="agentDetailsLocked && haveAgentDetails" />
          </div>
        </div>

        <div class="p-field p-grid">
          <label :class="labelClass" for="agentFaction">Faction</label>
          <p-dropdown
            v-model="profile.agentFaction"
            placeholder="Ingress faction"
            :disabled="agentDetailsLocked && haveAgentDetails"
            :options="teams"
            optionLabel="name"
            optionValue="value"
            />
        </div>

        <!--
        <div class="p-field p-grid" v-if="!profile.agentVerified">
          <label :class="labelClass" for="verificationChallenge">Verification challenge</label>
          <span class="tt">{{ profile.agentVerificationChallenge }}</span>
          
          <div style="display: none">
            Send a message in COMM:<br>
            <span class="tt">{{ verificationUsers }} verify {{ profile.agentVerificationChallenge }}</span>
          </div>
        </div>
        -->

      </p-panel>

      <p-panel header="API" class="p-fluid space-above">

        <div class="p-field p-grid">
          <label for="apiKey" :class="labelClass">API key</label>
          <div :class="valueClass">
            <span class="tt">{{ profile.apiKey }}</span> <p-button class="p-button-link" icon="fas fa-copy" @click="copyToClipboard(profile.apiKey)" title="Copy to clipboard" />
            <br><br>
            <p-button class="btn" icon="fas fa-key" @click="generateApiKey" label="Request" />
          </div>
        </div>

      </p-panel>

      <div class="p-grid space-above">
        <div class="p-col-12 p-text-right">
          <p-button icon="fas fa-save" label="Save" @click="save" class="btn" />
        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">

import { IUser } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { defineComponent } from 'vue';
import useClipboard from 'vue-clipboard3';

export default defineComponent({

  name: 'EditProfile',

  inject: [ 'state', BACKEND_KEY ],

  data() {
    return {
      labelClass: "p-lg-2 p-col-12",
      valueClass: "p-lg-10 p-col-12",
      agentDetailsLocked: true,
      profile: null as IUser | null,
      teams: [
        { value: 'E', name: 'Enlightened' },
        { value: 'R', name: 'Resistance' }
      ]
    }
  },

  setup() {
    const { toClipboard } = useClipboard();
    return {
      copyToClipboard(text: string) {
        toClipboard(text);
      }
    }
  },

  computed: {

    verificationUsers() {
      return [ ].map(m => '@'+m).join(' ');
    },

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },

    haveAgentDetails(): boolean {
      let state: any = (<any>this).state;
      return state.user.agentName != null && state.user.agentName != "";
    }

  },

  mounted() {
    let state = (<any>this)["state"];
    this.load(state.user);
  },

  watch: {
    'state.user'(to) {
      console.log("load user via state watcher");
      this.load(to);
    }
  },

  methods: {

    load(user: IUser) {
      this.agentDetailsLocked = true;      
      this.profile = user == null ? null : { ...user }; // copy
    },

    unlockAgentDetails() {
      if (confirm("You will have to verify your agent details again if you edit this - are you sure you want to continue?")) {
        this.agentDetailsLocked = false;
      }
    },

    async save() {
      try {
        let state = (<any>this)["state"];
        let user2 = await this.backendService.updateProfile(<IUser>{
          agentName: this.profile!.agentName,
          agentFaction: this.profile!.agentFaction,
        });
        state.user = user2;
        this.load(state.user);
      } catch (err) {
        alert("ERROR: " + err.message);
      }
    },

    async generateApiKey() {
      try {
        let state = (<any>this)["state"];
        this.profile!.apiKey = await this.backendService.requestNewApiKey();
      } catch (err) {
        alert("ERROR: " + err.message);
      }
    }

  }

});
</script>

<style lang="scss" scoped>

.tt {
  padding: 0.35em 0.35em;
  background-color: var(--surface-200);
  color: var(--text-color-secondary);
  font-family: Courier new;
  font-size: 0.9em;
}

.btn {
  width: 10em !important;
}

.space-above {
  margin-top: 1em;
}

</style>
