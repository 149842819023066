<template>
  <l-map
    ref="map"
    class="map"
    v-model="zoom"
    v-model:zoom="zoom"
    :center="center"
    :max-zoom="19"
    :min-zoom="5"
    v-model:center="center"
    
    @moveend="mapMoved"
    @zoomend="mapMoved"

    @ready="mapReady"
  >

    <l-tile-layer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    ></l-tile-layer>

    <l-control
      :position="'bottomleft'"
    >
      <div class="leaflet-bar">
        <a class="leaflet-bar-part" title="Toggle sidebar" style="cursor: pointer" @click="sidebar = !sidebar">
          <span v-if="sidebar">&lt;&lt;</span>
          <span v-else>&gt;&gt;</span>
        </a>
      </div>
    </l-control>

    <l-control
      :position="'topright'"
    >
      <div class="leaflet-bar">
        <a class="leaflet-bar-part" title="Show current location" style="cursor: pointer; padding-top: 0.15em" @click="goToLocation()">
          <i class="fas fa-location-arrow" />
        </a>
        <a class="leaflet-bar-part" title="Search" style="cursor: pointer; padding-top: 0.05em" @click="searchVisible = true">
          <i class="fas fa-search" />
        </a>
      </div>
    </l-control>

    <l-polygon
      v-for="tile in pendingTiles"
      color="red"
      :fill="true"
      :fillColor="TileColors[tile.state]"
      :interactive="false"
      :fillOpacity="0.15"
      :weight="2"
      :stroke="false"
      :lat-lngs="makeTilePoly(tile)"
      ></l-polygon>

<!--
    <l-marker-cluster-group v-if="banners">
      <l-marker
        v-for="b in banners"
        :key="b.guid"
        :lat-lng="[b.point.lat, b.point.lng]"
        :name="b.title"
        :icon="getIcon(b)"
        >
      </l-marker>

    </l-marker-cluster-group>
-->
    <l-circle-marker
      v-if="location"
      :lat-lng="location"
      :radius="8"
      :fill="true"
      fillColor="blue"
      :fillOpacity="1"
      >
      <l-tooltip>You are here!</l-tooltip>
    </l-circle-marker>

    <l-layer-group v-if="banners && mapReady">
      <l-marker
        v-for="b in banners"
        :key="b.guid"
        :lat-lng="[b.point.lat, b.point.lng]"
        :name="b.title"
        :icon="getIcon(b)"
        >
        <l-tooltip>
          <img :src="getBannerImage(b, 'tiny')"><br>
          <b>{{ b.title }}</b><br>
          <div style="width: 240px; white-space: normal">
            {{ b.description }}
          </div>
        </l-tooltip>
      </l-marker>


      <!--
      <l-circle-marker
        v-for="b in banners"
        :key="b.guid"

        :lat-lng="[b.point.lat, b.point.lng]"
        :name="b.title"
        :radius="8"
        :fill="true"
        color="#992E2C"
        fillColor="#E64543"
        :fillOpacity="1"
        >
        <l-tooltip>
          <img :src="getBannerImage(b, 'tiny')"><br>
          <b>{{ b.title }}</b><br>
          <div style="width: 240px; white-space: normal">
            {{ b.description }}
          </div>
        </l-tooltip>
      </l-circle-marker>
      -->
    </l-layer-group>

  </l-map>

  <p-sidebar v-model:visible="sidebar">
    hello!
  </p-sidebar>

  <p-sidebar v-model:visible="searchVisible" position="right" class="p-sidebar-lg" :showCloseIcon="false">
    <p-scroll-panel style="width: 100%; height: 100%; margin-top: 4.5em">
      <div class="p-fluid">
        <b>Search:</b><br>
        <div style="display: flex; flex-direction-row">
          <p-input-text style="flex: 1" type="text" v-model="locationSearch" @keydown.enter="search" />
          <p-button style="flex: 0; padding: 0 1.5em" title="Search" icon="fas fa-search" @click="search" />
        </div>
        <ol>
          <li v-for="res in searchResults.data">
            <a @click="gotoSearchResult(res)">{{ res.display_name }}</a><br>
            <span class="searchResultType" v-if="res.type">{{ res.type.replace(/_/g, ' ') }}</span>
          </li>
        </ol>
      </div>
    </p-scroll-panel>
  </p-sidebar>

  <set-title title="Map" />

</template>

<script lang="ts">

import axios from 'axios';

import { defineComponent } from 'vue';

const { LMap, LIcon, LTileLayer, LMarker, LLayerGroup, LCircleMarker, LControlLayers, LTooltip, LPopup, LPolyline, LPolygon, LRectangle, LControl } = require("@vue-leaflet/vue-leaflet");
import "leaflet/dist/leaflet.css";

import LMarkerClusterGroup from '@/components/LMarkerClusterGroup.vue';

// interface ILatLng {
//   lat: number;
//   lng: number;
// }

type LatLng = Array<number>[2] | { lat: number, lng: number };

import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { IBanner, ISearchResults } from '../../../shared/src/types';

const TileColors: {[key in TileDataState]: string} = {
  [TileDataState.Pending]: 'orange',
  [TileDataState.Loading]: 'yellow',
  [TileDataState.Failed]: 'red',
  [TileDataState.Aborted]: 'orange',
  [TileDataState.Loaded]: 'green'
}

let svg = `<svg width="40" height="40" viewbox="-10 -10 120 120">
  <polygon
    points="50,0 0,25 0,75 50,100 100,75 100,25"
    style="fill: #282828; stroke: #5BA79C; stroke-width: 10"
    />
  <text
    x="50"
    y="65"
    style="font: 50px sans-serif; fill: #85F4E4; text-anchor: middle"
    >64</text>
</svg>`;

import * as L from 'leaflet';

import { TileDataState, ITileData, MercatorTileLoader } from '../lib/mercator-tile-loader';

let CustomIcon = L.Icon.extend({
  options: {
    iconSize:     [40, 40],
    iconAnchor:   [20, 20],
  }
}); 

export default defineComponent({
  name: 'Map',

  inject: [ BACKEND_KEY, "state" ],
 
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LLayerGroup,
    LCircleMarker,
    LMarker,
    LControlLayers,
    LTooltip,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle,
    LControl,
    LMarkerClusterGroup
  },

  computed: {

    bounds() {
      let map = <{ center: LatLng, zoom: number, leafletObject: L.Map }> this.$refs.map; 
      let b = map.leafletObject.getBounds();
      return {
        n: b.getNorth(),
        e: b.getEast(),
        s: b.getSouth(), 
        w: b.getWest()
      };
    },

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },

    pendingTiles(): Array<ITileData<IBanner>> {
      return this.tiles.filter(tile => tile.state != TileDataState.Loaded);
    }

  },

  data() {
    return {
      tiles: [] as ITileData<IBanner>[],
      sidebar: false,
      searchVisible: false,
      locationSearch: '',
      searchResults: [],
      zoom: 15,
      iconWidth: 25,
      iconHeight: 40,
      center: { lat: 59.91838719629693, lng: 10.747590065002443 } as LatLng,
      moveTimeout: null as any,
      banners: [] as Array<IBanner>,
      location: null as LatLng | null,
      TileColors,
      loader: undefined! as MercatorTileLoader<IBanner>
    }
  },

  created() {

    let cache: {[id: string]: ITileData<IBanner>} = {};

    this.loader = new MercatorTileLoader({
      size: 256,
      concurrency: 10,
      minZoom: 2,
      maxZoom: 16,
      openRequest: tile => {
        let u = `/api/b/map/${tile.z}/${tile.x}/${tile.y}`;
        tile.xhr!.open("GET", u, true);
      },
      checkCache: tile => {
        let key = `${tile.x}_${tile.y}_${tile.z}`;
        let cached = cache[key];
        // console.log("served from cache:", cached);
        return cached;
      },
      onTiles: tiles => {
        //console.log("[ev] got tiles", tiles);
        this.tiles = tiles;
      },
      onResults: all => {
        //console.log("[ev] got complete result", all);
        this.banners = all;
        console.log("ALL!!!", all);
      },
      onTileStatus: tile => {
      },
      onTileResults: tile => {
        //console.log("[ev] got individual tile", tile);
        let key = `${tile.x}_${tile.y}_${tile.z}`;
        if (tile.state == TileDataState.Loaded) {
          if (cache[key] != tile) {
            // console.log("stored in cache:", tile);
            cache[key] = tile;
          }
        }
        tile.data.forEach(b => {
          let x = this.banners.find(y => y.guid == b.guid);
          if (!x) this.banners.push(b);
        })
      }
    });
  },

  methods: {

    gotoSearchResult(res: any) {
      this.center = { lat: res.lat, lng: res.lon };
      this.zoom = 16;
      this.searchVisible = false;
    },

    makeTilePoly(tile: ITileData<IBanner>) {
      return [ 
        [ tile.sw.lat, tile.sw.lng ], // sw
        [ tile.ne.lat, tile.sw.lng ], // se
        [ tile.ne.lat, tile.ne.lng ], // ne
        [ tile.sw.lat, tile.ne.lng ], // nw
      ]
    },

    goToLocation() {
      let first = true;
      navigator.geolocation.watchPosition(pos => {
        this.location = { lat: pos.coords.latitude, lng: pos.coords.longitude };
        if (first) {
          first = false;
          this.center = { lat: pos.coords.latitude, lng: pos.coords.longitude };
          this.zoom = 16;
        }
      }, err => {
        alert("sorry, can't get geo location")
      })
    },

    getBannerImage(banner: IBanner, size: string = 'small'): string {
      return `/static/images/banner-${banner.guid}-${size}.jpg`;
    },

    getIcon(banner: IBanner): L.Icon {

      let sz = 35;

      let completed = banner.userData && banner.userData.completedBy;
      let todo = banner.userData && banner.userData.toDoBy;
      let favorited = banner.userData && banner.userData.favoritedBy;

      // let fillColor = "hsl(0, 0%, 16%)";
      // let strokeColor = "hsl(170, 0%, 53%)";
      // let textColor = "hsl(170, 0%, 90%)";

      let hue = 0;
      let sat = 1;

      if (completed) {
        hue = 170;
      } else if (todo) {
        hue = 0;
      } else if (favorited) {
        hue = 45;
        sat = 0;
      } else {
        sat = 0;
      }

      let fillColor = `hsl(${hue}, ${27*sat}%, 35%)`;
      let strokeColor = `hsl(${hue}, ${27*sat}%, 63%)`;
      let textColor = `hsl(${hue}, ${69*sat}%, 100%)`;

      let svg = `<svg width="${sz}" height="${sz}" viewbox="-10 -10 120 120">
        <polygon
          points="50,0 0,25 0,75 50,100 100,75 100,25"
          style="fill: black; stroke: black; stroke-width: 20"
          />
        <polygon
          points="50,0 0,25 0,75 50,100 100,75 100,25"
          style="fill: ${fillColor}; stroke: ${strokeColor}; stroke-width: 10"
          />
        <text
          x="50"
          y="65"
          style="font: 45px sans-serif; fill: ${textColor}; text-anchor: middle"
          >${banner.numMissions}</text>
      </svg>`;

      var myIcon = L.divIcon({
        className: 'my-div-icon',
        iconSize: [ sz, sz ],
        iconAnchor: [ sz/2, sz/2 ],
        html: svg
      });

      return myIcon;

      // let url = encodeURI("data:image/svg+xml," + svg).replace('#','%23');
      // console.log(url);
      // let icon = new CustomIcon({iconUrl: url})
      // return icon;

      // let rows = banner.numMissions!/banner.rowSize;
      // let width = 128;
      // return L.icon({
      //   iconUrl: `/static/images/banner-${banner.guid}-tiny.jpg`, // `/static/images/` + banner.images!.small, 
      //   iconSize: [ width, Math.round((width/6)*rows) ],
      //   iconAnchor: [ 0, 0 ]
      // })
    },

    mapReady() {
      this.mapMoved(false);

      // let map = <{ center: LatLng, zoom: number, leafletObject: L.Map }> this.$refs.map; 
      // var markers = new MarkerClusterGroup();

    },

    mapMoved(delay: boolean = true) {

      let map = <{ center: LatLng, zoom: number, leafletObject: L.Map }> this.$refs.map; 
      //console.log("map moved: %o c=%o z=%s", map, map.center, map.zoom, map.leafletObject.getBounds());
      
      clearTimeout(this.moveTimeout);
      this.moveTimeout = setTimeout(async () => {

        let z = map.zoom;
        let b = map.leafletObject.getBounds();
        let [ n, e, s, w ] = [ b.getNorth(), b.getEast(), b.getSouth(), b.getWest() ];

        this.loader.update(n, e, s, w, z);

      }, delay ? 500 : 0);
    },

    async search() {
      let query = this.locationSearch.trim();
      if (query != '') {
        let b = this.bounds;
        this.searchResults = await axios.get('https://nominatim.openstreetmap.org/search'
          + '?format=json&q=' + encodeURIComponent(query)
          + `&viewbox=${b.n},${b.e},${b.s},${b.w}&bounded=0`
        );
      }
    }
  }

});
</script>

<style lang="scss" scoped>

@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.map {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.searchResultType {
  font-size: 0.9em;
  color: var(--text-color-secondary);
}

</style>