export interface ISequence {
  seq: number;
  total?: number;
};

export function parseSequence(title: string): ISequence | undefined {
  let m = title.replace(/[#]/g, ' ').match(/(\d+)\s*(\/|-|of)\s*(\d+)/);
  if (m && m[2].trim().length == 0) m = null;
  if (!m) m = title.replace(/[#]/g, ' ').match(/(\d+)\s*([^\d\s]+?)\s*(\d+)/);
  if (m) {
    return { seq: parseInt(m[1]), total: parseInt(m[3]) };
  } else {
    m = title.match(/#(\d+)/);
    if (m == null) m = title.match(/(\d+)/);
    if (m) {
      return { seq: parseInt(m[1]) };
    // let nums = title.match(/(?=[^\d]|\b)(\d+)(?<=\b|[^\d])/g);
    // if (nums) {
    //   //nums = nums.map(x => parseInt(x.replace(/[^0-9]/g, '')));
    //   console.dir(nums);
    // }
    }
  }
}

