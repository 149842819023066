<template>

  <div class="p-grid">
    <div class="p-col-6 p-text-left">
      <span style="margin-left: 1em" class="link" @click="toggleExpandAll()">(expand/collapse all)</span>
    </div>
    <div class="p-col-6 p-text-right">
      <p-checkbox id="revealWaypoints" v-model="revealWaypoints" :binary="true" />
      <label for="revealWaypoints"> reveal waypoints</label>
    </div>
    <!--
    <div class="p-col-3 p-text-right">
      <p-checkbox id="intelLinks" v-model="intelLinks" :binary="true" />
      <label for="intelLinks"> show intel links</label>
    </div>
    -->
  </div>

  <div style="text-align: right; padding-bottom: 1em">
  </div>

  <table class="missions">
    <thead>
      <tr>
        <th width="60">#</th>
        <th>Title</th>
        <th width="100" class="r"><i :class="icons.waypoint" /> Waypoints</th>
        <th width="100" class="r"><i :class="icons.distance" /> Distance</th>
        <th width="100" class="r"><i :class="icons.time" /> Time</th>
      </tr>
    </thead>
    <tbody>

<!--
      <tr
        v-for="(mission, i) in banner.missions"
        :key="`${mission.guid}_mission`"
        @click="expanded[mission.guid] = !expanded[mission.guid]"
        >
-->        
      <tr 
        v-for="(row, i) in missionRows"
        :key="`${row.mission.guid}_${row.type}`"
        >

        <td v-if="row.type == 'detail'" colspan="1">
        </td>

        <td v-if="row.type == 'detail'" colspan="4">

          <div class="details" v-if="expanded[row.mission.guid]">
            <div class="description">{{row.mission.description}}</div>
          </div>         

          <span class="objectives" v-if="!revealWaypoints">
            <span v-for="(w,i) in row.mission.waypoints" :key="`${row.mission.guid}_objectives_${i}`">
              <span v-if="w.hidden" class="hidden">Hidden</span>
              <span v-else-if="w.point == null || w.point.lat == null || w.point.lng == null" class="unavailable">Unavailable</span>
              <span v-else :title="w.title">
                <map-ref :waypoint="w">{{ WaypointObjectiveTextsShort[w.objective] }}</map-ref>
                <!-- <intel-link v-if="intelLinks" :waypoint="w" /> -->
              </span>
            </span>
          </span>

          <div class="waypoints x-space-above" v-if="revealWaypoints">
            <div class="p-grid" v-for="(w, i) in row.mission.waypoints" :key="`${row.mission.guid}_waypoints_${i}`">
              <div class="p-col-1 index">#{{ i+1 }}</div>
              <div class="p-col-11">
                <span v-if="w.hidden" class="hidden">Hidden</span>
                <span v-else-if="w.point == null || w.point.lat == null || w.point.lng == null" class="unavailable">Unavailable</span>
                <span v-else>
                  <map-ref :waypoint="w">
                    <span class="objective">{{ WaypointObjectiveTextsShort[w.objective] }} :</span> {{ w.title}} <intel-link v-if="intelLinks" :waypoint="w" />
                  </map-ref>
                </span>
              </div>
            </div>
          </div>

        </td>

        <td v-if="row.type == 'master'" @click="expanded[row.mission.guid] = !expanded[row.mission.guid]">
          <span class="p-d-none p-d-md-inline">
            <i class="fas fa-folder-open" v-if="expanded[row.mission.guid]" />
            <i class="fas fa-folder" v-else />
            &nbsp;
          </span>
          {{ row.index }}
        </td>

        <td v-if="row.type == 'master'" class="title">

          <div class="title">
            {{ row.mission.title }}
            <intel-link :mission="row.mission" title="Intel link to mission" />
            &nbsp;
            <map-ref title="Map links to starting point" :portal="findFirstWaypoint(row.mission)"><i class="fas fa-walking"></i></map-ref>

          </div>

        </td>
        <td v-if="row.type == 'master'" class="r">{{ row.mission.numWaypoints }}</td>
        <td v-if="row.type == 'master'" class="r">{{ formatDistance(row.mission.distance) }}</td>
        <td v-if="row.type == 'master'" class="r">{{ formatTime(row.mission.time) }}</td>
      </tr>
    </tbody>
  </table>

  
</template>
:
<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionWaypointEx, WaypointObjectiveTextsShort, findFirstWaypoint, IMissionInfoDetailed } from '../../../shared/src/types';
import { formatDistance, formatTime, formatRating } from '../lib/utils';

export default defineComponent({

  computed: {
    missionRows(): Array<{ type: string, mission: IMissionInfoDetailed }> {
      if (this.banner) {
        let list: any = [];
        this.banner.missions?.forEach((m,i) => {
          list.push({ type: 'master', mission: m, index: i + 1 });
          list.push({ type: 'detail', mission: m, index: i + 1 });
        })
        //console.dir(list);
        return list;
      } else {
        return []
      }
    }
  },

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  data() {
    return {
      icons: Object.freeze(icons),
      intelLinks: false,
      revealWaypoints: false,
      expanded: {} as {[id: string]: boolean},
      WaypointObjectiveTextsShort,
    }
  },

  methods: {

    openWaypointInIntel(w: IMissionWaypointEx): void {
      window.open(`https://intel.ingress.com/ll=${w.point.lat},${w.point.lng}&pll=${w.point.lat},${w.point.lng}&z=17`);
    },

    toggleExpandAll() {
      let expand = true;
      let keys = Object.keys(this.expanded);
      if (keys.length > 0) expand = !this.expanded[keys[0]];
      this.expanded ={};
      this.banner?.missions?.forEach(m => this.expanded[m.guid] = expand);
    },

    formatDistance,
    formatTime,
    formatRating,
    findFirstWaypoint

  }

});

</script>

<style lang="scss" scoped>

table.missions {
  width: 100%;
  th {
    text-align: left;
  }
  tr {
    vertical-align: top;
    td, th {
      &.r {
        text-align: right;
      }
    }
    td {
      padding-top: 1em;
    }
    td.title {
      * > {
        display: inline-block;
      }
    }
  }
}


.objectives {
  //font-size: 0.9em;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1em;
  color: var(--text-color-secondary);
  > * {
    .hidden {

    }
    .unavailable {
      text-decoration: line-through;
    }
    &:not(:first-child):before {
      content: "→";
      padding: 0 0.5em;
      color: #999;
    }
  }
}

.link {
  display: inline-block;
  color: #999;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  user-select: none; 
}

.description {
  padding-bottom: 1em;
}

.details {
  padding-top: 1em;
  .description {
    color: var(--text-color-secondary);
  }
}

.waypoints {
  width: 100%;
  .index {
    color: var(--text-color-secondary);
  }
  .objective {
    color: var(--text-color-secondary);
  }
}

</style>