

import { ITileData } from '../lib/mercator-tile-loader';

import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';

import { defineComponent, PropType } from 'vue';

import Map from './Map.vue';
import MapTiles from './MapTiles.vue';
import MapLayerGroup from './MapLayerGroup.vue';
import MapMarker from './MapMarker.vue';
import MapMarkerClusterGroup from './MapMarkerClusterGroup.vue';
import MapBannerMarker from './MapBannerMarker.vue';
import MapMissionMarker from './MapMissionMarker.vue';
import MapTileDataSource from './MapTileDataSource.vue'
import MapControlGroup from './MapControlGroup.vue';
  
import { BACKEND_KEY, BackendService } from '../services/backend-service';

export default defineComponent({

  inject: [ BACKEND_KEY ],
  
  props: {

    banner: {
      type: Object as PropType<IBanner>,
      default: () => null
    },

    showMissions: {
      type: Boolean,
      default: false
    },

    showBanners: {
      type: Boolean,
      default: false
    },

    showSummaries: {
      type: Boolean,
      default: false
    },

    showUsed: {
      type: Boolean,
      default: false
    },

    zoom: {
      type: Number,
      default: 15
    },

    center: {
      type: Object as PropType<L.LatLngExpression>,
      default: () => [ 59, 10 ]
    }

  },

  watch: {

    banner: {
      handler(to) {
        // const focus = () => {
        //   this.initializeBannerItems();
        // };
        // if (this.isReady) focus();
        // else this.onReady.push(focus);
      }
    },

    zoom: {
      immediate: true,
      handler(to) {
        this.zoom_ = to;
      }
    },

    center: {
      immediate: true,
      handler(to) {
        this.center_ = to
      }
    },

    zoom_(to) {
      this.$emit('update:zoom', to);
    },

    center_(to) {
      this.$emit('update:center', to);
    }

  },

  computed: {

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },

    banners(): Array<IBanner> {
      let filtered = this.items.filter(item => item.b).map(item => item.b);
      this.$emit('banners', filtered);
      return filtered;
    },

    missions(): Array<IMissionInfoDetailed> {
      let filtered = this.items.filter(item => item.m).map(item => item.m);
      this.$emit('missions', filtered);
      return filtered;
    }
  },

  components: {
    XMap: Map,
    XMapTiles: MapTiles,
    XMapLayerGroup: MapLayerGroup,
    XMapMarker: MapMarker,
    XMapMarkerClusterGroup: MapMarkerClusterGroup,
    XMapBannerMarker: MapBannerMarker,
    XMapTileDataSource: MapTileDataSource,
    XMapMissionMarker: MapMissionMarker,
    XMapControlGroup: MapControlGroup
  },

  data() {
    return {
      zoom_: 15,
      center_: [ 59.91838719629693, 10.747590065002443 ] as L.LatLngExpression,
      items: [] as Array<any>,
      isReady: false as boolean,
      onReady: [] as Array<Function>
    }
  },

  async mounted() {
  },

  methods: {

    goHere() {
      navigator.geolocation.getCurrentPosition(pos => {
        let latLng = new L.LatLng(pos.coords.latitude, pos.coords.longitude);
        this.center_ = latLng;
      }, err => {

      })
    },

    moved() {
      // history.replaceState(null, '', `/map/${c.lat}/${c.lng}/${z}`);
    },

    loadTileData(tile: ITileData<any>) {
      //let u = `/api/b/map/${tile.z}/${tile.x}/${tile.y}`;
      let query = [];
      if (this.showMissions) {
        let flags = 1;
        if (this.showSummaries) flags |= 2;
        if (this.showUsed) flags |= 4;
        query.push("missions=" + flags)
      };
      if (this.showBanners) query.push("banners=1");
      let qs = query.join("&");
      let u = `/api/map/${tile.z}/${tile.x}/${tile.y}?${qs}`;
      tile.xhr!.open("GET", u, true);
    },

    addTileData(data: any[]) {
      // console.log("addTileData", data);
    },

    updatedTileData(data: any[]) {
      // console.log("updateTileData", data);
      this.items = data;
    },

    ready() {
      this.isReady = true;
      this.onReady.forEach(fun => fun());
      // let mapComponent: any = this.$refs.map;
      // let map = mapComponent.getMap() as  L.Map;
      // this.$emit('ready', {
      //   map, mapComponent
      // })
    },

    getMap(): L.Map {
      let mapComponent: any = this.$refs.map;
      let map = mapComponent.getMap() as  L.Map;
      return map;
    },

    initializeBannerData() {
      // var group = new L.featureGroup([marker1, marker2, marker3]);
      // map.fitBounds(group.getBounds());

    }

  }
});

