<style lang="scss">

:root {
  --text-color-secondary: #AAA !important;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
//@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Coda&display=swap');

body {
  font-size: 0.9em;
}

html, body, #app {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  background-color: var(--surface-0);
  color: var(--text-color);
}

* {
  //font-family: Coda;
  font-family: "Exo 2", "Tahoma", "Segoe UI", Helvetica, Arial, sans-serif;;
}

.appContent {
  padding: 1em;
  box-sizing: border-box;
}

.space-above {
  padding-top: 1em;
}

.space-below {
  padding-bottom: 1em;
}


.x-space-above {
  padding-top: 1em;
}

.x-space-below {
  padding-bottom: 1em;
}

.x-secondary-text, .x-text-secondary {
  color: var(--text-color-secondary);
}

.x-split-pri-left {
  display: flex;
  flex-direction: row;
  > * {
    flex: 1;
    //flex: 11;
  }
  > *:last-child {
    flex: 0;
    // flex: 1;
  }
}

.x-link, .x-clickable, a, label {
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  //color: var(--primary-color);
  color: var(--text-color);
  &:hover {
    text-shadow: 0 0 16px var(--primary-color);
    color: var(--text-color);
    //text-decoration: underline;
  }
  &:visited {
    color: var(--text-color);
    // color: var(--primary-color);
  }
}

// export primeflex values as css vars
@import './styles/primeflex-overrides.scss';
:root {
  --primeflex-sm:#{$sm};
  --primeflex-md:#{$md};
  --primeflex-lg:#{$lg};
  --primeflex-xl:#{$xl};
}

</style>

<style lang="scss" scoped>

.appTitle {
  font-weight: bold;
  text-shadow: 0 0 8px var(--primary-color);
  color: var(--text-color);
  font-weight: 1.1em;
  padding-right: 1em;
}

.appLayout {
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .appHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999999999;
  }

  .appMain {
    position: absolute;
    left: 0;
    top: 60px;
    bottom: 0;
    right: 0;
    //border: 1px solid red;
    box-sizing: border-box;
    overflow-y: auto;
  }
}

</style>

<template>
  <div class="appLayout">

    <p-toast position="bottom-right" />

    <set-title title="SpecOps.Quest" />
    <p-menubar :model="items" class="appHeader">
      <template #end>
        <div class="appTitle">:: specops.quest ::</div>
      </template>
    </p-menubar>
    <div class="appMain">
      <router-view
        v-if="state.userReady"
        :key="$route.path.split('/')[1]"
        />
    </div>
  </div>

</template>

<script lang="ts">

import { BACKEND_KEY, BackendService } from '@/services/backend-service';

import { defineComponent } from 'vue';

import { IState } from './state';

export default defineComponent({

  inject: [ 'state', BACKEND_KEY ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },
    activeIndex(): string {
      switch (this.$route.name) {
        case "Home": return "home";
        case "Banners": return "banners";
        case "Missions": return "missions";
        case "Map": return "map";
        case "Banner": return "banners";
        case "Add": return "banners-add";
        case "EditProfile": return "profile-edit";
        case "Login": return "profile-login";
        case "Logout": return "profile-logout";
        default: return "";
      }
    },
    STATE(): IState {
      return (<any>this).state as IState;
    },
    isLoggedIn(): boolean {
      return this.STATE.user != null;
    }
  },

  data() {
    return {
      sidebar: false,

      items: [
          {
              label: 'Banners',
              icon:'pi pi-fw pi-microsoft',
              to: '/'
          },
          {
              label: 'Missions',
              icon:'pi pi-fw pi-flag',
              to: '/missions'
          },
          {
              label:'Manage',
              icon:'pi pi-fw pi-pencil',
              visible: () => this.isLoggedIn,
              items:[
                {
                    label: 'Create',
                    icon: 'pi pi-fw pi-pencil',
                    to: '/add'
                },
                {
                    disabled: true,
                    label:'List',
                    icon:'pi pi-fw pi-bars'
                }
              ]
          },
          {
              label: 'Resources',
              icon: 'pi pi-fw pi-external-link',
              items:[
                {
                    label:'IITC',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://iitc.app',
                    target: '_blank'
                },
                {
                    label: 'IITC plug-ins',
                    icon: 'pi pi-fw pi-external-link',
                    items: [
                      {
                        label: 'Mission plug-in',
                        icon:'pi pi-fw pi-external-link',
                        url: 'https://iitc.app/build/release/plugins/missions.user.js',
                        target: '_blank'
                      },
                      {
                        label: 'Mission Add-on plug-in',
                        icon:'pi pi-fw pi-external-link',
                        url: 'https://softspot.nl/ingress/plugins/iitc-plugin-missions-addon.user.js',
                        target: '_blank'
                      },
                      {
                        label: 'specops.quest plug-in',
                        icon:'pi pi-fw pi-external-link',
                        url: '/assets/iitc-plugin-specops-quest-helper.user.js',
                        target: '_blank'
                      }
                    ]
                },
                {
                  separator: true
                },
                {
                    label:'Intel',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://intel.ingress.com',
                    target: '_blank'

                },
                {
                    label:'Mission Creator',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://missions.ingress.com',
                    target: '_blank'
                },
                {
                    label:'Ingress',
                    icon:'pi pi-fw pi-external-link',
                    url: 'https://ingress.com',
                    target: '_blank'
                }
              ]
          },
          {
              label:'Profile',
              icon:'pi pi-fw pi-user',
              items:[
                {
                    label:'Log in',
                    icon:'pi pi-fw pi-sign-in',
                    to: '/login',
                    visible: () => !this.isLoggedIn

                },
                {
                    label:'Edit',
                    icon:'pi pi-fw pi-user-edit',
                    to: '/profile',
                    visible: () => this.isLoggedIn

                },
                {
                    label:'Log out',
                    icon:'pi pi-fw pi-sign-out',
                    to: '/logout',
                    visible: () => this.isLoggedIn
                }
              ]
          }
        ]



    }
  },

  async mounted() {
    const state = (<any>this)["state"] as any;
    // console.log("STATE IS", state);
    let user = null;
    try {
      if (!state.user) {
        state.user = await this.backendService.getAuth();
        // console.log("USER IS", state.user)
      } else {
        // console.log("USER WAS ALREADY", user)
      }
    } catch (err) {
      console.error(err);
      console.log("USER CHECK FAILED", err);
      state.user = null;
    }
    // console.log("USER SET READY");
    state.userReady = true;

    //this.$toast.add({ severity: 'info', summary: 'Bannergress', detail: '' })

  },

  methods: {
    go(name: string, params?: any) {
      if (params) this.$router.push({ name, params })
      else this.$router.push({ name });
    }
  }
});

</script>

