

interface ISequence {
  seq: number;
  total?: number;
};

function parseSequence(title: string): ISequence | undefined {
  let m = title.replace(/[#]/g, ' ').match(/(\d+)\s*(\/|-|of)\s*(\d+)/);
  if (m && m[2].trim().length == 0) m = null;
  if (!m) m = title.replace(/[#]/g, ' ').match(/(\d+)\s*([^\d\s]+?)\s*(\d+)/);
  if (m) {
    return { seq: parseInt(m[1]), total: parseInt(m[3]) };
  } else {
    m = title.match(/#(\d+)/);
    if (m == null) m = title.match(/(\d+)/);
    if (m) {
      return { seq: parseInt(m[1]) };
    // let nums = title.match(/(?=[^\d]|\b)(\d+)(?<=\b|[^\d])/g);
    // if (nums) {
    //   //nums = nums.map(x => parseInt(x.replace(/[^0-9]/g, '')));
    //   console.dir(nums);
    // }
    }
  }
}

import { defineComponent, inject, PropType } from 'vue';

import {
  IMissionInfoSummary,
  IMissionInfoDetailed,
  IBanner,
  ISearchResults,
  MissionInfo
} from '../../../shared/src/types';

import { BACKEND_KEY, BackendService } from '@/services/backend-service';

export default defineComponent({

  name: 'BannerEditor',

  components: {
  },

  props: {

    step: {
      type: Number,
      default: 0,
    },

    add: {
      type: Boolean,
      default: false
    },

    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }

  },

  data() {
    return {
      bannerData: {} as IBanner,
      mapFocusMission: null as IMissionInfoDetailed | null,
      loading: false,
      //rowSize: 6 as number,
      rowSizes: [
        { value: 6, label: '6 - Regular' },
        { value: 5, label: '5' },
        { value: 4, label: '4' },
        { value: 3, label: '3 - Half' },
        { value: 2, label: '2' },
        { value: 1, label: '1' },
      ],
      selectTitleFromMission: false,
      selectDescriptionFromMission: false,
      filterTitle: '',
      filterIndexed: true,
      searchResults: { results: [], count: 0, page: 0, pageSize: 1, numPages: 0 } as ISearchResults<MissionInfo>,
      //title: '',
      //description: '',
      //missions: [] as IMissionDetailsEx[],
      //addedMissions: [] as IMissionInfoDetailed[],
      leftSelection: [] as IMissionInfoDetailed[],
      rightSelection: [] as IMissionInfoDetailed[],
      previewCircle: false
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(v) {
        if (v) {
          this.bannerData = v; // JSON.parse(JSON.stringify(v)) ;
          // if (this.bannerData.missions!.length == 0) this.step = 0;
          // else this.step = 2;
          //this.title = v.title;
          //this.description = v.description;
          //this.addedMissions = v.missions; // || []
        }
      }
    }
  },

  inject: [ BACKEND_KEY ],

  computed: {

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
      //return inject(BACKEND_KEY);
    },

    filteredMissions(): Array<MissionInfo> {
      let a = this.addedMissionsMap;
      //let f = new RegExp(this.filterTitle.trim(), "i");
      return this.searchResults.results.filter(m => {
        return ((this.filterIndexed && (<IMissionInfoDetailed>m).detailsModified) || !this.filterIndexed)
          //&& f.test(m.title)
          && !a[m.guid];
      })
    },

    addedMissionsMap(): {[guid: string]: MissionInfo} {
      let map: {[guid: string]: MissionInfo} = {};
      this.bannerData.missions!.forEach(m => map[m.guid] = m);
      return map;
    }

  },

  mounted() {
  },

  methods: {

    async save() {

      // let b: IBanner = {
      //   ...this.banner,
      //   rowSize: this.rowSize,
      //   title: this.title,
      //   description: this.description,
      //   missionGuids: this.bannerData.missions.map(m => m.guid),
      //   missions: this.addedMissions,
      // };

      let b = this.bannerData;

      b.missionGuids = b.missions!.map(m => m.guid);

      this.$emit('update:banner', b);

      b = { ... b};
      delete b.missions;
      //delete b.comments;

      this.$emit('save', b);
    },

    sortAlpha() {
      let list = [ ...this.bannerData.missions! ].sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));
      if (confirm("Does this look correct?\n\n" + list.map(m => m.title).join("\n"))) {
        this.bannerData.missions = list;
      }
    },

    sortSmart() {
      let sequences = this.bannerData.missions!.map(m => parseSequence(m.title));
      let previewOrder = this.bannerData.missions!.map((m, i) => ({ m, i, seq: sequences[i]?.seq }));
      previewOrder.sort((a, b) => a.seq! - b.seq!);
      
      if (confirm("Does this look correct?\n\n" + previewOrder.map(m => m.m.title).join("\n"))) {
        this.bannerData.missions = previewOrder.map(x => x.m);
      }
    },

    nextStep() {
      //alert("next step")
      this.$emit('update:step', this.step + 1);
    },

    prevStep() {
      //alert("prev step")
      this.$emit('update:step', this.step - 1);
    },
    
    async goStep2() {
      this.loading = true;
      try {
        let missions = await this.backendService?.getMissionsByGuids(this.bannerData.missions!.map(m => m.guid));
        console.dir(missions);
        missions?.forEach(m2 => {
          let m = this.bannerData.missions!.find(x => x.guid == m2.guid);
          //console.log("upgrade: %o -> %o", m, m2);
          if (m != null) {
            Object.assign(m, m2); // will this work with reactivity?
          } else {
            console.error("could not find mission in addedMission:", m2);
          }
        })
      } finally {
        this.loading = false;
      }

      // reload missions with all details

      if (this.bannerData.description == null || this.bannerData.description == "") this.bannerData.description = this.bannerData.missions?.[0].description as string;
      if (this.bannerData.title == null || this.bannerData.title == "") this.bannerData.title = this.bannerData.missions?.[0].title as string;
      this.nextStep();
    },

    startSelectTitleFromMission() {
      this.selectTitleFromMission = true;
      this.$nextTick(() => {
        let el = this.$refs.selectMissionTitle;
        (<any>el)?.focus();
      })
    },

    startSelectDescriptionFromMission() {
      this.selectDescriptionFromMission = true;
      this.$nextTick(() => {
        let el = this.$refs.selectMissionDescription;
        (<any>el)?.focus();
      })
    },

    async search() {
      this.loading = true;
      try {
        this.searchResults = await this.backendService.searchMissions({ title: this.filterTitle });
      } finally {
        this.loading = false;
      }
    },

    async searchNext(page: number) {
      try {      
        this.loading = true;
        this.searchResults = await this.backendService.searchMissions({ title: this.filterTitle, page: page });
      } finally {
        this.loading = false;
      }
    },

    formatTime(t: number) {
      t /= 1000;
      let d = Math.floor(t / 86400);
      t %= 86400;
      let h = Math.floor(t / 3600);
      t %= 3600;
      let m = Math.floor(t / 60);
      t %= 60;
      let s = Math.round(t);
      return [
        d ? d+'d' : null,
        h ? h+'h' : null,
        m ? m+'m' : null,
        s ? s+'s' : null
      ].filter(s => s).join(" ")
    },


  }
  
});

