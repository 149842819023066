<template>
  <span v-if="agent && agent.nickname" class="agent" :class="`team-${agent.team}`">
    <img src="/assets/resistance_alt.svg" class="logo" v-if="agent.team == 'R'">
    <img src="/assets/enlightened_alt.svg" class="logo" v-else-if="agent.team == 'E'">
    <i class="fas fa-times" style="color: var(--text-color-secondary)" v-else />
    <span v-if="agent.team == 'N'"> Unavailable</span>
    <span v-else>{{ agent.nickname }}</span>
  </span>
  <span v-else class="agent team-N">Unavailable</span>
</template>

<style lang="scss" scoped>

.agent {
  white-space: nowrap;
  .logo {
    height: 1em;
    position: relative;
    top: 0.15em;
  }
  &.team-R {
    //color: blue;
  }
  &.team-E {
    //color: green;
  }
  &.team-N {
    //color: red;
    // no team
  }
}

</style>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { IAgent } from '../../../shared/src/types';

export default defineComponent({

  name: 'AgentName',

  props: {
    agent: {
      type: Object as PropType<IAgent>,
      required: true
    }
  }

});

</script>
