

import { icons } from '../lib/icons';
import { formatDistance, formatTime, formatRating, formatEfficiency, formatAddress, formatAddress2, filteredWaypointObjectivesStats } from '../lib/utils';
import { IBanner, MissionOrder, IAgent, MissionOrderTexts } from '../../../shared/src/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  name: 'BannerStatsTable',

  components: {
  },

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  data() {
    return {
      icons
    }
  },

  computed: {

    avgNumCompleted(): number {
      let sum = this.banner!.missions!.reduce((p,m) => p + m.numCompleted, 0) as number;
      return sum / this.banner!.numMissions!;
    },

    avgRating(): number {
      let sum = this.banner!.missions!.reduce((p,m) => p + m.rating, 0) as number;
      return sum / this.banner!.numMissions!;
    },

    missionAuthors(): IAgent[] {
      let authors: {[name: string]: IAgent} = {};
      this.banner?.missions?.forEach(m => {
        authors[m.author.nickname] = m.author;
      })
      return Object.values(authors);
    },

    missionOrders(): string[] {
      let orders: any = {};
      this.banner?.missions?.forEach(m => {
        orders[m.type!] = m.type;
      })
      return Object.values(orders).map(o => MissionOrderTexts[<MissionOrder><unknown>o]);
    }
    
  },

  methods: {

    filteredWaypointObjectivesStats,
    formatDistance,
    formatTime,
    formatEfficiency,
    formatRating,
    formatAddress,
    formatAddress2

  }

})

