

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner } from '../../../shared/src/types';

export default defineComponent({
  inject: [ 'state' ],
  props: {
    step: {
      type: Number
    },
    banner: {
      type: Object as PropType<IBanner>,
      //required: true
    }
  },
  data() {
    return {
      icons: Object.freeze(icons)
    }
  },
  methods: {
  }
});

