

import { h,defineComponent } from 'vue';

export default defineComponent({
  name: 'FlexDetect',
  render() {
    return h('span', { style: 'display: none' });
  },
  data() {
    let sm = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--primeflex-sm').trim());
    let md = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--primeflex-md').trim());
    let lg = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--primeflex-lg').trim());
    let xl = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--primeflex-xl').trim());
    return {
      sm,
      md,
      lg,
      xl,
      cur: 'sm'
    }
  },
  props: {
    size: {
      type: String
    }
  },
  methods: {
    resized() {
      let w = window.innerWidth;
      let ncur;
      if (w >= this.xl) ncur = "xl";
      else if (w >= this.lg) ncur = "lg";
      else if (w >= this.md) ncur = "md";
      else if (w >= this.sm) ncur = "sm";
      else ncur = "xs";
      if (this.cur != ncur) {
        this.cur = ncur;
        this.$emit("update:size", ncur);
      }
      //console.log("resize:", ncur, w);
    }
  },
  created() {
    window.addEventListener('resize', this.resized)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resized);
  }
})

