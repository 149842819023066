
export interface ICompletionReview {
  createdBy?: string;
  createdAt?: number;
  modifiedAt?: number;
  //
  comment: string;
  enjoyed: boolean;
  twentyFourSeven: boolean;
  artworkRating: number;
  difficultyRating: number; // "effortlessness"
  routeRating: number;
  passphraseRating: number;
  completedByFoot: boolean;
  completedByBike: boolean;
  completedByCar: boolean;
  suitableTransportation: {
    foot: boolean;
    bike: boolean;
    car: boolean;
    wheelchair: boolean;
  }
}

export enum MissionOrder {
  Sequential = 1,
  AnyOrder = 2,
  Hidden = 3
}

export const MissionOrderTexts: {[id in MissionOrder]: string} = {
  [MissionOrder.Sequential]: 'Sequential',
  [MissionOrder.AnyOrder]: 'Any order',
  [MissionOrder.Hidden]: 'Hidden',
};

export enum WaypointType {
  Portal = 1,
  FieldTrip = 2
}

export const WaypointTypeTexts: {[id in WaypointType]: string} = {
  [WaypointType.Portal]: 'Portal',
  [WaypointType.FieldTrip]: 'FieldTrip'
};

export enum WaypointObjective {
  Hack = 1,
  CaptureOrUpgrade = 2,
  CreateLink = 3,
  CreateField = 4,
  InstallMod = 5,
  TakePhoto = 6,
  ViewFieldTripWaypoint = 7,
  EnterPassprase = 8
}

export type WaypointObjectiveMap = {[id in WaypointObjective]: number};

export const WaypointObjectiveTexts: {[id in WaypointObjective]: string} = {
  [WaypointObjective.Hack]: 'Hack',
  [WaypointObjective.CaptureOrUpgrade]: 'Capture or upgrade',
  [WaypointObjective.CreateLink]: 'Create a link from',
  [WaypointObjective.CreateField]: 'Create a field from',
  [WaypointObjective.InstallMod]: 'Install a mod on',
  [WaypointObjective.TakePhoto]: 'Take a photo',
  [WaypointObjective.ViewFieldTripWaypoint]: 'Visit FieldTrip waypoint',
  [WaypointObjective.EnterPassprase]: 'Enter passphrase',
};

export const WaypointObjectiveTextsShort: {[id in WaypointObjective]: string} = {
  [WaypointObjective.Hack]: 'Hack',
  [WaypointObjective.CaptureOrUpgrade]: 'Capture/upgrade',
  [WaypointObjective.CreateLink]: 'Creat link',
  [WaypointObjective.CreateField]: 'Create field',
  [WaypointObjective.InstallMod]: 'Install mod',
  [WaypointObjective.TakePhoto]: 'Take photo',
  [WaypointObjective.ViewFieldTripWaypoint]: 'FieldTrip',
  [WaypointObjective.EnterPassprase]: 'Passphrase',
};

export interface IGeoJSONPoint {
  type: "Point",
  coordinates: Array<number>; // [lat, lng] - point(array of lat/lng)
}

export interface IGeoJSONLineString {
  type: "LineString",
  coordinates: Array<Array<number>>; // [[lat, lng], ..] - array of points(array of lat/lng)
}

export interface IGeoJSONPolygon {
  type: "Polygon",
  coordinates: Array<Array<Array<number>>>; // [[[lat, lng], ..]] - array of array of points(array of lat/lng)
}

export interface ILatLng {
  lat: number;
  lng: number;
}

export interface ILatLngBounds {
  ne: ILatLng;
  sw: ILatLng;
}

export interface IPoint extends ILatLng {
  latE6?: number,
  lngE6?: number,
  //lat: number,
  //lng: number,

  /** @deprecated */
  geoJSON?: IGeoJSONPoint, // this stores lat,lng instead of lng,lat :\
  
  geojson?: IGeoJSONPoint, // lng,lat :)
  backfilled?: boolean;
}

export interface IMissionUserData {
  favoritedBy?: Array<string>;
  refreshedBy?: Array<string>;
  refreshedRecentlyBy?: {[userId: string]: number};
}

export interface IMissionInfoSummary {
  guid: string;
  title: string;
  image: string;
  time: number;
  rating: number;
  portalGuid: string | null | undefined;
  // since there is no location with this, we store the location either of the related portal, or the current view
  locationBounds?: ILatLngBounds;
  locationPoint?: ILatLng;
  // timestamps
  summaryCreated?: number;
  summaryUpdated?: number;
  // per-user data
  userData?: IMissionUserData;
}

export interface IMissionMetrics {
  numMissions?: number;
  numWaypoints?: number;  
  distance?: number;
  numWaypointObjectives?: {[type in WaypointObjective]: number};
  numUniques?: number;
}

export interface IAgent {
  nickname: string;
  team: 'E' | 'R' | 'N';
}

export interface IBannerUserData {
  likedBy?: Array<string>;
  favoritedBy?: Array<string>;
  toDoBy?: Array<string>;
  completedBy?: Array<string>;
}

export interface IBannerUserDataPersonalized {
  likedBy: boolean;
  favoritedBy: boolean;
  toDoBy: boolean;
  completedBy: boolean;
}

export const DefaultBannerUserData: IBannerUserData = {
  likedBy: [],
  favoritedBy: [],
  toDoBy: [],
  completedBy: [],
}

export interface IMissionInfoDetailed extends IMissionInfoSummary, IMissionMetrics {
  type?: MissionOrder;
  description: string;
  author: IAgent,
  numCompleted: number;
  waypoints: Array<IMissionWaypointEx>;
  rating: number;
  portalGuid: string | null | undefined;
  // added
  startPoint?: IPoint; // added as a replacement for locationPoint which is overwritten by summaries
  detailsCreated?: number;
  detailsModified?: number;
  // references
  usedInBanners?: Array<string>;
}

export interface IMissionWaypointEx {

  type: WaypointType;

  objective: WaypointObjective;

  hidden: boolean;

  /** @deprecated Use point.lat */
  lat?: number;

  /** @deprecated Use point.lng */
  lng?: number;

  point: IPoint;

  guid?: string;

  title: string;

}

export interface IBanner extends IMissionMetrics {

  _id?: string;
  guid?: string;
  slug?: string;

  // properties
  title: string;
  description: string;
  rowSize: number;

  // missions
  missions?: IMissionInfoDetailed[];
  missionGuids?: string[];

  // routes
  routes?: {
    [range: string]: {
      [provider: string]: any
    };
  }

  // meta
  createdBy?: string;
  createdAt?: number;
  lastModifiedAt?: number;
  draft?: boolean;

  // geo
  /** deprecated */
  lat?: number;
  /** deprecated */
  lng?: number;
  point?: IPoint;

  path?: IGeoJSONLineString;
  addressHierarchy?: Array<string | null | undefined>,
  addressLookups?: {
    [provider: string]: any;
  };

  // metrics
  time?: number;
  timeEfficiency?: number;
  distanceEfficiency?: number;
  combinedEfficiency?: number;
  distanceEndsBegins?: number;

  // reviews
  reviews?: ICompletionReview[];

  // per-user data
  userData?: IBannerUserData | IBannerUserDataPersonalized;

  $refs?: {
    users?: {[id: string]: IUser};
  }

  // thumbnails
  images?: {
    small?: string;
    medium?: string;
    large?: string;
  }

  versionHistory?: IBanner[];
}

export interface ISearchResults<T> {
  results: Array<T>;
  page: number;
  numPages: number;
  pageSize: number;
  count: number;
}

export function countUniques(waypoints: IMissionWaypointEx[]): number {
  let visits: {[guid: string]: true} = {};
  waypoints.forEach(wp => {
    if (wp.guid && wp.type == WaypointType.Portal) {
      visits[wp.guid] = true;
    }
  })
  return Object.keys(visits).length;
}

export function filterValidWaypoint(w: IMissionWaypointEx): boolean {
  return w.point != null && w.point.lat != null && w.point.lng != null;
}

export function filterValidWaypoints(mission: IMissionInfoDetailed): IMissionWaypointEx[] {
  return mission.waypoints.filter(filterValidWaypoint);
}

export function findFirstWaypoint(mission: IMissionInfoDetailed): IMissionWaypointEx | undefined {
  return filterValidWaypoints(mission)[0];
  //return mission.waypoints.find(w => w.point != null && w.point.lat != null && w.point.lng != null);
}

export function findLastWaypoint(mission: IMissionInfoDetailed): IMissionWaypointEx | undefined {
  return filterValidWaypoints(mission).reverse()[0];
  //return [...mission.waypoints].reverse().find(w => w.point != null && w.point.lat != null && w.point.lng != null);
}

export function initializeWaypointsObjectivesMap(): {[type in WaypointObjective]: number} {
  return {
    [WaypointObjective.Hack]: 0,
    [WaypointObjective.CaptureOrUpgrade]: 0,
    [WaypointObjective.CreateLink]: 0,
    [WaypointObjective.CreateField]: 0,
    [WaypointObjective.InstallMod]: 0,
    [WaypointObjective.TakePhoto]: 0,
    [WaypointObjective.ViewFieldTripWaypoint]: 0,
    [WaypointObjective.EnterPassprase]: 0,
  }
}

export type MissionInfo = IMissionInfoDetailed | IMissionInfoSummary;

export interface ICountryInfo {
  name: string;
  count: number;
  hierarchy?: IGeoAdminLevel[]
}

export interface IGeoAdminLevel {
  name: string;
  level: number;
  children?: IGeoAdminLevel[];
  count: number;
}

export interface IUser {
  _id: string;
  googleId: string;
  googleProfile: any;
  agentName?: string;
  agentFaction?: 'E' | 'R';
  agentVerified?: boolean;
  agentVerificationChallenge?: string;
  apiKey?: string;
  apiKeyCreatedAt?: number;
  createdAt?: number; 
  roles?: string[];
  lastRefreshedMissionIds?: Array<string>; // store this here as we dont want to pollute everywhere with it
}

export function filterCommentReviews(reviews?: ICompletionReview[]): ICompletionReview[] {
  return reviews
    ? reviews.filter(r => (r.comment || "").trim().length)
    : [];
}

