

import { IBanner } from '../../../shared/src/types';
import { defineComponent, PropType } from 'vue';
import { formatTime, formatDistance, formatAddress, formatAddress2, formatRating, formatEfficiency  } from '../lib/utils';
import { icons } from '../lib/icons';

export default defineComponent({

  name: 'BannerGridEntry',

  components: {
  },  

  inject: [ 'state' ],

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  data() {
    return {
      icons,
      flexSize: 'xl',
      maxBannerHeight: {
        'xl': '10em',
        'lg': '12em',
        'md': '12em',
        'sm': '20em',
        'xs': '20em',
      }
    }
  },

  computed: {
    link(): string {
      return `/banner/${this.banner.slug || this.banner.guid}`;
    }
  },

  methods: {

    select() {
      this.$emit('select', this.banner);
    },  

    formatTime,
    formatDistance,
    formatEfficiency,
    formatAddress,
    formatAddress2,
    formatRating,

  }

})

