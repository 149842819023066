<template>
  
  <TwoColumnLayout :left="2" :right="5">

    <template #left>
      <MissionList
        style="width: 100%"
        v-model:missions="bannerData.missions"
        :expanded="false"
        :selectable="false"
        :allow-reorder="true"
        :sortable="false"
        :show-rating="false"
        :show-time="false"
        :show-waypoints="false"
        :show-folders="false"
        :show-details="true"
        :show-intel="true"
        @cell-enter="mapFocusMission = $event"
        @cell-leave="mapFocusMission = null"
        />
    </template>

    <template #right>
      <BannerMap :missions="bannerData.missions" :focus-mission="mapFocusMission" style="widht: 100%; height: 100%" />
    </template>

    <template #bottom>

      <p-dialog :modal="true" :closeOnEscape="false" :closable="false" v-model:visible="loading">
        <template #header>
          Saving
        </template>
        Saving banner, please wait..
      </p-dialog>

      <p-dialog :modal="true" :closeOnEscape="false" :closable="true" v-model:visible="showSaved" @hide="afterSave">
        <template #header>
          Saved!
        </template>
        The banner has been saved, please allow a moment for the image preview to be generated and refreshed.
      </p-dialog>

      <p-dialog :modal="true" :closeOnEscape="false" :closable="true" v-model:visible="showError">
        <template #header>
          Error
        </template>
        <p>Failed saving the banner :\ - Your session may have timed out, please try logging out (top bar), then log back in again!</p>
        <p>{{ error }}</p>
      </p-dialog>

      <div style="flex: 0; padding: 1em" class="p-grid">
        <div class="p-col-6 p-text-left">
          <p-button @click="$emit('goto-details')" label="Back" icon="fas fa-arrow-left" />
        </div>
        <div class="p-col-6 p-text-right">
          <p-button @click="save" label="Save" icon="fas fa-save" />
        </div>
      </div>

    </template>

  </TwoColumnLayout>
  
</template>

<script lang="ts">

import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IMissionInfoDetailed, IBanner } from '../../../shared/src/types';
import { IState } from '../state';

export default defineComponent({

  inject: [ 'state', BACKEND_KEY ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },
  },

  props: {
    step: {
      type: Number
    },
    banner: {
      type: Object as PropType<IBanner>,
      //required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {
      loading: false,
      icons: Object.freeze(icons),
      bannerData: {} as IBanner,
      mapFocusMission: null as IMissionInfoDetailed | null,
      savedGuid: '',
      showSaved: false,
      showError: false,
      error: ''
    }
  },

  methods: {

    afterSave() {
      this.$router.push({ name: 'ViewBanner', params: { bannerId: this.savedGuid }});
    },

    async save() {

      let b = this.bannerData;

      let titles = b.missions!.map(m => m.title);
      if (titles.length > 1 && titles.indexOf(b.title) >= 0) {
        if (!confirm("⚠ WARNING ⚠\n\nThe banner title is the same as one of the mission titles - are you sure you remembered to remove any '2/24' or similar sequence information from the title?\n\n" + b.title + "\n\nContinue?"))
          return;
      }

      if (!confirm(`Are you sure you are ready to submit the banner?\nMake sure you have removed any sequence numbers from the title!\n\n${b.title}\n\n${b.description}`))
        return;

      b.missionGuids = b.missions!.map(m => m.guid);
      this.$emit('update:banner', b);

      b = { ... b};
      delete b.missions;
      //delete b.comments;

      try {
        this.loading = true;

        let b2 = (b.guid == null)
          ? await this.backendService?.createBanner(b)
          : await this.backendService?.updateBanner(b);

        console.log("SAVED BANNER ->", b2);

        let state = (<any>this).state as IState;
        state.editBanner = undefined;

        this.loading = false;

        this.showSaved = true;
        this.savedGuid = b2.slug! || b2.guid!;

      } catch (err) {

        this.loading = false;
        this.error = err.message;
        this.showError = true;

      }
    },

  },

});

</script>

<style lang="scss" scoped>

.fs {
  box-sizing: border-box;
  border: 1px solid green;
}

</style>