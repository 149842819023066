<template>
  <div class="appContent">
    <set-title />
    <div v-if="state.user == null">
      <p>You are not logged in - please log in using one of the following providers:</p>
      <p-button label="Log in with Google" icon="fab fa-google" @click="login" />
    </div>
    <div v-else>
      You're already logged in!
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Login',
  inject: ['state'],
  data() {
    return {
    }
  },
  methods: {
    login() {
      window.location.replace('/auth/google');
    }
  }
});
</script>