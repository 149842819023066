

import * as L from 'leaflet';

import "leaflet/dist/leaflet.css";

const icon = require('leaflet/dist/images/marker-icon.png');
const iconShadow = require('leaflet/dist/images/marker-shadow.png');

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

import { defineComponent, PropType } from 'vue';

const Emitter = require('tiny-emitter');

export default defineComponent({

  provide() {
    return {

      layer: () => {
        return this.frozen.map;
      },

      map: () => {
        return this.frozen.map;
      },

      mapComponent: () => {
        return this;
      }

    }
  },

  props: {
    zoom: {
      type: Number,
      value: 15
    },
    center: {
      type: Object as PropType<L.LatLngExpression>,
      value: [0, 0]
    }
  },

  watch: {
    zoom(to) {
      this.zooming = true;
      this.frozen.map.setZoom(to);
    },
    center(to) {
      this.moving = true;
      this.frozen.map.panTo(to);
    }
  },

  data() {
    return {
      resizeTimeout: null as any,
      emitter: new Emitter(),
      isReady: false,
      zooming: false,
      moving: false,
      frozen: {
        map: null! as L.Map 
      },
    }
  },

  mounted() {
    let mapEl = this.$refs.map as HTMLElement;
    let map = L.map(mapEl).setView(this.center!, this.zoom);

    map.on('movestart', ev => {
      if (!this.moving) {
        this.$emit('update:center', map.getCenter());
        this.$emit('movestart', ev);
        this.emitter.emit('movestart', ev);
      }
    })

    map.on('moveend', ev => {
      if (!this.moving) {
        this.$emit('update:center', map.getCenter());
        this.$emit('moveend', ev);
        this.emitter.emit('moveend', ev);
      } else {
        this.moving = false;
      }
    })

    map.on('zoomstart', ev => {
      if (!this.zooming) {
        this.$emit('update:zoom', map.getZoom());
        this.$emit('zoomstart', ev);
        this.emitter.emit('zoomstart', ev);
      }
    })

    map.on('zoomend', ev => {
      if (!this.zooming) {
        this.$emit('update:zoom', map.getZoom());
        this.$emit('zoomend', ev);
        this.emitter.emit('zoomend', ev);
      } else {
        this.zooming = false;
      }
    })

    this.frozen = Object.freeze({
      map
    });

    console.log("READY!");

    this.$nextTick(() => {
      this.isReady = true;
      this.$emit('ready');
    });
  },

  computed: {
  },

  methods: {

    onResize() {
      console.log("map resized");
      if (this.isReady) {
        if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          this.frozen.map.invalidateSize(false);
          this.resizeTimeout = null;
        }, 250);
      }
    },

    getMap() {
      return this.frozen.map;
    },
    $on(...args: any[]) { this.emitter.on(...args) },
    $off(...args: any[]) { this.emitter.off(...args) },
    $once(...args: any[]) { this.emitter.once(...args) }
  }

})

