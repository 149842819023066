

import { defineComponent } from 'vue';

import { icons } from '../lib/icons';
import { IBanner, IBannerUserDataPersonalized, ICompletionReview, filterCommentReviews } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import BannerImage from '../components/BannerImage.vue';
import BannerStatsTable from '../components/BannerStatsTable.vue';
import BannerMapPreview from '../components/BannerMap.vue';
import { IState, isAdminOrOwner, isAdmin } from '../state';

import BannerCompletionDialog from '../components/BannerCompletionDialog.vue';

export default defineComponent({

  name: 'ViewBanner',

  inject: [ 'state', BACKEND_KEY ],

  components: {
    BannerImage,
    BannerStatsTable,
    BannerMapPreview,
    BannerCompletionDialog
  },

  computed: {

    fullscreen(): boolean {
      return this.$route.query.fullscreen == 'true';
    },

    STATE(): IState {
      return (<any>this).state as IState;
    },

    tabs(): Array<any> {
      let bannerId = this.bannerId;
      return [
        { label: 'Missions', icon: icons.mission, to: `/banner/${bannerId}/missions` },
        { label: 'Map', icon: icons.map, to: `/banner/${bannerId}/map` },
        { label: 'Comments (' + filterCommentReviews(this.banner?.reviews).length + ')', icon: 'fas fa-comment', to: `/banner/${bannerId}/comments` },
        //{ label: 'Export', icon: 'fas fa-download', to: `/banner/${bannerId}/export` },
      ]
    },

    loadMap(): boolean {
      if (this.mapLoaded) return true;
      else if (this.tab == "map") {
        this.$nextTick(() => {
          this.mapLoaded = true;
        })
        return true;
      } else {
        return false;
      }
      
    },

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },

    bannerId(): string {
      return <string>this.$route.params.bannerId;
    },

  },

  beforeRouteLeave(to, from, next) {
    this.isLeaving = true;
    next();
  },

  data() {
    return {

      isLeaving: false,
      review: {} as ICompletionReview,

      saving: false,

      isCompleted: false,
      isToDo: false,
      isFavorite: false,

      tab: "missions",
      mapLoaded: false,
      icons: Object.freeze(icons),
      banner: null as IBanner | null
    }
  },

  watch: {
    bannerId: {
      immediate: true,
      handler(to: any, from: any) {
        if (!this.isLeaving && to != from) this.load();
      }
    }
  },

  methods: {

    isAdmin,
    isAdminOrOwner,

    startReview() {
      let dlg = this.$refs.reviewDialog as any;
      dlg.showDialog();
    },

    saveReview(review: ICompletionReview) {
      this.toggleCompleted(true, review);
      if (review) {
        // fake-insert
        let user = this.STATE.user!;
        let banner = this.banner!;
        review.createdAt = Date.now();
        review.modifiedAt = Date.now();
        review.createdBy = user._id;
        if (!banner.reviews) banner.reviews = [];
        if (!banner.$refs) banner.$refs = {};
        if (!banner.$refs.users) banner.$refs.users = {};
        banner.$refs.users[user._id!] = user;
        banner.reviews = banner.reviews.filter(r => r.createdBy != user._id);
        banner.reviews.push(review);
      }
    },

    deleteReview(review: ICompletionReview) {
      this.toggleCompleted(false);
      let user = this.STATE.user!;
      let banner = this.banner!;
      if (banner.reviews) {
        banner.reviews = banner.reviews.filter(r => r.createdBy != user._id);
      }
    },

    async toggleCompleted(state?: boolean, review?: ICompletionReview) {
      try {
        if (state == null) state = !this.isCompleted;
        if (review) this.review = review;
        this.saving = true;
        await this.backendService.toggleBannerCompleted(this.banner!, state, review);
        this.isCompleted = state;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async load() {
      this.banner = await this.backendService.getBanner(<string>this.bannerId);
      let review = this.banner?.reviews?.find(r => r.createdBy == this.STATE.user?._id);
      if (review) this.review = review;

      this.isFavorite = false;
      this.isCompleted = false;
      this.isToDo = false;
      if (this.STATE && this.STATE.user) {
        if (this.banner && this.banner.userData) {
          let ud = this.banner.userData as IBannerUserDataPersonalized;
          this.isFavorite = ud.favoritedBy;
          this.isCompleted = ud.completedBy;
          this.isToDo = ud.toDoBy;
        }
      }
    },

    async toggleFavorite() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerFavorite(this.banner!, !this.isFavorite);
        this.isFavorite = !this.isFavorite;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async toggleToDo() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerToDo(this.banner!, !this.isToDo);
        this.isToDo = !this.isToDo;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    }

  }
});

