<template>
  <p-dialog :modal="true" :closeOnEscape="false" :closable="false" :visible="visible">
    
    <template #header> 
      Mission review
    </template>

    <div class="items">

      <div class="p-grid">

        <div class="p-field p-col-6 blok">
          <div class="subject">Artwork</div>
          <p-rating class="value" v-model="form.artworkRating" :stars="5" :cancel="false" />
          <div class="key">Does this thing look good in your scanner? (very subjective)</div>
        </div>

        <div class="p-field p-col-6 blok">
          <div class="subject">Effortlessness</div>
          <div class="value">
            <p-rating v-model="form.difficultyRating" :stars="5" :cancel="false" />     
          </div>   
          <div class="key">
            1 - Off-road and acrobatics / 3 - Some gradients / 5 - Straightforward
          </div>
        </div>


        <div class="p-field p-col-6 blok">
          <div class="subject">Route</div>
          <div class="value">
            <p-rating v-model="form.routeRating" :stars="5" :cancel="false" />     
          </div>   
          <div class="key">
            How logical was the route? Any backtracking? Needed many heatsinks? Areas you didn't feel comfortable venturing into?
          </div>
        </div>

        <div class="p-field p-col-6 blok">
          <div class="subject">Passphrases</div>
          <div class="value">
            <p-rating :disabled="!hasPassphrases" v-model="form.passphraseRating" :stars="5" :cancel="false" />     
          </div>   
          <div class="key">
            Are the passphrases easy, or do they require special cultural knowledge or something
            that is not easy for an outsider to answer?
          </div>
        </div>

        <div class="p-field p-col-6 blok">
          <div class="subject">Availability</div>
          <div class="value">
            <p-checkbox :binary="true" v-model="form.twentyFourSeven" /> 24/7
          </div>   
          <div class="key">
            Are all the waypoints in the missions publicly available and open 24-7 - no admittance fees?
          </div>
        </div>

        <div class="p-field p-col-6 blok">
          <div class="subject">Suited means of transportation</div>
          <div class="value">
            <div class="radiobuttons">
              <div><p-checkbox :binary="true" v-model="form.suitedTransportation.foot" /> by foot</div>
              <div><p-checkbox :binary="true" v-model="form.suitedTransportation.bike" /> by bike</div>
              <div><p-checkbox :binary="true" v-model="form.suitedTransportation.car" /> by car</div>
              <div><p-checkbox :binary="true" v-model="form.suitedTransportation.wheelchair" /> wheelchair friendly</div>
            </div>
          </div>   
          <div class="key">
            Which means of transportation are practical for completing the banner?
          </div>
        </div>

        <div class="p-field p-col-6 blok">
          <div class="subject">Transportation</div>
          <div class="value">
            <div class="radiobuttons">
              <div><p-radiobutton v-model="form.transportationUsed" name="transportation" value="foot" /> by foot</div>
              <div><p-radiobutton v-model="form.transportationUsed" name="transportation" value="bike" /> by bike</div>
              <div><p-radiobutton v-model="form.transportationUsed" name="transportation" value="car" /> by car</div>
            </div>
          </div>   
          <div class="key">
            How did <i>you</i> complete the banner?
          </div>
        </div>

        <div class="p-field p-col-6 blok">
          <div class="subject">Did you enjoy it?</div>
          <div class="value">
            <div class="radiobuttons">
              <div><p-radiobutton v-model="form.enjoyed" name="enjoyed" :value="true" /> yes! <i class="fas fa-thumbs-up" /></div>
              <div><p-radiobutton v-model="form.enjoyed" name="enjoyed" :value="false" /> no! <i class="fas fa-thumbs-down" /></div>
            </div>
          </div>
          <div class="key">
            All in all - did you enjoy it? Would you recommend it to someone else?
          </div>
        </div>

        <div class="p-field p-col-12 p-fluid">
          <label for="comment">Comment</label><br>
          <p-text-area id="comment" v-model="form.comment" :rows="4" />
        </div>

        <div class="p-field p-col-12 p-grid">
          <div class="p-col-6">
            <p-button icon="fas fa-times" label="Oops, I didn't complete this" @click="deleteReview" />
          </div>
          <div class="p-col-6 p-text-right">
            <p-button icon="fas fa-save" label="Cancel" @click="cancelReview" />
            <p-button icon="fas fa-save" label="Save!" @click="saveReview" />
          </div>
        </div>

      </div>

    </div>


  </p-dialog>
</template>

<style lang="scss" scoped>

.dlg {
  min-width: 50vw;
}

.items {
  width: 80vw;
  > * {
    padding-bottom: 1em;
  }
}

.kek {
  > * {
    display: block;
    text-align: left;
  }
}

small {
  color: var(--text-color-secondary);
  font-size: 0.8em;
}

.blok {
  .subject {

  }

  .key {
    margin-top: 0.5em;
    color: var(--text-color-secondary);
    font-size: 0.8em;
  }

  .value {
    margin-top: 0.5em;

  }
}

.radiobuttons {
  display: flex;
  font-size: 0.8em;
  flex-direction: row;
  > * {
    margin-right: 0.5em;
  }
}

</style>

<style lang="scss">
</style>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { ICompletionReview } from '../../../shared/src/types';

export default defineComponent({

  name: 'BannerCompletionDialog',

  inject: [ 'state', BACKEND_KEY ],

  props: {
    show: {
      type: Boolean,
      default: true
    },
    hasPassphrases: {
      type: Boolean,
      default: false
    },
    review: {
      type: Object as PropType<ICompletionReview>,
      default: () => ({}),
      required: true
    }
  },

  data() {
    return {

      visible: false,

      dirty: false,

      headingClass: "p-col-12",
      keyClass: "p-col-6",
      valueClass: "p-col-6 p-text-right",

      icons,

      form: {
        comment: 'This took me to many new pokestops, and I really liked all the playground portals!',
        enjoyed: null as boolean | null,
        twentyFourSeven: false as boolean | null,
        artworkRating: 0,
        difficultyRating: 0,
        routeRating: 0,
        passphraseRating: 0,
        transportationUsed: null as string | null,
        suitedTransportation: {
          foot: false as boolean | null,
          bike: false as boolean | null,
          car: false as boolean | null,
          wheelchair: false as boolean | null
        }
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler(to) {
        this.dirty = true;
      }
    },
    review: {
      immediate: true,
      deep: true,
      handler(to: ICompletionReview, from: ICompletionReview) {
        this.form.artworkRating = to.artworkRating;
        this.form.difficultyRating = to.difficultyRating;
        this.form.routeRating = to.routeRating;
        this.form.passphraseRating = to.passphraseRating;
        if (to.completedByBike) this.form.transportationUsed = 'bike';
        else if (to.completedByCar) this.form.transportationUsed = 'car';
        else if (to.completedByFoot) this.form.transportationUsed = 'foot';
        else this.form.transportationUsed = null;
        this.form.suitedTransportation = {
          foot: to.suitableTransportation?.foot,
          bike: to.suitableTransportation?.bike,
          car: to.suitableTransportation?.car,
          wheelchair: to.suitableTransportation?.wheelchair
        },
        this.form.twentyFourSeven = to.twentyFourSeven;
        this.form.enjoyed = to.enjoyed;
        this.form.comment = to.comment;
        this.dirty = false;
      }
    }
  },

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  methods: {

    showDialog() {
      this.visible = true;
      this.dirty = false;
    },

    cancelReview() {
      if (!this.dirty || confirm("Dismiss your changes?")) {
        this.visible = false;
      }
    },

    deleteReview() {
      if (confirm("Mark this as not completed and delete any review data you may have previously submitted?")) {
        this.$emit('oops');
        this.visible = false;
      }
    },

    saveReview() {
      let review: ICompletionReview = {
        artworkRating: this.form.artworkRating,
        difficultyRating: this.form.difficultyRating,
        routeRating: this.form.routeRating,
        passphraseRating: this.form.passphraseRating,
        twentyFourSeven: this.form.twentyFourSeven!,
        enjoyed: this.form.enjoyed!,
        comment: this.form.comment,
        completedByFoot: this.form.transportationUsed == 'foot',
        completedByBike: this.form.transportationUsed == 'bike',
        completedByCar: this.form.transportationUsed == 'car',
        suitableTransportation: {
          foot: this.form.suitedTransportation.foot!,
          bike: this.form.suitedTransportation.bike!,
          car: this.form.suitedTransportation.car!,
          wheelchair: this.form.suitedTransportation.wheelchair!
        }
      }
      this.visible = false;
      this.$emit("update:review", review);
      this.$emit("save", review);
    }
  },

  mounted() {
    this.dirty = false;
  }

});

</script>
