import { InjectionKey } from '@vue/runtime-core';

const bannersPath = "b"; // "banners"
const missionsPath = "m"; // "missions"
const profilePath = "profile"; 

import {
  ISearchResults,
  IBanner,
  MissionInfo,
  ICountryInfo,
  IUser,
  IGeoAdminLevel,
  ICompletionReview
} from '../../../shared/src/types';

//export const BACKEND_KEY: InjectionKey<BackendService> = Symbol('backendService');
export const BACKEND_KEY = '_backend_service_';

import axios from 'axios';

export class BackendService {

  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  //// AUTH ////

  async getAuth(): Promise<IUser> {
    return (await axios.get(`${this.baseUrl}auth`)).data;
  }

  async logOut(): Promise<void> {
    await axios.get('/auth/logout');
  }

  //// PROFILE ////

  async updateProfile(profile: IUser): Promise<IUser> {
    return (await axios.put(`${this.baseUrl}${profilePath}`, profile)).data;
  }

  async requestNewApiKey(): Promise<string> {
    let resp = (await axios.put(`${this.baseUrl}${profilePath}/api-key`));
    console.dir(resp);
    return resp.data.apiKey;
  }

  async requestVerifyAgent(): Promise<string> {
    return (await axios.put(`${this.baseUrl}${profilePath}/agent-verify`));
  }

  //// IMAGES ////

  getBannerImage(banner: IBanner | string, size: "large" | "small" | "tiny" = "small", preferPng: boolean = false): string {
    let bannerId = (typeof banner == 'object') ? banner.guid : <string>banner;
    let ext = preferPng ? 'png' : 'jpg';
    return `/static/images/banner-${bannerId}-${size}.${ext}`;
  }

  //// GEO ////

  async getGeoCountries(mode: string): Promise<ICountryInfo[]> {
    let url = `${this.baseUrl}geo/country?mode=${mode}`;
    return (await axios.get(url)).data;
  }

  async getGeoCountry(name: string, mode: string): Promise<IGeoAdminLevel[]> {
    let url = `${this.baseUrl}geo/country/` + encodeURIComponent(name) + `?mode=${mode}`;
    return (await axios.get(url)).data as IGeoAdminLevel[];
  }

  //// MISSIONS ////

  async getMissionsByGuids(guids: string[], detailed: boolean = true): Promise<Array<MissionInfo>> {
    let url = `${this.baseUrl}${missionsPath}/guids/` + guids.join(",") + "?t=" + (detailed ? "details" : "summary") + "&pageSize=0";
    return (await axios.get(url)).data.results;
  }

  async searchMissions(query: ISearchMissionsOptions): Promise<ISearchResults<MissionInfo>> {
    let url = `${this.baseUrl}${missionsPath}/search?title=` + encodeURIComponent(query.title || "*");
    if (query.page != null) url += "&page=" + query.page;
    if (query.pageSize != null) url += "&pageSize=" + query.pageSize;
    if (typeof query.requireFavorite == 'boolean') url += "&requireFavorite=" + (query.requireFavorite?1:0);
    if (typeof query.requireDetails == 'boolean') url += "&requireDetails=" + (query.requireDetails?1:0);
    if (typeof query.requireRecent == 'boolean') url += "&requireRecent=" + (query.requireRecent?1:0);
    if (typeof query.requireUnused == 'boolean') url += "&requireUnused=" + (query.requireUnused?1:0);
    return (await axios.get(url)).data;
  }

  //// BANNERS ////

  async createBanner(banner: IBanner): Promise<IBanner> {
    let res = await axios.post(`${this.baseUrl}${bannersPath}`, banner);
    return res.data;
  }

  async updateBanner(banner: IBanner): Promise<IBanner> {
    let res = await axios.put(`${this.baseUrl}${bannersPath}/${banner.guid}`, banner);
    return res.data;
  }

  async deleteBanner(banner: IBanner): Promise<void> {
    await axios.delete(`${this.baseUrl}${bannersPath}/${banner.guid}`);
  }

  async toggleBannerFavorite(banner: IBanner, state: boolean): Promise<IBanner> {
    let res = state
      ? await axios.post(`${this.baseUrl}${bannersPath}/${banner.guid}/favorite`)
      : await axios.delete(`${this.baseUrl}${bannersPath}/${banner.guid}/favorite`);
    return res.data;
  }

  async toggleBannerToDo(banner: IBanner, state: boolean): Promise<IBanner> {
    let res = state
      ? await axios.post(`${this.baseUrl}${bannersPath}/${banner.guid}/todo`)
      : await axios.delete(`${this.baseUrl}${bannersPath}/${banner.guid}/todo`);
    return res.data;
  }

  async toggleBannerCompleted(banner: IBanner, state: boolean, review?: ICompletionReview): Promise<IBanner> {
    let res = state
      ? await axios.post(`${this.baseUrl}${bannersPath}/${banner.guid}/completed`, review)
      : await axios.delete(`${this.baseUrl}${bannersPath}/${banner.guid}/completed`);
    return res.data;
  }

  async getBannersMap(x: number, y: number, z: number) {
    let res = await axios.get(`${this.baseUrl}${bannersPath}/geo/${z}/${x}/${y}`);
    return res.data;
  }

  async getBanner(id: string): Promise<IBanner> {
    let res = await axios.get(`${this.baseUrl}${bannersPath}/${encodeURIComponent(id)}?full=1`);
    return res.data;
  }

  async getBanners(options?: any): Promise<ISearchResults<IBanner>> {
    let qs = [];
    if (options) {
      for (let k in options) {
        let v = options[k];
        if (typeof v == 'object') v = JSON.stringify(v);
        else (v = v || "").toString();
        qs.push(`${k}=${encodeURIComponent(v)}`)
      }
    }
    return (await axios.get<ISearchResults<IBanner>>(`${this.baseUrl}${bannersPath}/search?${qs.join("&")}`)).data;
  }

}

export interface ISearchMissionsOptions {
  page?: number;
  pageSize?: number;
  title?: string;
  requireDetails?: boolean;
  requireFavorite?: boolean 
  requireRecent?: boolean;
  requireUnused?: boolean;
}