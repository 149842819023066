

import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';

export default defineComponent({

  inject: [ BACKEND_KEY, 'state' ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {
      loading: false,
      icons: Object.freeze(icons),
      bannerData: {} as IBanner,
      selectTitleFromMission: false,
      selectDescriptionFromMission: false,
      rowSizes: [
        { value: 6, label: '6 - Regular' },
        { value: 5, label: '5' },
        { value: 4, label: '4' },
        { value: 3, label: '3 - Half' },
        { value: 2, label: '2' },
        { value: 1, label: '1' },
      ],
    }
  }

});

