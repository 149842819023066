<template>

  <div class="appContent" v-if="!banner" >
    <p-dialog :modal="true" :closeOnEscape="false" :closable="false" :visible="true">
      <template #header>
        Loading
      </template>
      Loading, please wait..
    </p-dialog>
  </div>

  <div v-else class="p-grid p-nogutter">

    <set-title :title="banner.title" />

    <BannerCompletionDialog ref="reviewDialog" :hasPassphrases="true" v-model:review="review" @save="saveReview" @oops="deleteReview" />

    <p-dialog :modal="true" :closeOnEscape="false" :closable="false" v-model:visible="saving">
      <template #header>
        Saving
      </template>
      Saving, please wait..
    </p-dialog>

    <div class="p-xl-3 p-lg-4 p-md-4 p-sm-12">
      <div style="padding: 1em; box-sizing: border-box">

        <BannerImage
          :banner="banner"
          maxHeight="40vh"
          :auto="true"
          :fillHeight="false"
          style="width: 100%"
          />

        <div class="xp-grid userDataContainer" v-if="state && state.user">

          <div
            class="xp-col-4 p-text-center userData"
            :class="{ checked: isFavorite }"
            @click="toggleFavorite"
            >
            <i class="icon" :class="isFavorite ? icons.bookmarkTrue : icons.bookmarkFalse" /> <span class="text x-clickable">Bookmark</span>
          </div>

          <div
            class="xp-col-4 p-text-center userData"
            :class="{ checked: isToDo }"
            @click="toggleToDo"
            >
            <i class="icon" :class="isToDo ? icons.todoTrue : icons.todoFalse" /> <span class="text x-clickable">To-Do</span>
          </div>

          <div
            class="xp-col-4 p-text-center userData"
            :class="{ checked: isCompleted }"
            @click="startReview"
            >
            <i class="icon" :class="isCompleted ? icons.completedTrue : icons.completedFalse" /> <span class="text x-clickable">Completed</span>
          </div>

          <div
            v-if="isAdminOrOwner(banner)"
            class="xp-col-4 p-text-center userData"
            @click="$refs.op.toggle($event)"
            >
            <i class="icon fas fa-cog" /> <span class="text x-clickable">Manage</span>
          </div>

        </div>

        <BannerToolsPopup
          ref="op"
          :banner="banner"
          :tools="false"
          :manage="true"
          />

        <br>

        <h1 class="p-d-md-none">{{ banner.title }}</h1>
        <div class="description p-d-md-none">{{ banner.description }}</div>
        <BannerStatsTable :banner="banner" />
      </div>

    </div>

    <div class="p-xl-9 p-lg-8 p-md-8 p-sm-12" style="padding: 1em;">

      <h1 class="p-d-none p-d-xl-block p-d-lg-block p-d-md-block">{{ banner.title }}</h1>
      <div class="p-d-none p-d-xl-block p-d-lg-block p-d-md-block description">{{ banner.description }}</div>
      
      <p-tabmenu :model="tabs" class="x-space-above x-space-below" />

      <router-view
        v-if="!fullscreen"
        :banner="banner"
        :class="{ fullscreen: fullscreen }"
        />
      
    </div>

    <div v-if="fullscreen" id="#fullscreen" class="fullscreen">
      <router-view
        :banner="banner"
        />
    </div>

  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

import { icons } from '../lib/icons';
import { IBanner, IBannerUserDataPersonalized, ICompletionReview, filterCommentReviews } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import BannerImage from '../components/BannerImage.vue';
import BannerStatsTable from '../components/BannerStatsTable.vue';
import BannerMapPreview from '../components/BannerMap.vue';
import { IState, isAdminOrOwner, isAdmin } from '../state';

import BannerCompletionDialog from '../components/BannerCompletionDialog.vue';

export default defineComponent({

  name: 'ViewBanner',

  inject: [ 'state', BACKEND_KEY ],

  components: {
    BannerImage,
    BannerStatsTable,
    BannerMapPreview,
    BannerCompletionDialog
  },

  computed: {

    fullscreen(): boolean {
      return this.$route.query.fullscreen == 'true';
    },

    STATE(): IState {
      return (<any>this).state as IState;
    },

    tabs(): Array<any> {
      let bannerId = this.bannerId;
      return [
        { label: 'Missions', icon: icons.mission, to: `/banner/${bannerId}/missions` },
        { label: 'Map', icon: icons.map, to: `/banner/${bannerId}/map` },
        { label: 'Comments (' + filterCommentReviews(this.banner?.reviews).length + ')', icon: 'fas fa-comment', to: `/banner/${bannerId}/comments` },
        //{ label: 'Export', icon: 'fas fa-download', to: `/banner/${bannerId}/export` },
      ]
    },

    loadMap(): boolean {
      if (this.mapLoaded) return true;
      else if (this.tab == "map") {
        this.$nextTick(() => {
          this.mapLoaded = true;
        })
        return true;
      } else {
        return false;
      }
      
    },

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },

    bannerId(): string {
      return <string>this.$route.params.bannerId;
    },

  },

  beforeRouteLeave(to, from, next) {
    this.isLeaving = true;
    next();
  },

  data() {
    return {

      isLeaving: false,
      review: {} as ICompletionReview,

      saving: false,

      isCompleted: false,
      isToDo: false,
      isFavorite: false,

      tab: "missions",
      mapLoaded: false,
      icons: Object.freeze(icons),
      banner: null as IBanner | null
    }
  },

  watch: {
    bannerId: {
      immediate: true,
      handler(to: any, from: any) {
        if (!this.isLeaving && to != from) this.load();
      }
    }
  },

  methods: {

    isAdmin,
    isAdminOrOwner,

    startReview() {
      let dlg = this.$refs.reviewDialog as any;
      dlg.showDialog();
    },

    saveReview(review: ICompletionReview) {
      this.toggleCompleted(true, review);
      if (review) {
        // fake-insert
        let user = this.STATE.user!;
        let banner = this.banner!;
        review.createdAt = Date.now();
        review.modifiedAt = Date.now();
        review.createdBy = user._id;
        if (!banner.reviews) banner.reviews = [];
        if (!banner.$refs) banner.$refs = {};
        if (!banner.$refs.users) banner.$refs.users = {};
        banner.$refs.users[user._id!] = user;
        banner.reviews = banner.reviews.filter(r => r.createdBy != user._id);
        banner.reviews.push(review);
      }
    },

    deleteReview(review: ICompletionReview) {
      this.toggleCompleted(false);
      let user = this.STATE.user!;
      let banner = this.banner!;
      if (banner.reviews) {
        banner.reviews = banner.reviews.filter(r => r.createdBy != user._id);
      }
    },

    async toggleCompleted(state?: boolean, review?: ICompletionReview) {
      try {
        if (state == null) state = !this.isCompleted;
        if (review) this.review = review;
        this.saving = true;
        await this.backendService.toggleBannerCompleted(this.banner!, state, review);
        this.isCompleted = state;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async load() {
      this.banner = await this.backendService.getBanner(<string>this.bannerId);
      let review = this.banner?.reviews?.find(r => r.createdBy == this.STATE.user?._id);
      if (review) this.review = review;

      this.isFavorite = false;
      this.isCompleted = false;
      this.isToDo = false;
      if (this.STATE && this.STATE.user) {
        if (this.banner && this.banner.userData) {
          let ud = this.banner.userData as IBannerUserDataPersonalized;
          this.isFavorite = ud.favoritedBy;
          this.isCompleted = ud.completedBy;
          this.isToDo = ud.toDoBy;
        }
      }
    },

    async toggleFavorite() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerFavorite(this.banner!, !this.isFavorite);
        this.isFavorite = !this.isFavorite;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async toggleToDo() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerToDo(this.banner!, !this.isToDo);
        this.isToDo = !this.isToDo;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    }

  }
});

</script>

<style lang="scss" scoped>

.fullscreen {
  z-index: 100;
  left: 0;
  top: 5em;
  right: 0;
  bottom: 0;
  position: fixed !important;
}

.userDataContainer {

  margin-top: 1em;
  background-color: var(--surface-200);

  .userData {

    cursor: pointer;
    user-select: none;

    .icon {
      display: block;
    }
    .text {

    }
    &.checked {
      .icon {
        color: var(--primary-color);
      }
      font-weight: bold;
    }
  }

}

.userDataContainer {
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: row;
  > * {
    flex: 1;
    text-align: center;
  }
}

</style>