<template>
  <span class="image">
    <img :src="src" />
  </span>
</template>

<style lang="scss" scoped>
span.image {
  display: inline-block;
  img {
    width: 100%;
  }
}
</style>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutoScaleImage',
  props: {
    src: {
      type: String,
      required: true
    }
  }
})

</script>
