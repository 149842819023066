

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionInfoDetailed, ISearchResults, MissionInfo } from '../../../shared/src/types';
import { formatTime, formatDistance, formatRating, formatAddress } from '../lib/utils';
import { BACKEND_KEY, BackendService, ISearchMissionsOptions } from '@/services/backend-service';

export default defineComponent({

  inject: [ BACKEND_KEY ],

  computed: {

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
      //return inject(BACKEND_KEY);
    },

    filteredMissions(): Array<MissionInfo> {
      let a = this.addedMissionsMap;
      //let f = new RegExp(this.filterTitle.trim(), "i");
      return this.searchResults.results.filter(m => {
        return ((this.filterIndexed && (<IMissionInfoDetailed>m).detailsModified) || !this.filterIndexed)
          //&& f.test(m.title)
          && !a[m.guid];
      })
    },

    addedMissionsMap(): {[guid: string]: MissionInfo} {
      let map: {[guid: string]: MissionInfo} = {};
      this.bannerData.missions!.forEach(m => map[m.guid] = m);
      return map;
    }

  },

  props: {
    step: {
      type: Number
    },
    mode: {
      type: String,
      default: 'add'
    },
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {

      icons: Object.freeze(icons),
      bannerData: {} as IBanner,

      prevSearchOptions: {} as ISearchMissionsOptions,

      requireFavorite: false,
      requireRecent: false,
      requireUnused: true,
      excludeAlreadyUsed: false,
      excludeNonDetailed: true,

      loading: false,
      filterTitle: '',
      filterIndexed: true,
      searchResults: { results: [], count: 0, page: 0, pageSize: 1, numPages: 0 } as ISearchResults<MissionInfo>,

      leftSelection: [] as IMissionInfoDetailed[],
      rightSelection: [] as IMissionInfoDetailed[],

    }
  },

  methods: {

    formatTime,
    formatDistance,
    formatRating,
    formatAddress,

    clearAll() {
      if (confirm("Do you want to clear everything? (remove missions, clear title/description, etc?")) {
        this.bannerData.missions = [];
        this.bannerData.title = "";
        this.bannerData.description = "";
        this.$emit("update:banner", this.bannerData); // XXX: CLEAN UP THIS LATER.. well, this is not really bound to a temporary object so it's a b it useless
        this.leftSelection = [];
        this.rightSelection = [];
      }
    },

    async search() {
      this.prevSearchOptions = {};
      this.searchNext(0);
    },

    async searchNext(page: number) {
      this.loading = true;
      console.log("PAGE", page);
      try {      
        this.loading = true;
        this.searchResults = await this.backendService.searchMissions({
          ...this.prevSearchOptions,
          title: this.filterTitle,
          requireDetails: true,
          page: page,
          requireFavorite: this.requireFavorite,
          requireRecent: this.requireRecent,
          requireUnused: this.requireUnused
        });
      } finally {
        this.loading = false;
      }
    },

    moveAllRight() {
      this.searchResults.results.forEach(m => {
        let mr = this.bannerData.missions!.find(x => x.guid == m.guid);
        if (!mr)
          this.bannerData.missions!.push(<any>m)
      });
      this.leftSelection = [];
    },

    moveRight() {
      this.leftSelection.forEach(m => this.bannerData.missions!.push(m));
      this.leftSelection = [];
    },

    moveAllLeft() {
      this.bannerData.missions!.forEach(m => {
        if (!this.searchResults.results.find(x => x.guid == m.guid)) {
          this.searchResults.results.push(m);
        }
      })
      this.bannerData.missions = [];
      this.rightSelection = [];
    },

    moveLeft() {
      this.bannerData.missions = this.bannerData.missions!.filter(m => this.rightSelection.indexOf(m) == -1);
      this.rightSelection = [];
    },

    leftColumnDblClicked(m: IMissionInfoDetailed) {
      this.bannerData.missions!.push(m);
    },

    rightColumnDblClicked(m: IMissionInfoDetailed) {
      this.bannerData.missions = this.bannerData.missions!.filter(x => m != x);
      if (!this.searchResults.results.find(x => x.guid == m.guid)) {
        this.searchResults.results.push(m);
      }
    }
  }
});

