<script lang="ts">

import { MissionInfo, IMissionWaypointEx, ILatLng } from '../../../shared/src/types';

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';

export default defineComponent({
  name: 'IntelLink',
  props: {
    latLng: {
      type: Object as PropType<ILatLng>,
      default: null,
      required: false
    },
    waypoint: {
      type: Object as PropType<IMissionWaypointEx>,
      default: null,
      required: false
    },
    portal: {
      type: Object as PropType<IMissionWaypointEx>,
      default: null,
      required: false
    },
    mission: {
      type: Object as PropType<MissionInfo>,
      default: null,
      required: false
    },
    missionId: {
      type: String,
      default: null,
      reqired: false
    },
    zoom: {
      type: Number,
      default: 17,
      required: false
    }
  },
  data() {
    return {
      icons
    }
  },
  computed: {
    url(): string {
      if (this.latLng) {
        let latLng: any = this.latLng;
        if (latLng instanceof Array)
          latLng = { lat: latLng[0], lng: latLng[1] };
        return `https://intel.ingress.com?ll=${latLng.lat},${latLng.lng}&z=${this.zoom}`;
      } else if (this.mission) {
        return `https://intel.ingress.com/mission/${this.mission.guid}`;
      } else if (this.missionId) {
        return `https://intel.ingress.com/mission/${this.missionId}`;
      } else if (this.portal && this.portal.point) {
        return `https://intel.ingress.com?ll=${this.portal.point.lat},${this.portal.point.lng}&pll=${this.portal.point.lat},${this.portal.point.lng}&z=${this.zoom}`;
      } else if (this.waypoint && this.waypoint.point) {
        return `https://intel.ingress.com?ll=${this.waypoint.point.lat},${this.waypoint.point.lng}&pll=${this.waypoint.point.lat},${this.waypoint.point.lng}&z=${this.zoom}`;
      } else {
        return "https://intel.ingress.com/";
      }
    }
  }
});

</script>

<template>
  <a :href="url" target="_new" title="Intel link">
    <slot><i :class="icons.share"></i></slot>
  </a>
</template>

<style lang="scss" scoped>
a {
  padding-left: 0.25em;
  font-size: 0.75em;
}
a, a:visited {
  color: var(--text-color-secondary);
}

</style>