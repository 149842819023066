

import { defineComponent } from 'vue';

import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { IState } from '../state';

import EditBannerDetails from './EditBannerDetails.vue';
import MissionList from '@/components/MissionList.vue';
import IngressMap from '@/components/IngressMap.vue';

import { sortMissionsAlpha, sortMissionsSequence } from '../lib/sorting';
export default defineComponent({

  name: 'EditBanner',

  components: {
    MissionList,
    EditBannerDetails,
    IngressMap
  },

  inject: [ 'state', BACKEND_KEY ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
      //return inject(BACKEND_KEY);
    },
    filteredMissionsInView(): Array<IMissionInfoDetailed> {
      try {
        let titleRegex = new RegExp(this.titleFilter, "i");
        let filtered = this.missionsInView.filter(m => titleRegex.test(m.title));
        console.dir(filtered);
        return filtered;
      } catch {
        return this.missionsInView;
      }
    }
  },

  data() {
    return {
      sortChinese: false,
      sortRoman: false,
      titleFilter: '',
      banner: null! as IBanner,
      missionsInView: [] as Array<IMissionInfoDetailed>,
      zoom: 15,
      center: new L.LatLng(59.1, 10.9) as L.LatLngExpression
    }
  },

  async created() {
    let bannerId = "lucky-187f44a5";
    this.banner = await this.backendService.getBanner(bannerId);
    if (this.$route.params.zoom) this.zoom = parseFloat(this.$route.params.zoom.toString());
    if (this.$route.params.lat && this.$route.params.lng) {
      this.center = [
        parseFloat(this.$route.params.lat.toString()),
        parseFloat(this.$route.params.lng.toString())
      ];
    }
  },

  methods: {

    addMission(mission: IMissionInfoDetailed) {
      // TODO
    },

    resizeMissionImage(url: string) {
      return url;
    },

    setMissionsInView(missions: Array<IMissionInfoDetailed>) {
      this.missionsInView = sortMissionsAlpha(missions, {
        naturalize: true,
        fixChinese: this.sortChinese,
        fixRoman: this.sortRoman
      })
    },

    mapMoved(ev: any) {
      let map = ev.target as L.Map;
      let c = map.getCenter();
      let z = map.getZoom();
      history.replaceState(null, '', `/add_v2/${c.lat}/${c.lng}/${z}`);
    },

    showOnMap(mission: IMissionInfoDetailed) {
      let map = (<any>this).$refs.map.getMap() as L.Map;
      map.setView(new L.LatLng(mission.startPoint!.lat, mission.startPoint!.lng), Math.max(this.zoom, 15));
      // this.zoom = Math.max(this.zoom, 15);
      // this.center = new L.LatLng(mission.startPoint!.lat, mission.startPoint!.lng);
    }
  }

});
