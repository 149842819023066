import {
  IBanner,
  WaypointObjective,
  WaypointObjectiveMap,
  WaypointObjectiveTexts,
  WaypointObjectiveTextsShort
} from '../../../shared/src/types';

export function formatTime(t: number) {
  t /= 1000;
  let d = Math.floor(t / 86400);
  t %= 86400;
  let h = Math.floor(t / 3600);
  t %= 3600;
  let m = Math.floor(t / 60);
  t %= 60;
  let s = Math.round(t);
  return [
    d ? d+'d' : null,
    h ? h+'h' : null,
    m ? m+'m' : null,
    s ? s+'s' : null
  ].filter(s => s).join(" ")
}

export function formatDistance(t: number): string {
  if (t > 1000) {
    return (Math.round(t) / 1000).toFixed(1) + ' km';
  } else {
    return Math.round(t) + ' m';
  }
}

export function formatEfficiency(n: number): string {
  return n != null ? n.toFixed(1) : '';
}

export function formatRating(r: number): string {
  return Math.round(r) + "%";
}

export function filteredWaypointObjectivesStats(stats: WaypointObjectiveMap): {[text: string]: number} {
  try {
    let list: {[text: string]: number} = {};
    for (let key in stats) {
      let wo = <WaypointObjective><unknown>key;
      let text = WaypointObjectiveTextsShort[wo];
      let count = stats[wo];
      if (count != null && count > 0) list[text] = count;
    }
    return list;
  } catch (err) {
    return {};
  }
}

export function formatAddress(banner: IBanner, max?: number): string[] {
  if (banner.addressHierarchy) {
    let addr = <string[]>banner.addressHierarchy.filter(x => x && x.length);
    addr = addr.filter((a,i) => a != addr[i-1]);
    if (typeof max == 'number') {
      addr = addr.slice(0, max);
    }
    return addr;
  } else {
    return ["Unknown"]
  }
}

export interface IAddressPart {
  name: string;
  array: Array<string>;
  link: string;
}

export function formatAddress2(banner: IBanner, max?: number): IAddressPart[] {
  if (banner.addressHierarchy) {

    let sofar: string[] = [];
    let addr: Array<IAddressPart> = banner.addressHierarchy.map(a => {
      sofar.push(a!);
      return { name: a!, array: [...sofar], link: '/banners/' + sofar.map(s => s != null && s != "-" ? encodeURIComponent(s) : '-').join("/") }
    });

    addr = addr.filter(x => x.name && x.name.length);
    addr = addr.filter((a,i) => a.name != addr[i-1]?.name);

    if (typeof max == 'number') {
      addr = addr.slice(0, max);
    }
    return addr;
  } else {
    return [ { name: "Unknown", array: [], link: '/banners' } ];
  }
}

export function decodeAddress(address: any) {
  let chunks = [];
  if (address.country) chunks.push(address.country);
  if (address.county) chunks.push(address.county);
  if (address.county_district) chunks.push(address.county_district);
  if (address.municipality) chunks.push(address.municipality);
  if (address.city) chunks.push(address.city);
  if (address.town) chunks.push(address.town);
  if (address.village) chunks.push(address.village);
  if (address.suburb) chunks.push(address.suburb);
  if (address.neighbourhood) chunks.push(address.neighbourhood);
  return chunks;
}

export function parseBool(value: any, defaultValue: boolean = false): boolean {
  if (typeof value == 'boolean') return value;
  else if (typeof value == 'number') return value !== 0;
  else if (value === null || value === undefined) return false;
  else {
    value = value.toString().trim().toLowerCase();
    if (value == "true" || value == "yes" || value == "on") return true;
    else if (value == "false" || value == "no" || value == "off") return false;
    else {
      let n = parseInt(value);
      if (!isNaN(n)) return n !== 0;
    }
    return defaultValue;
  }
}

export function stripTrailingNulls<T>(a: Array<T>): Array<T> {
  let b: Array<T> = [ ...a ].reverse();
  for (let i = 0; b.length>i; i++) {
    if (b[i] != null) return b.splice(i).reverse();
  }
  return [];
}
