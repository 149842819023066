<template>
  <div style="display: none">
    <div ref="popup" class="popup">
      <div class="title">{{ mission.title }}</div>
      <img
        class="image"
        :key="`image-${mission.guid}`"
        :src="mission.image">
      <div class="tools">
        actions..
        <!--
        <a :href="`/banner/${ banner.slug || banner.guid }`"><i class="fas fa-info" /> details</a>
        &middot;
        <a @click="toggleRoute(banner)"><i class="fas fa-route" /> route</a>
        -->
      </div>
      <div class="stats">
        info..
        <!--
        <i :class="icons.mission" /> {{ banner.numMissions }} m 
        &middot;
        <i :class="icons.mission" /> {{ banner.numWaypoints }} wp 
        &middot;
        <i :class="icons.distance" /> {{ formatDistance(banner.distance) }} 
        -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';
import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';
import { icons } from '../lib/icons';
import { formatDistance } from '../lib/utils';

const htmlEscape = require('xml-escape');

import { stripGoogleImageParams } from '../../../shared/src/googleImageParams';

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  props: {
    mission: {
      type: Object as PropType<IMissionInfoDetailed>,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    banner: {
      type: Object as PropType<IBanner>,
      required: false
    }
  },

  data() {
    return {
      icons,
      frozen: { 
        marker: null! as L.Marker
      }
    }
  },

  mounted() {
    // console.log("marker loaded");
    let layer = (<any>this).layer() as L.LayerGroup<any>;
    if (this.mission.startPoint) {
      let marker = L.marker(this.mission.startPoint!, {
        icon: this.getIcon()
      });
      marker.on('popupopen', ev => {
      })
      marker.bindPopup(<any>this.$refs.popup);
      (<any>marker).bindTooltip(`<img src="${this.mission.image}" style="width: 48px; height: 48px; border-radius: 24px; border: 2px solid #F59614;"><br>${htmlEscape(this.mission.title)}`).openTooltip();
      layer.addLayer(marker);
      this.frozen = Object.freeze({ marker });
    } else {
      console.warn(`mission ${this.mission.guid} start point is null`, { mission: this.mission })
    }
  },

  beforeUnmount() {
    // console.log("marker unloaded");
    let layer = (<any>this).layer() as L.LayerGroup<any>;
    layer.removeLayer(this.frozen.marker);
  },

  methods: {

    getIcon(): L.Icon {

      let sz = 32;

      let im = stripGoogleImageParams(this.mission.image, `=w${sz}-h${sz}`);

      var myIcon = L.divIcon({
        className: 'my-div-icon',
        iconSize: [ sz, sz ],
        iconAnchor: [ sz/2, sz/2 ],
        html: `<div style="width: ${sz}px; height: ${sz}px">
          <img src="${this.mission.image}" style="width: ${sz}px; height: ${sz}px; border-radius: ${sz/2}px; border: 2px solid gold; box-shadow: 0px 0px 8px rgba(0,0,0,0.5)">
          <!--
          <div
            style="margin-left: -30px; overflow: hidden; padding: 0.5em; width: 100px; color: black; font-size: 0.7em; background-color: rgba(255,255,255,0.8)"
            >${htmlEscape(this.mission.title)}</div>
          -->
        </div>`
      });

      return myIcon;

    },

    formatDistance,

    async toggleRoute(banner: IBanner) {

      /*

      let b = this.bannerDetails[banner.guid!];
      if (!b) {
        let bb = await this.backendService.getBanner(banner.guid!);
        b = this.bannerDetails[banner.guid!] = {
          banner: bb,
          routeLayerGroup: null!,
          routeVisible: false
        };
      }

      if (b.routeLayerGroup == null) {
        let lg = new L.LayerGroup<any>();
        let p: IMissionWaypointEx | undefined = undefined;

        let colors = colorRange(b.banner.missions!.length).map((c: string) => '#' + c);

        let lines: any[] = [];
        let connectors: any[] = [];

        b.banner.missions?.forEach((mission, i) => {
          let f = findFirstWaypoint(mission);
          if (p != null) {
            connectors.push([
              [ p!.point!.lat, p!.point!.lng ],
              [ f!.point!.lat, f!.point!.lng ]
            ]);
          }

          let waypoints = filterValidWaypoints(mission);
          let coords = waypoints.map(w => [ w.point.lat, w.point.lng ]);
          lines.push(coords);

          p = findLastWaypoint(mission);
        })

        let weight = 2;
        let weightExtra = 4;
        let radius = 4;
        let radiusExtra = 2;
        let bgColor = "black";
        lines.forEach((coords, i) => {
          let line = new L.Polyline(coords, { color: bgColor, weight: weight+weightExtra });
          lg.addLayer(line);
          let start = new L.CircleMarker(coords[0], {
            radius: radius+radiusExtra, color: bgColor, fillColor: bgColor, fill: true, fillOpacity: 1
          })
          lg.addLayer(start);
        });

        connectors.forEach(coords => {
          let cline = new L.Polyline(coords, { color: '#999', weight: weight, dashArray: '4,2' });
          lg.addLayer(cline);
        })

        lines.forEach((coords, i) => {
          let mission = b.banner.missions![i];
          let color = colors[i]
          let line = new L.Polyline(coords, { color: color, weight: weight });
          lg.addLayer(line);
          (<any>line).bindTooltip(mission.title);
          let start = new L.CircleMarker(coords[0], {
            radius: radius, color: color, fillColor: color, fill: true, fillOpacity: 1
          })
          lg.addLayer(start);
        });

        lg.addTo(this.frozen.map);
        b.routeLayerGroup = lg;
      } else {
        this.frozen.map.removeLayer(b.routeLayerGroup);
        b.routeLayerGroup = null!;
      }
      */

    }







  }

})

</script>

<style lang="scss" scoped>


.popup {
  width: 210px;
  .title {
    font-weight: bold;
    font-size: 1.2em;
  }
  .image {
    padding: 4px;
    background-color: black;
    width: 128px;
    height: 128px;
    border-radius: 64px;
    border: goldenrod 2px solid;
  }
  .tools {
    padding-top: 0.5em;
    font-size: 1.1em;
  }
  .stats {
    padding-top: 0.5em;
    font-size: 0.9em;
    color: #666;
  }
}

</style>
