

import * as L from 'leaflet';
import { defineComponent, PropType, ComponentPublicInstance } from 'vue';

import { TileDataState, ITileData, MercatorTileLoader } from '../lib/mercator-tile-loader';

const TileColors: {[key in TileDataState]: string} = {
  [TileDataState.Pending]: 'orange',
  [TileDataState.Loading]: 'yellow',
  [TileDataState.Failed]: 'red',
  [TileDataState.Aborted]: 'orange',
  [TileDataState.Loaded]: 'green'
}

export default defineComponent({

  inject: [
    'layer', 'map', 'mapComponent'
  ],

  props: {

    enableCache: {
      type: Boolean,
      default: true
    },

    hashTile: {
      type: Function as PropType<(tile: ITileData<any>) => string>,
      required: false
    },

    loadedTileData: {
      type: Function as PropType<(data: any) => void>,
      required: false
    },

    loadedData: {
      type: Function as PropType<(data: any[]) => void>,
      required: false
    },

    loadTileData: {
      type: Function as PropType<(tile: ITileData<any>) => void>,
      required: true
    },

    maxZoom: {
      type: Number,
      default: 17
    },

    minZoom: {
      type: Number,
      default: 5
    },

    tileSize: {
      type: Number,
      default: 256
    },

    concurrency: {
      type: Number,
      default: 10
    }

  },

  data() {
    return {
      tiles: [] as ITileData<any>[],
      TileColors,
      loader: undefined! as MercatorTileLoader<any>,
      moveTimeout: null as any,
      isReady: false,
      frozen: { 
        layerGroup: null! as L.LayerGroup<any>,
        tilePolys: {} as {[key: string]: L.Polygon}
      },
      startMove: null! as Function,
      endMove: null! as Function
    }
  },

  mounted() {
    console.log("layergroup loaded");
    let map = (<any>this).map() as L.Map;
    let mapComponent = (<any>this).mapComponent() as any;
    console.log("map component", mapComponent);
    let layerGroup = L.layerGroup([]);
    layerGroup.addTo(map);
    this.frozen = Object.freeze({ layerGroup, tilePolys: {} });
    this.isReady = true;

    this.startMove = () => {
      // console.log("startMove")
      clearTimeout(this.moveTimeout);
    }

    this.endMove = () => {
      // console.log("endMove")
      this.updateData();
    }

    mapComponent.$on('movestart', this.startMove);
    mapComponent.$on('moveend', this.endMove);
    mapComponent.$on('zoomstart', this.startMove);
    mapComponent.$on('zoomend', this.endMove);

    this.setupLoader();
    this.updateData(true);
  },

  beforeUnmount() {
    console.log("layergroup unloaded");

    let mapComponent = (<any>this).mapComponent() as any;
    let map = (<any>this).map() as L.Map;

    mapComponent.$off('movestart', this.startMove);
    mapComponent.$off('moveend', this.endMove);
    mapComponent.$off('zoomstart', this.startMove);
    mapComponent.$off('zoomend', this.endMove);

    map.removeLayer(this.frozen.layerGroup);
  },

  methods: {

    setupLoader() {

      let cache: {[id: string]: ITileData<any>} = {};

      let makeTileKey = (tile: ITileData<any>): string => {
        return this.hashTile
          ? this.hashTile(tile)
          : `${tile.x}_${tile.y}_${tile.z}`;
      }
      const updateTilePoly = (tile: ITileData<any>) => {
        let key = makeTileKey(tile);
        let poly = this.frozen.tilePolys[key];
        if (poly) {
          if (tile.state == TileDataState.Loaded) {
            this.frozen.layerGroup.removeLayer(poly);
          }
          poly.setStyle({ fillColor: this.TileColors[tile.state], stroke: false })
        }
      }
      
      this.loader = new MercatorTileLoader({

        size: this.tileSize,
        concurrency: this.concurrency,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,

        openRequest: this.loadTileData,

        checkCache: tile => {
          if (this.enableCache !== true) return undefined;

          let key = makeTileKey(tile);
          let cached = cache[key];
          // console.log("served from cache:", cached);
          return cached;
        },

        onTiles: tiles => {

          this.frozen.layerGroup.clearLayers();
          Object.keys(this.frozen.tilePolys).forEach(k => delete this.frozen.tilePolys[k]);
          //this.frozen.tilePolys = {};
          tiles.forEach(tile => {
            let key = makeTileKey(tile);
            let poly = new L.Polygon(this.makeTilePoly(tile));
            this.frozen.layerGroup.addLayer(poly);
            this.frozen.tilePolys[key] = poly;
            updateTilePoly(tile);
          })

          //console.log("[ev] got tiles", tiles);
          this.tiles = tiles;
        },

        onResults: all => {
          this.frozen.layerGroup.clearLayers();
          if (this.loadedData) this.loadedData(all);
        },

        onTileStatus: tile => {
          updateTilePoly(tile);
        },

        onTileResults: tile => {
          let key = makeTileKey(tile);
          // console.log("[ev] got individual tile", tile);
          if (tile.state == TileDataState.Loaded) {
            if (this.enableCache && cache[key] != tile) {
              // console.log("stored in cache:", tile);
              cache[key] = tile;
            }
          }

          if (this.loadedTileData) this.loadedTileData(tile.data);
        }
      });
    },

    updateData(immediate: boolean = false) {

      let map = (<any>this).map() as L.Map;
      let z = map.getZoom();
      let b = map.getBounds();
      let c = map.getCenter();

      clearTimeout(this.moveTimeout);
      this.moveTimeout = setTimeout(() => {
        let [ n, e, s, w ] = [ b.getNorth(), b.getEast(), b.getSouth(), b.getWest() ];
        this.loader.update(n, e, s, w, z);
      }, immediate ? 0 : 500);
    },

    makeTilePoly(tile: ITileData<any>) {
      return [
        [ tile.sw.lat, tile.sw.lng ], // sw
        [ tile.ne.lat, tile.sw.lng ], // se
        [ tile.ne.lat, tile.ne.lng ], // ne
        [ tile.sw.lat, tile.ne.lng ], // nw
      ]
    },

  }

})

