

import { defineComponent, PropType } from 'vue';
import { IBanner } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { icons } from '../lib/icons';
import { isAdminOrOwner, isAdmin } from '../state';

export default defineComponent({
  
  name: 'BannerToolsPopup',

  inject: [ 'state', BACKEND_KEY ],

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    },
    tools: {
      type: Boolean,
      default: true
    },
    manage: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  data() {
    return {
      saving: false,
      icons
    }
  },

  methods: {

    isAdmin,

    isAdminOrOwner,

    toggle(ev: Event) {
      let op: any = this.$refs.op;
      op.toggle(ev);
    },
    
    async deleteBanner(banner: IBanner) {
      if (confirm("Are you sure? There is no undo!"))
        await this.backendService.deleteBanner(banner);
    },

    editBanner(banner: IBanner) {
      (<any>this.$refs.op).hide(); // must hide it, otherwise it throws an error during route leave  - https://github.com/primefaces/primevue/issues/1161
      this.$router.push("/edit/" + banner.guid);
      // this.$router.push({
      //   name: 'Edit',
      //   params: <any>{
      //     bannerId: banner.guid
      //   }
      // })
    },

    async toggleFavorite() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerFavorite(this.banner, !this.banner.userData!.favoritedBy);
        this.banner.userData!.favoritedBy = !this.banner.userData!.favoritedBy;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async toggleToDo() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerToDo(this.banner, !this.banner.userData!.toDoBy);
        this.banner.userData!.toDoBy = !this.banner.userData!.toDoBy;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async toggleCompleted() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerCompleted(this.banner, !this.banner.userData!.completedBy);
        this.banner.userData!.completedBy = !this.banner.userData!.completedBy;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    }

  }

})

