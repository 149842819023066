

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  data() {
    return {
      target: null as null | string,
      isReady: false,
      frozen: { 
        control: null! as L.Control
      }
    }
  },

  mounted() {
    console.log("marker loaded");
    let map = (<any>this).map() as L.Map;

    let target = "control_" + Date.now();

    let CustomControl = L.Control.extend({
        options: {
            position: 'topright'
        },
        onAdd(map: any) {
          console.warn("initialize custom control")
          let div = document.createElement("div");
          div.id = target;
          return div;
        }
    });

    let control = new CustomControl() as L.Control;
    control.addTo(map);
    this.frozen = Object.freeze({ control });

    this.$nextTick(() => {
      this.target = "#" + target;
    });

    this.isReady = true;
  },

  beforeUnmount() {
    let map = (<any>this).map() as L.Map;
    map.removeControl(this.frozen.control);
  }

})

