import { reactive, watch } from 'vue';
import { IUser, IBanner } from '../../shared/src/types';

function saveToLocalStorage(key: string, obj: any) {
  localStorage.setItem(key, JSON.stringify(obj));
}

function loadFromLocalStorage(key: string, defaultObj: any = null): any {
  let v = localStorage.getItem(key);
  if (v == null) return defaultObj;
  try {
    return JSON.parse(v);
  } catch (e) {
    return defaultObj;
  }
}

export interface IState {
  user?: IUser;
  userReady: boolean;
  sidebar: boolean;
  editBanner?: IBanner;
  hideAddInstructions: boolean;
}

// simple store using provide/inject and reactivity 
export const state = reactive(<IState>{
  user: undefined,
  userReady: false,
  sidebar: true,
  editBanner: loadFromLocalStorage('editBanner', undefined),
  hideAddInstructions: loadFromLocalStorage('hideAddInstructions', false)
});

watch(state, (to, from) => {
  //console.log("STATE UPDATED: %o -> %o", from, to);
  saveToLocalStorage('editBanner', state.editBanner);
  saveToLocalStorage('hideAddInstructions', state.hideAddInstructions);
}, { deep: true });


export function isAdminOrOwner(banner: IBanner): boolean {
  if (state && state.user) {
    if (state.user.roles && state.user.roles.indexOf('admin') >= 0) // admin
      return true;
    if (state.user._id == banner.createdBy) // owner
      return true;
  }
  return false;
}

export function isAdmin(banner: IBanner): boolean {
  if (state && state.user) {
    if (state.user.roles && state.user.roles.indexOf('admin') >= 0) // admin
      return true;
  }
  return false;
}
