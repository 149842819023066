import { IMissionInfoDetailed } from "../../../shared/src/types";

const nomar = require('nomar');
const sprintf = require('sprintf-js').sprintf;
const chineseNumbersConverter = require("chinese-numbers-converter");
import { parseSequence } from './parseSequence';

export interface IMissionTitleProcessingOptions {
  fixRoman?: boolean;
  fixChinese?: boolean;
  naturalize?: boolean;
}

export function sortMissionsSequence(list: Array<IMissionInfoDetailed>, opts: IMissionTitleProcessingOptions): Array<IMissionInfoDetailed> {
  
  let sequences = list.map(m => {
    let title = normalizeMissionTitle(m.title, opts);
    return parseSequence(title);
  });
  
  return list.map((m, i) => ({ m, i, seq: sequences[i]?.seq }))
      .sort((a, b) => a.seq! - b.seq!)
      .map(x => x.m);
}

export function sortMissionsAlpha(list: Array<IMissionInfoDetailed>, opts: IMissionTitleProcessingOptions) {
  return [ ...list ].sort((a, b) =>
    normalizeMissionTitle(a.title, opts)
    .toLocaleLowerCase()
    .localeCompare(normalizeMissionTitle(b.title, opts).toLocaleLowerCase()));
}

export function normalizeMissionTitle(title: string, opts: IMissionTitleProcessingOptions): string {

  // parse roman numerals
  if (opts.fixRoman) {
    title = title.replace(/(\bM{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,4})(IL|IX|IV|V?I{0,4})\b)/g, x => { // allow IL and XXXX etc
      return x == "" ? "" : nomar(x);
    })
  }

  // parse chinese numerals
  if (opts.fixChinese) {
    let regex = new RegExp('([' + chineseNumbersConverter.characterList + ']+)', 'g');
    title = title.replace(regex, x => {
      if (x == "") return x;
      try {
        let n = new chineseNumbersConverter(x).toInteger();
        return n;
      } catch (err) {
        console.error("chinese number parsing failed", { x, err });
        return x;
      }
    })
  }

  // preprocess numbers
  if (opts.naturalize) {
    title = title.replace(/(\d+)/g, x => sprintf("%010d", x));
  }

  // condense multiple spaces
  title = title.replace(/\s+/g, ' ');
  return title;
}

