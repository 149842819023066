

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { ICompletionReview } from '../../../shared/src/types';

export default defineComponent({

  name: 'BannerCompletionDialog',

  inject: [ 'state', BACKEND_KEY ],

  props: {
    show: {
      type: Boolean,
      default: true
    },
    hasPassphrases: {
      type: Boolean,
      default: false
    },
    review: {
      type: Object as PropType<ICompletionReview>,
      default: () => ({}),
      required: true
    }
  },

  data() {
    return {

      visible: false,

      dirty: false,

      headingClass: "p-col-12",
      keyClass: "p-col-6",
      valueClass: "p-col-6 p-text-right",

      icons,

      form: {
        comment: 'This took me to many new pokestops, and I really liked all the playground portals!',
        enjoyed: null as boolean | null,
        twentyFourSeven: false as boolean | null,
        artworkRating: 0,
        difficultyRating: 0,
        routeRating: 0,
        passphraseRating: 0,
        transportationUsed: null as string | null,
        suitedTransportation: {
          foot: false as boolean | null,
          bike: false as boolean | null,
          car: false as boolean | null,
          wheelchair: false as boolean | null
        }
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler(to) {
        this.dirty = true;
      }
    },
    review: {
      immediate: true,
      deep: true,
      handler(to: ICompletionReview, from: ICompletionReview) {
        this.form.artworkRating = to.artworkRating;
        this.form.difficultyRating = to.difficultyRating;
        this.form.routeRating = to.routeRating;
        this.form.passphraseRating = to.passphraseRating;
        if (to.completedByBike) this.form.transportationUsed = 'bike';
        else if (to.completedByCar) this.form.transportationUsed = 'car';
        else if (to.completedByFoot) this.form.transportationUsed = 'foot';
        else this.form.transportationUsed = null;
        this.form.suitedTransportation = {
          foot: to.suitableTransportation?.foot,
          bike: to.suitableTransportation?.bike,
          car: to.suitableTransportation?.car,
          wheelchair: to.suitableTransportation?.wheelchair
        },
        this.form.twentyFourSeven = to.twentyFourSeven;
        this.form.enjoyed = to.enjoyed;
        this.form.comment = to.comment;
        this.dirty = false;
      }
    }
  },

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  methods: {

    showDialog() {
      this.visible = true;
      this.dirty = false;
    },

    cancelReview() {
      if (!this.dirty || confirm("Dismiss your changes?")) {
        this.visible = false;
      }
    },

    deleteReview() {
      if (confirm("Mark this as not completed and delete any review data you may have previously submitted?")) {
        this.$emit('oops');
        this.visible = false;
      }
    },

    saveReview() {
      let review: ICompletionReview = {
        artworkRating: this.form.artworkRating,
        difficultyRating: this.form.difficultyRating,
        routeRating: this.form.routeRating,
        passphraseRating: this.form.passphraseRating,
        twentyFourSeven: this.form.twentyFourSeven!,
        enjoyed: this.form.enjoyed!,
        comment: this.form.comment,
        completedByFoot: this.form.transportationUsed == 'foot',
        completedByBike: this.form.transportationUsed == 'bike',
        completedByCar: this.form.transportationUsed == 'car',
        suitableTransportation: {
          foot: this.form.suitedTransportation.foot!,
          bike: this.form.suitedTransportation.bike!,
          car: this.form.suitedTransportation.car!,
          wheelchair: this.form.suitedTransportation.wheelchair!
        }
      }
      this.visible = false;
      this.$emit("update:review", review);
      this.$emit("save", review);
    }
  },

  mounted() {
    this.dirty = false;
  }

});

