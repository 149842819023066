<template>
  <div class="appContent hello">
    <splitpanes class="default-theme">
      <pane style="height: 100%">
        <p-scroll-panel style="height: 100%; width: 100%">
          <p-accordion :activeIndex="2">

            <p-accordion-tab>
              <template #header>
                <span>Missions in view&nbsp;</span>
                <span v-if="missionsInView.length == filteredMissionsInView.length">
                  ({{ missionsInView.length }})
                </span>
                <span v-else>
                  ({{ filteredMissionsInView.length }}
                  /
                  {{ missionsInView.length }})
                </span>
              </template>
              <div class="p-grid">
                <p-input-text
                  class="p-col-12"
                  v-model="titleFilter"
                  placeholder="filter by mission title"
                  />
                <div class="p-col-12">
                  <small>
                    Preprocess: 
                    <label><input type="checkbox" v-model="sortRoman"> Roman numerals</label>
                    &nbsp;
                    <label><input type="checkbox" v-model="sortChinese"> Chinese numerals</label>
                  </small>
                </div>
              </div>
              <table class="missions">
                <tr v-for="m in filteredMissionsInView" :key="m.guid" class="mission">
                  <td class="image"><img :src="resizeMissionImage(m.image)"></td>
                  <td class="title">{{ m.title }}</td>
                  <td class="actions">
                    <a @click="addMission(m)" title="Add mission to banner"><i class="fas fa-plus" /></a>
                    <a @click="titleFilter = m.title" title="Set as title filter"><i class="fas fa-filter" /></a>
                    <a @click="showOnMap(m)" title="Show on map"><i class="fas fa-map" /></a>
                    <!-- <a :href="`https://intel.ingress.com?ll=${m.startPoint.lat},${m.startPoint.lng}`" target="_new" title="Open in Intel"><i class="fas fa-external-link-alt" /></a> -->
                    <a :href="`https://intel.ingress.com/mission/${m.guid}`" target="_new" title="Open in Intel"><i class="fas fa-external-link-alt" /></a>
                    <!-- <a :href="`https://intel.ingress.com?ll=${m.startPoint.lat},${m.startPoint.lng}`" target="_new" title="Open in Intel"><img src="assets/ingress.svg" class="intel"></a> -->
                  </td>
                </tr>
              </table>
            </p-accordion-tab>

            <p-accordion-tab header="Information">
              <EditBannerDetails
                v-if="banner"
                :banner="banner"
                />
            </p-accordion-tab>

            <p-accordion-tab>
              <template #header>
                <span v-if="banner">
                  Missions ({{ banner.missions.length}})
                </span>
              </template>
              <MissionList
                v-if="banner"
                v-model:missions="banner.missions"
                :selectable="true"
                :allow-reorder="true"
                :sortable="true"
                :show-rating="false"
                :show-type="false"
                :show-time="false"
                :show-waypoints="false"
                :show-details="true"
                />
            </p-accordion-tab>

          </p-accordion>

        </p-scroll-panel>

      </pane>
      <pane>
        <splitpanes horizontal class="default-theme">
          <pane class="map">
            <ingress-map
              ref="map"
              :showMissions="true"
              @missions="setMissionsInView"
              @moveend="mapMoved"
              @zoomend="mapMoved"
              @ready="mapReady"
              v-model:center="center"
              v-model:zoom="zoom"
              ></ingress-map>
          </pane>
          <pane class="mosaic">
            <small>Preview</small>
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { IState } from '../state';

import EditBannerDetails from './EditBannerDetails.vue';
import MissionList from '@/components/MissionList.vue';
import IngressMap from '@/components/IngressMap.vue';

import { sortMissionsAlpha, sortMissionsSequence } from '../lib/sorting';
export default defineComponent({

  name: 'EditBanner',

  components: {
    MissionList,
    EditBannerDetails,
    IngressMap
  },

  inject: [ 'state', BACKEND_KEY ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
      //return inject(BACKEND_KEY);
    },
    filteredMissionsInView(): Array<IMissionInfoDetailed> {
      try {
        let titleRegex = new RegExp(this.titleFilter, "i");
        let filtered = this.missionsInView.filter(m => titleRegex.test(m.title));
        console.dir(filtered);
        return filtered;
      } catch {
        return this.missionsInView;
      }
    }
  },

  data() {
    return {
      sortChinese: false,
      sortRoman: false,
      titleFilter: '',
      banner: null! as IBanner,
      missionsInView: [] as Array<IMissionInfoDetailed>,
      zoom: 15,
      center: new L.LatLng(59.1, 10.9) as L.LatLngExpression
    }
  },

  async created() {
    let bannerId = "lucky-187f44a5";
    this.banner = await this.backendService.getBanner(bannerId);
    if (this.$route.params.zoom) this.zoom = parseFloat(this.$route.params.zoom.toString());
    if (this.$route.params.lat && this.$route.params.lng) {
      this.center = [
        parseFloat(this.$route.params.lat.toString()),
        parseFloat(this.$route.params.lng.toString())
      ];
    }
  },

  methods: {

    addMission(mission: IMissionInfoDetailed) {
      // TODO
    },

    resizeMissionImage(url: string) {
      return url;
    },

    setMissionsInView(missions: Array<IMissionInfoDetailed>) {
      this.missionsInView = sortMissionsAlpha(missions, {
        naturalize: true,
        fixChinese: this.sortChinese,
        fixRoman: this.sortRoman
      })
    },

    mapMoved(ev: any) {
      let map = ev.target as L.Map;
      let c = map.getCenter();
      let z = map.getZoom();
      history.replaceState(null, '', `/add_v2/${c.lat}/${c.lng}/${z}`);
    },

    showOnMap(mission: IMissionInfoDetailed) {
      let map = (<any>this).$refs.map.getMap() as L.Map;
      map.setView(new L.LatLng(mission.startPoint!.lat, mission.startPoint!.lng), Math.max(this.zoom, 15));
      // this.zoom = Math.max(this.zoom, 15);
      // this.center = new L.LatLng(mission.startPoint!.lat, mission.startPoint!.lng);
    }
  }

});
</script>

<style lang="scss" scoped>

.missions {
  border-collapse: collapse;
  border: 0;
  width: 100%;
  .mission {
    td {
      padding: 0.25em;
    }
    td.image {
      width: 32px;
      img {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        border: 2px solid goldenrod;
      }
    }
    td.title {
      vertical-align: top;
      
    }
    td.actions {

      font-size: 0.8em;

      width: 4 * 2em;

      > * {
        padding-right: 0.5em;
        &:last-child {
          padding-right: 0;
        }
      }

      .intel {
        position: relative;
        top: 0.2em;
        width: 1.1em;
        height: 1.1em;
      }
    }
  }
}



.hello {
  //background-color: #333;
  padding: 0;
  width: 100%;
  height: 100%;
}

.outer {
  width: 100%;
  height: 100%;
}

.mosaic, .search, .missions {
  padding: 0.5em;
}

</style>

<style lang="scss">

.splitpanes__pane {
  background-color: inherit !important; 
  border: 0 !important;
}

.splitpanes__splitter {
  background-color: var(--surface-200) !important; 
  border: 0 !important;
}

.p-accordion-content {
  background: inherit !important;
}

.p-accordion-tab {
  //height: 100%;
}

</style>