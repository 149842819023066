

import { MissionInfo, IMissionWaypointEx, ILatLng } from '../../../shared/src/types';

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';

export default defineComponent({
  name: 'MapRef',
  props: {
    title: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: '_new'
    },
    latLng: {
      type: Object as PropType<ILatLng>,
      default: null,
      required: false
    },
    waypoint: {
      type: Object as PropType<IMissionWaypointEx>,
      default: null,
      required: false
    },
    portal: {
      type: Object as PropType<IMissionWaypointEx>,
      default: null,
      required: false
    },
    mission: {
      type: Object as PropType<MissionInfo>,
      default: null,
      required: false
    },
    missionId: {
      type: String,
      default: null,
      reqired: false
    },
    zoom: {
      type: Number,
      default: 17,
      required: false
    }
  },
  data() {
    return {
      icons
    }
  },
  computed: {
    coords(): ILatLng {
      if (this.latLng) {
        let latLng: any = this.latLng;
        if (latLng instanceof Array)
          latLng = { lat: latLng[0], lng: latLng[1] };
        return latLng;
      } else if (this.portal && this.portal.point) {
        return this.portal.point;
      } else if (this.waypoint && this.waypoint.point) {
        return this.waypoint.point;
      } else {
        return { lat: 0, lng: 0 };
      }
    },
    googleLink(): string {
      let ll = this.coords;
      return `https://www.google.com/maps/search/${ll.lat},${ll.lng}/@${ll.lat},${ll.lng},${this.zoom}z`;
    },
    intelLink(): string {
      if (this.latLng) {
        let latLng: any = this.latLng;
        if (latLng instanceof Array)
          latLng = { lat: latLng[0], lng: latLng[1] };
        return `https://intel.ingress.com?ll=${latLng.lat},${latLng.lng}&z=${this.zoom}`;
      } else if (this.mission) {
        return `https://intel.ingress.com/mission/${this.mission.guid}`;
      } else if (this.missionId) {
        return `https://intel.ingress.com/mission/${this.missionId}`;
      } else if (this.portal && this.portal.point) {
        return `https://intel.ingress.com?ll=${this.portal.point.lat},${this.portal.point.lng}&pll=${this.portal.point.lat},${this.portal.point.lng}&z=${this.zoom}`;
      } else if (this.waypoint && this.waypoint.point) {
        return `https://intel.ingress.com?ll=${this.waypoint.point.lat},${this.waypoint.point.lng}&pll=${this.waypoint.point.lat},${this.waypoint.point.lng}&z=${this.zoom}`;
      } else {
        return "https://intel.ingress.com/";
      }
    }
  },
  methods: {
    clicked(event: any) {
      (<any>this.$refs.op).toggle(event);
    }
  }
});

