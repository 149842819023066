

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SeparatedList',
  props: {
    separator: {
      type: String,
      default: ' > '
    },
    items: {
      type: Array,
      required: true
    }
  }
});

