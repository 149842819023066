

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  props: {
  },

  data() {
    return {
      frozen: { 
        tiles: null! as L.TileLayer
      }
    }
  },

  mounted() {
    let map = (<any>this).map() as L.Map;
    let tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 19,
    });
    tiles.addTo(map);
    this.frozen = Object.freeze({ tiles });
  },

  beforeUnmount() {
    console.log("tiles unloaded");
    let map = (<any>this).map() as L.Map;
    map.removeLayer(this.frozen.tiles);
  }

})

