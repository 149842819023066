

import BannerGridEntry from './BannerGridEntry.vue';

import { IBanner } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { defineComponent, PropType } from 'vue';
import { formatTime, formatDistance, formatAddress, formatRating, formatEfficiency  } from '../lib/utils';
import { icons } from '../lib/icons';

export default defineComponent({

  name: 'BannerGrid',

  inject: [ BACKEND_KEY ],

  components: {
    BannerGridEntry
  },  

  props: {
    banners: {
      type: Object as PropType<Array<IBanner>>,
      required: true
    },
    suppressDefaultAction: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      icons
    }
  },

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  methods: {
    formatTime,
    formatDistance,
    formatEfficiency,
    formatAddress,
    formatRating,

    go(banner: IBanner) {

      this.$emit("selected", banner);

      if (!this.suppressDefaultAction) {
        this.$router.push({
          name: 'ViewBanner',
          params: <any>{
            bannerId: banner.slug || banner.guid
          }
        });
      }

    }
  }

})

