<template>

  <div style="width: 100%; height: 100%">

    <!--
    <el-dialog
      title="Incoming glyph.."
      v-model="loading"
      width="30%">
      <span>Loading..</span>
    </el-dialog>
    -->

    <TwoColumnLayout v-if="step == 0" :left="1" :right="0">

      <template #top></template>

      <template #left>

        <div class="instructions">

          <p>Adding a banner requires that the data for each banner has been indexed first, a process that can be a bit cumbersome at times.. Either you or someone else has to use one of the following methods - both are not mobile friendly, and is probably easiest to do on a desktop:</p>

          <h3>Method 1 - via Intel / IITC</h3>

          <p>This is similar to the old method used with IngressMosaik:</p>

          <ol>
            <li>
              Install and configure IITC - <a href="https://iitc.app">IITC-CE</a><br>
              (this also includes installing the TamperMonkey browser extension)
            </li>
            <li>
              Install or enable the <b>missions plugin</b> in IITC
            </li>
            <li>Install a special data-gathering plugin for IITC:
              <ol>
                <li><a href="/assets/iitc-plugin-specops-quest-helper.user.js">iitc-plugin-specops-quest-helper.user.js</a></li>
                <li><a href="https://softspot.nl/ingress/">DanielOnDiordna's Missions add-on plugin</a> (makes finding mission start portals easier)<br>
                  <a href="https://softspot.nl/ingress/plugins/iitc-plugin-missions-addon.user.js">iitc-plugin-missions-addon.user.js</a>
                </li>
              </ol>
            </li>
            <li>Browse the intel map and locate all <i>mission start portals</i> (this results in a mission summary)</li>
            <li>View the <i>details page</i> for each of the involved missions (this results in details mission data)</li>
          </ol>

          <h3>Method 2 - via Missions Creator</h3>
          <p>(not yet available)</p>
          <!--
          <p>This is limited to mission you have created yourself!</p>
          <ol>
            <li>Install the TamperMonkey browser extension</li>
            <li>Install a TamperMonkey script for extracting mission data from the Missions Creator - <a href="/assets/missionscreator-extract-data.user.js">missionscreator-extract-data.user.js</a></li>
            <li>Go to the <a href="https://missions.ingress.com">Missions Creator</a></li>
            <li>Use the extended interface offered by the TamperMonkey script to export your missions</li>
          </ol>
          -->

          <p>Ready? Let's go!</p>

        </div>

      </template>

      <template #right></template>
      
      <template #bottom>
        <el-row style="padding-top: 1em">
          <el-col :span="24" style="text-align: right">
            <el-button type="primary" @click="nextStep">Next</el-button>
          </el-col>
        </el-row>
      </template>
      
    </TwoColumnLayout>

    <div v-if="step == 1" style="width: 100%; height: 100%; overflow: hidden">
      <div style="display: flex; flex-direction: column; height: 100%; overflow: hidden">
        <div style="flex: 0; _background-color: orange">
          <div>Search for missions to add - ordering is done in the next step. Use * as a wildcard anywhere, e.g. <span class="exampleQuery">Oslo*</span> or <span class="exampleQuery">*Gothenburg*</span></div>
          <el-input placeholder="Search by title, use * as wildcard, e.g. 'big cat*' or '*horse*'" v-model="filterTitle" @keydown.enter="search"></el-input>
          <el-checkbox v-model="filterIndexed">show only indexed missions</el-checkbox>
          <!-- <div class="searching" v-if="loading">Searching...</div> -->
        </div>
        <div style="flex: 1; _background-color: gold; height: 100%; display: flex; flex-direction: row; overflow: hidden">
          <div style="flex: 20; height: 100%; _background-color: green; overflow: hidden; display: flex; flex-direction: column">

            <MissionList
              style="flex: 1; overflow-y: auto; height: 100%"
              :missions="filteredMissions"
              v-model:selection="leftSelection"
              :show-details="true"
              :selectable="true"
              :sortable="true"
              :show-type="false"
              :show-rating="false"
              :show-time="false"
              :show-waypoints="false"
              @row-dblclick="leftColumnDblClicked"
              />

            <div  
              v-if="searchResults.numPages > 1"
              style="text-align: center; flex: 0"
              >
              <el-pagination
                ref="pagination"
                background
                layout="prev, pager, next"
                :page-count="searchResults.numPages"
                :current-page="searchResults.page"
                @current-change="searchNext">
              </el-pagination>
              #matches = {{searchResults.count}}
            </div>

          </div>
          <div style="width: 64px; height: 100%; _background-color: cyan; display: flex; flex-direction: column; justify-content: center">
            <div style="text-align: center"><el-button :disabled="leftSelection.length == 0" type="primary" icon="el-icon-caret-right" circle title="Add" @click="moveRight"></el-button><br><br></div>
            <div style="text-align: center"><el-button :disabled="rightSelection.length == 0" type="primary" icon="el-icon-caret-left" circle title="Remove" @click="moveLeft"></el-button></div>
          </div>
          <div style="flex: 20; height: 100%; width: 100%; _background-color: green; overflow: hidden; display: flex; flex-direction: column">
            <MissionList
              style="flex: 1; overflow-y: auto; height: 100%; width: 100%"
              v-model:missions="bannerData.missions"
              v-model:selection="rightSelection"
              :selectable="true"
              :allow-reorder="false"
              :sortable="true"
              :show-rating="false"
              :show-type="false"
              :show-time="false"
              :show-waypoints="false"
              :show-details="true"
              @row-dblclick="rightColumnDblClicked"
              />
            <div style="flex: 0; text-align: center">
              Number of missions: {{ bannerData.missions.length }}
            </div>
          </div>
        </div>
        <div style="flex: 0; padding-top: 1em">
          <el-row>
            <el-col :span="12">
              <el-button @click="prevStep">Back</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button :disabled="bannerData.missions.length == 0" type="primary" @click="goStep2()">Next</el-button>
            </el-col>
          </el-row>
        </div>
      </div>  
    </div>
        
    <TwoColumnLayout v-else-if="step == 2" :left="1" :right="1">

      <template #top></template>

      <template #left>
        <MissionList
          style="width: 100%"
          v-model:missions="bannerData.missions"
          v-model:selection="rightSelection"
          :selectable="false"
          :allow-reorder="true"
          :sortable="false"
          :show-rating="false"
          :show-num-completed="false"
          :show-time="false"
          :show-waypoints="false"
          :show-details="true"
          />
      </template>

      <template #right>
      
        <div>
          Reorder:<br>
          <el-button icon="el-icon-magic-stick" @click="sortSmart">Smart sort</el-button>
          <el-button icon="el-icon-sort" @click="sortAlpha">Alphabetical sort</el-button>
        </div>
        <br>

        Title: 
        <span class="selectHelper" v-if="!selectTitleFromMission" @click="startSelectTitleFromMission()">select from missions..</span>
        <span class="selectHelper" v-if="selectTitleFromMission" @click="selectTitleFromMission = false">cancel/done</span>
        <br>
        <el-input v-if="!selectTitleFromMission" v-model="bannerData.title" placeholder="Enter a title for the banner"/>
        <el-select ref="selectMissionTitle" automatic-dropdown v-if="selectTitleFromMission" v-model="bannerData.title" filterable placeholder="Select" style="width: 100%" @change="selectTitleFromMission = false">
          <el-option
            v-for="item in bannerData.missions"
            :key="item.guid"
            :label="item.title"
            :value="item.title">
          </el-option>
        </el-select>
        <div class="tip">The title should not include any #of# or #/# sequence information!</div>
        <br>
        Description:
        <span class="selectHelper" v-if="!selectDescriptionFromMission" @click="startSelectDescriptionFromMission()">select from missions..</span>
        <span class="selectHelper" v-if="selectDescriptionFromMission" @click="selectDescriptionFromMission = false">cancel/done</span>          
        <br>
        <el-input v-if="!selectDescriptionFromMission" type="textarea" :rows="4" autosize v-model="bannerData.description" placeholder="Enter a description of the banner" />
        <el-select ref="selectMissionDescription" automatic-dropdown v-if="selectDescriptionFromMission" v-model="bannerData.description" filterable placeholder="Select" style="width: 100%" @input="selectDescriptionFromMission = false">
          <el-option
            v-for="item in bannerData.missions"
            :key="item.guid"
            :label="item.description"
            :value="item.description">
          </el-option>
        </el-select>
        <br>
        <br>
        Row size:<br>
        <el-select v-model="bannerData.rowSize" placeholder="Select" style="width: 100%">
          <el-option
            v-for="item in rowSizes"
            :key="item.value"
            :label="item.title"
            :value="item.value">
          </el-option>
        </el-select>
        <br>
        <br>
        Preview:<br>
        <BannerMosaicPreview
          :missions="bannerData.missions"
          :tile-size="64"
          :circle="true"
          :padding="8"
          :outline="2"
          :row-size="bannerData.rowSize"
          ></BannerMosaicPreview>      
      </template>

      <template #bottom>
        <el-row style="padding-top: 1em">
          <el-col :span="12">
            <el-button @click="prevStep">Back</el-button>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button type="primary" @click="nextStep">Next</el-button>
          </el-col>
        </el-row>
      </template>
      
    </TwoColumnLayout>
    
    <TwoColumnLayout v-else-if="step == 3" :left="2" :right="5">
      <template #left>
        <MissionList
          style="width: 100%"
          v-model:missions="bannerData.missions"
          v-model:selection="rightSelection"
          :expanded="false"
          :selectable="false"
          :allow-reorder="false"
          :sortable="false"
          :show-rating="false"
          :show-time="false"
          :show-waypoints="false"
          :show-details="true"
          @cell-enter="mapFocusMission = $event"
          @cell-leave="mapFocusMission = null"
          />
      </template>
      <template #right>
        <BannerMap :missions="bannerData.missions" :focus-mission="mapFocusMission" style="widht: 100%; height: 100%" />
      </template>

      <template #bottom>
        <el-row style="padding-top: 1em">
          <el-col :span="12">
            <el-button @click="prevStep">Back</el-button>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button type="primary" @click="save">Save</el-button>
          </el-col>
        </el-row>
      </template>

    </TwoColumnLayout>

  </div>

</template>

<script lang="ts">

interface ISequence {
  seq: number;
  total?: number;
};

function parseSequence(title: string): ISequence | undefined {
  let m = title.replace(/[#]/g, ' ').match(/(\d+)\s*(\/|-|of)\s*(\d+)/);
  if (m && m[2].trim().length == 0) m = null;
  if (!m) m = title.replace(/[#]/g, ' ').match(/(\d+)\s*([^\d\s]+?)\s*(\d+)/);
  if (m) {
    return { seq: parseInt(m[1]), total: parseInt(m[3]) };
  } else {
    m = title.match(/#(\d+)/);
    if (m == null) m = title.match(/(\d+)/);
    if (m) {
      return { seq: parseInt(m[1]) };
    // let nums = title.match(/(?=[^\d]|\b)(\d+)(?<=\b|[^\d])/g);
    // if (nums) {
    //   //nums = nums.map(x => parseInt(x.replace(/[^0-9]/g, '')));
    //   console.dir(nums);
    // }
    }
  }
}

import { defineComponent, inject, PropType } from 'vue';

import {
  IMissionInfoSummary,
  IMissionInfoDetailed,
  IBanner,
  ISearchResults,
  MissionInfo
} from '../../../shared/src/types';

import { BACKEND_KEY, BackendService } from '@/services/backend-service';

export default defineComponent({

  name: 'BannerEditor',

  components: {
  },

  props: {

    step: {
      type: Number,
      default: 0,
    },

    add: {
      type: Boolean,
      default: false
    },

    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }

  },

  data() {
    return {
      bannerData: {} as IBanner,
      mapFocusMission: null as IMissionInfoDetailed | null,
      loading: false,
      //rowSize: 6 as number,
      rowSizes: [
        { value: 6, label: '6 - Regular' },
        { value: 5, label: '5' },
        { value: 4, label: '4' },
        { value: 3, label: '3 - Half' },
        { value: 2, label: '2' },
        { value: 1, label: '1' },
      ],
      selectTitleFromMission: false,
      selectDescriptionFromMission: false,
      filterTitle: '',
      filterIndexed: true,
      searchResults: { results: [], count: 0, page: 0, pageSize: 1, numPages: 0 } as ISearchResults<MissionInfo>,
      //title: '',
      //description: '',
      //missions: [] as IMissionDetailsEx[],
      //addedMissions: [] as IMissionInfoDetailed[],
      leftSelection: [] as IMissionInfoDetailed[],
      rightSelection: [] as IMissionInfoDetailed[],
      previewCircle: false
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(v) {
        if (v) {
          this.bannerData = v; // JSON.parse(JSON.stringify(v)) ;
          // if (this.bannerData.missions!.length == 0) this.step = 0;
          // else this.step = 2;
          //this.title = v.title;
          //this.description = v.description;
          //this.addedMissions = v.missions; // || []
        }
      }
    }
  },

  inject: [ BACKEND_KEY ],

  computed: {

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
      //return inject(BACKEND_KEY);
    },

    filteredMissions(): Array<MissionInfo> {
      let a = this.addedMissionsMap;
      //let f = new RegExp(this.filterTitle.trim(), "i");
      return this.searchResults.results.filter(m => {
        return ((this.filterIndexed && (<IMissionInfoDetailed>m).detailsModified) || !this.filterIndexed)
          //&& f.test(m.title)
          && !a[m.guid];
      })
    },

    addedMissionsMap(): {[guid: string]: MissionInfo} {
      let map: {[guid: string]: MissionInfo} = {};
      this.bannerData.missions!.forEach(m => map[m.guid] = m);
      return map;
    }

  },

  mounted() {
  },

  methods: {

    async save() {

      // let b: IBanner = {
      //   ...this.banner,
      //   rowSize: this.rowSize,
      //   title: this.title,
      //   description: this.description,
      //   missionGuids: this.bannerData.missions.map(m => m.guid),
      //   missions: this.addedMissions,
      // };

      let b = this.bannerData;

      b.missionGuids = b.missions!.map(m => m.guid);

      this.$emit('update:banner', b);

      b = { ... b};
      delete b.missions;
      //delete b.comments;

      this.$emit('save', b);
    },

    sortAlpha() {
      let list = [ ...this.bannerData.missions! ].sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));
      if (confirm("Does this look correct?\n\n" + list.map(m => m.title).join("\n"))) {
        this.bannerData.missions = list;
      }
    },

    sortSmart() {
      let sequences = this.bannerData.missions!.map(m => parseSequence(m.title));
      let previewOrder = this.bannerData.missions!.map((m, i) => ({ m, i, seq: sequences[i]?.seq }));
      previewOrder.sort((a, b) => a.seq! - b.seq!);
      
      if (confirm("Does this look correct?\n\n" + previewOrder.map(m => m.m.title).join("\n"))) {
        this.bannerData.missions = previewOrder.map(x => x.m);
      }
    },

    nextStep() {
      //alert("next step")
      this.$emit('update:step', this.step + 1);
    },

    prevStep() {
      //alert("prev step")
      this.$emit('update:step', this.step - 1);
    },
    
    async goStep2() {
      this.loading = true;
      try {
        let missions = await this.backendService?.getMissionsByGuids(this.bannerData.missions!.map(m => m.guid));
        console.dir(missions);
        missions?.forEach(m2 => {
          let m = this.bannerData.missions!.find(x => x.guid == m2.guid);
          //console.log("upgrade: %o -> %o", m, m2);
          if (m != null) {
            Object.assign(m, m2); // will this work with reactivity?
          } else {
            console.error("could not find mission in addedMission:", m2);
          }
        })
      } finally {
        this.loading = false;
      }

      // reload missions with all details

      if (this.bannerData.description == null || this.bannerData.description == "") this.bannerData.description = this.bannerData.missions?.[0].description as string;
      if (this.bannerData.title == null || this.bannerData.title == "") this.bannerData.title = this.bannerData.missions?.[0].title as string;
      this.nextStep();
    },

    startSelectTitleFromMission() {
      this.selectTitleFromMission = true;
      this.$nextTick(() => {
        let el = this.$refs.selectMissionTitle;
        (<any>el)?.focus();
      })
    },

    startSelectDescriptionFromMission() {
      this.selectDescriptionFromMission = true;
      this.$nextTick(() => {
        let el = this.$refs.selectMissionDescription;
        (<any>el)?.focus();
      })
    },

    async search() {
      this.loading = true;
      try {
        this.searchResults = await this.backendService.searchMissions({ title: this.filterTitle });
      } finally {
        this.loading = false;
      }
    },

    async searchNext(page: number) {
      try {      
        this.loading = true;
        this.searchResults = await this.backendService.searchMissions({ title: this.filterTitle, page: page });
      } finally {
        this.loading = false;
      }
    },

    formatTime(t: number) {
      t /= 1000;
      let d = Math.floor(t / 86400);
      t %= 86400;
      let h = Math.floor(t / 3600);
      t %= 3600;
      let m = Math.floor(t / 60);
      t %= 60;
      let s = Math.round(t);
      return [
        d ? d+'d' : null,
        h ? h+'h' : null,
        m ? m+'m' : null,
        s ? s+'s' : null
      ].filter(s => s).join(" ")
    },


  }
  
});

</script>

<style lang="scss" scoped>

.selectHelper {
  color: #999;
  border-bottom: 1px dotted #999;
  cursor: pointer;
  font-style: italic;
}

.tip {
  font-size: 0.8em;
  padding: 0.25em;
  color: #999;
}

.search {
  color: #999;
  font-family: Italic;  
}

.exampleQuery {
  font-family: Courier New;
}

.instructions {
  li {
    line-height: 2em;
  }
}

</style>
