<template>
  <slot v-if="isReady"></slot>
</template>

<script lang="ts">

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  provide() {
    return {
      layer: () => {
        return this.frozen.layerGroup;
      }
    }
  },

  props: {
  },

  data() {
    return {
      isReady: false,
      frozen: { 
        layerGroup: null! as L.LayerGroup<any>
      }
    }
  },

  mounted() {
    console.log("layergroup loaded");
    let map = (<any>this).map() as L.Map;
    let layerGroup = L.layerGroup([]);
    layerGroup.addTo(map);
    this.frozen = Object.freeze({ layerGroup });
    this.isReady = true;
  },

  beforeUnmount() {
    console.log("layergroup unloaded");
    let map = (<any>this).map() as L.Map;
    map.removeLayer(this.frozen.layerGroup);
  }

})

</script>

<style lang="scss" scoped>
</style>
