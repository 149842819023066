

import { IUser } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { defineComponent } from 'vue';
import useClipboard from 'vue-clipboard3';

export default defineComponent({

  name: 'EditProfile',

  inject: [ 'state', BACKEND_KEY ],

  data() {
    return {
      labelClass: "p-lg-2 p-col-12",
      valueClass: "p-lg-10 p-col-12",
      agentDetailsLocked: true,
      profile: null as IUser | null,
      teams: [
        { value: 'E', name: 'Enlightened' },
        { value: 'R', name: 'Resistance' }
      ]
    }
  },

  setup() {
    const { toClipboard } = useClipboard();
    return {
      copyToClipboard(text: string) {
        toClipboard(text);
      }
    }
  },

  computed: {

    verificationUsers() {
      return [ ].map(m => '@'+m).join(' ');
    },

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },

    haveAgentDetails(): boolean {
      let state: any = (<any>this).state;
      return state.user.agentName != null && state.user.agentName != "";
    }

  },

  mounted() {
    let state = (<any>this)["state"];
    this.load(state.user);
  },

  watch: {
    'state.user'(to) {
      console.log("load user via state watcher");
      this.load(to);
    }
  },

  methods: {

    load(user: IUser) {
      this.agentDetailsLocked = true;      
      this.profile = user == null ? null : { ...user }; // copy
    },

    unlockAgentDetails() {
      if (confirm("You will have to verify your agent details again if you edit this - are you sure you want to continue?")) {
        this.agentDetailsLocked = false;
      }
    },

    async save() {
      try {
        let state = (<any>this)["state"];
        let user2 = await this.backendService.updateProfile(<IUser>{
          agentName: this.profile!.agentName,
          agentFaction: this.profile!.agentFaction,
        });
        state.user = user2;
        this.load(state.user);
      } catch (err) {
        alert("ERROR: " + err.message);
      }
    },

    async generateApiKey() {
      try {
        let state = (<any>this)["state"];
        this.profile!.apiKey = await this.backendService.requestNewApiKey();
      } catch (err) {
        alert("ERROR: " + err.message);
      }
    }

  }

});
