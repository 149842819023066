<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { IBanner } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '@/services/backend-service';
import { icons } from '../lib/icons';
import { isAdminOrOwner, isAdmin } from '../state';

export default defineComponent({
  
  name: 'BannerToolsPopup',

  inject: [ 'state', BACKEND_KEY ],

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    },
    tools: {
      type: Boolean,
      default: true
    },
    manage: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    }
  },

  data() {
    return {
      saving: false,
      icons
    }
  },

  methods: {

    isAdmin,

    isAdminOrOwner,

    toggle(ev: Event) {
      let op: any = this.$refs.op;
      op.toggle(ev);
    },
    
    async deleteBanner(banner: IBanner) {
      if (confirm("Are you sure? There is no undo!"))
        await this.backendService.deleteBanner(banner);
    },

    editBanner(banner: IBanner) {
      (<any>this.$refs.op).hide(); // must hide it, otherwise it throws an error during route leave  - https://github.com/primefaces/primevue/issues/1161
      this.$router.push("/edit/" + banner.guid);
      // this.$router.push({
      //   name: 'Edit',
      //   params: <any>{
      //     bannerId: banner.guid
      //   }
      // })
    },

    async toggleFavorite() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerFavorite(this.banner, !this.banner.userData!.favoritedBy);
        this.banner.userData!.favoritedBy = !this.banner.userData!.favoritedBy;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async toggleToDo() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerToDo(this.banner, !this.banner.userData!.toDoBy);
        this.banner.userData!.toDoBy = !this.banner.userData!.toDoBy;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    },

    async toggleCompleted() {
      try {
        this.saving = true;
        await this.backendService.toggleBannerCompleted(this.banner, !this.banner.userData!.completedBy);
        this.banner.userData!.completedBy = !this.banner.userData!.completedBy;
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.saving = false;
      }
    }

  }

})

</script>

<template>

  <p-overlay-panel ref="op"  :showCloseIcon="true" :dismissable="true">
    <div v-if="banner.userData" class="userPrefsPopup">
      <div v-if="tools" @click="toggleFavorite" :class="{ active: banner.userData.favoritedBy }"><i :class="banner.userData.favoritedBy ? icons.bookmarkTrue : icons.bookmarkFalse" /> bookmark</div>
      <div v-if="tools" @click="toggleToDo" :class="{ active: banner.userData.toDoBy }"><i :class="banner.userData.toDoBy ? icons.todoTrue : icons.todoFalse" /> to-do</div>
      <div v-if="tools" @click="toggleCompleted" :class="{ active: banner.userData.completedBy }"><i :class="banner.userData.completedBy ? icons.completedTrue : icons.completedFalse" /> completed</div>
      <p-divider v-if="tools && manage && isAdminOrOwner(banner)" />
      <div v-if="manage && isAdminOrOwner(banner)" @click="editBanner(banner)"><i class="fas fa-edit" /> edit</div>
      <div v-if="manage && isAdmin(banner)" @click="deleteBanner(banner)"><i class="fas fa-trash" /> delete</div>
    </div>
  </p-overlay-panel>

  <p-dialog :modal="true" :closeOnEscape="false" :closable="false" v-model:visible="saving">
    <template #header>
      Saving
    </template>
    Saving, please wait..
  </p-dialog>

</template>

<style lang="scss" scoped>

.userPrefsPopup {
  font-size: 0.8em;
  color: var(--text-color-secondary);
  display: flex;
  flex-direction: column;
  > div {
    > * { 
      &:first-child {
        width: 1em;
      }
    }
    &:hover {
      cursor: pointer;
      color: var(--text-color);
    }
    &.active {
      color: var(--primary-color);
    }
  }
}

</style>