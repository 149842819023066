<template>

  <TwoColumnLayout :left="1" :right="1">

    <template #top>

      <p-dialog :modal="true" :closeOnEscape="false" :closable="false" v-model:visible="loading">
        <template #header>
          Loading
        </template>
        Refreshing mission data, please wait..
      </p-dialog>

    </template>

    <template #left>
    
      <MissionList
        style="width: 100%"
        v-model:missions="bannerData.missions"
        :selectable="false"
        :allow-reorder="true"
        :sortable="false"
        :show-rating="false"
        :show-num-completed="false"
        :show-time="false"
        :show-waypoints="false"
        :show-details="true"
        />

    </template>

    <template #right>

      <div class="appContent">
    
        <div>
          Reorder:<br>
          <p-button icon="fas fa-magic" @click="sortSmart" label="Sequence sort" title="Attempt to detect sequence information, e.g. # of #, #/#" />
          &nbsp;
          <p-button icon="fas fa-sort-numeric-down" @click="sortAlpha(true)" label="Natural sort" title="Replace number sequences so that they are easier to sort" />
          &nbsp;
          <p-button icon="fas fa-sort-alpha-down" @click="sortAlpha(false)" label="Alphabetical sort" title="Regular alphabetical sorting" />
          <br>
          <input type="checkbox" id="fixRoman" v-model="fixRoman"> <label for="fixRoman"><small>preprocess Roman numerals</small></label>
          <input type="checkbox" id="fixChinese" v-model="fixChinese"> <label for="fixChinese"><small>preprocess Chinese numerals</small></label>
        </div>

        <div class="p-grid space-above">
          <div class="p-col-12">
            Title: 
            <span class="selectHelper" v-if="!selectTitleFromMission" @click="selectTitleFromMission = true">select from missions..</span>
            <span class="selectHelper" v-if="selectTitleFromMission" @click="selectTitleFromMission = false">cancel/done</span>          
          </div>
        </div>

        <div class="p-grid">

          <p-input-text
            class="p-col-12"
            v-if="!selectTitleFromMission"
            v-model="bannerData.title"
            placeholder="Enter a description of the banner"
            />

          <p-dropdown
            placeholder="Select"
            editable
            class="p-col-12"
            ref="selectMissionDescription"
            v-if="selectTitleFromMission"
            v-model="bannerData.title"
            :options="bannerData.missions"
            optionLabel="title"
            optionValue="title"
            @change="selectTitleFromMission = false"
            />        

          </div>

        <div class="tip">The title should not include any #of# or #/# sequence information!</div>

        <div class="p-grid space-above">
          <div class="p-col-12">
            Description:
            <span class="selectHelper" v-if="!selectDescriptionFromMission" @click="selectDescriptionFromMission = true">select from missions..</span>
            <span class="selectHelper" v-if="selectDescriptionFromMission" @click="selectDescriptionFromMission = false">cancel/done</span>          
          </div>
        </div>

        <div class="p-grid">

          <p-text-area
            class="p-col-12"
            v-if="!selectDescriptionFromMission"
            type="textarea"
            :autoResize="true"
            :rows="1"
            v-model="bannerData.description"
            placeholder="Enter a description of the banner"
            />

          <p-dropdown
            placeholder="Select"
            class="p-col-12"
            ref="selectMissionDescription"
            v-if="selectDescriptionFromMission"
            v-model="bannerData.description"
            :options="bannerData.missions"
            optionLabel="description"
            optionValue="description"
            @change="selectDescriptionFromMission = false"
            />
        </div>

        <div class="p-grid space-above">
          <div class="p-col-12">Row size:</div>
        </div>

        <div class="p-grid">
          <p-dropdown
            class="p-col-12"
            v-model="bannerData.rowSize"
            :options="rowSizes"
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            />
        </div>

        <div class="p-grid space-above">
          <div class="p-col-12">Preview:</div>
        </div>

        <draggable
          class="mosaic"
          :class="`rowSize${bannerData.rowSize}`"
          v-model="reversedMissions" 
          group="missions" 
          item-key="guid"
          >
          <template #item="{element, index}">
            <div
              class="mission"
              :class="`rowSize${bannerData.rowSize}`"
              >
              <img :src="stripGoogleImageParams(element.image, '=w64')" />
            </div>
          </template>
        </draggable>

        <!--
        <BannerMosaicPreview
          :missions="bannerData.missions"
          :tile-size="64"
          :circle="true"
          :padding="2"
          :outline="2"
          :row-size="bannerData.rowSize"
          ></BannerMosaicPreview>      
        -->

        </div>
    </template>

    <template #bottom>
      <div style="flex: 0; padding: 1em" class="p-grid">
        <div class="p-col-6 p-text-left">
          <p-button @click="$emit('goto-missions')" label="Back" icon="fas fa-arrow-left" />
        </div>
        <div class="p-col-6 p-text-right">
          <p-button @click="$emit('goto-preview')" label="Next" icon="fas fa-arrow-right" />
        </div>
      </div>
    </template>
    
  </TwoColumnLayout>


</template>

<script lang="ts">

const nomar = require('nomar');
const sprintf = require('sprintf-js').sprintf;
const chineseNumbersConverter = require("chinese-numbers-converter");

import { BACKEND_KEY, BackendService } from '../services/backend-service';
import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionInfoDetailed } from '../../../shared/src/types';
import { parseSequence } from '../lib/parseSequence';

import { stripGoogleImageParams } from '../../../shared/src/googleImageParams';

export default defineComponent({

  inject: [ BACKEND_KEY, 'state' ],

  computed: {
    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },
    reversedMissions: {
      get(): IMissionInfoDetailed[] {
        return [ ...this.bannerData.missions! ].reverse();
      },
      set(values: IMissionInfoDetailed[]) {
        this.bannerData.missions = [ ...values ].reverse();
      }
    }
  },

  props: {
    step: {
      type: Number
    },
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {
      loading: false,
      icons: Object.freeze(icons),
      bannerData: {} as IBanner,
      selectTitleFromMission: false,
      selectDescriptionFromMission: false,
      fixRoman: false,
      fixChinese: false,
      rowSizes: [
        { value: 6, label: '6 - Regular' },
        { value: 5, label: '5' },
        { value: 4, label: '4' },
        { value: 3, label: '3 - Half' },
        { value: 2, label: '2' },
        { value: 1, label: '1' },
      ],
    }
  },

  methods: {

    stripGoogleImageParams,

    async reloadMissions() {
    // reload missions with all details
      this.loading = true;
      try {
        let missions = await this.backendService?.getMissionsByGuids(this.bannerData.missions!.map(m => m.guid));
        //console.dir(missions);
        missions?.forEach(m2 => {
          let m = this.bannerData.missions!.find(x => x.guid == m2.guid);
          //console.log("upgrade: %o -> %o", m, m2);
          if (m != null) {
            Object.assign(m, m2); // will this work with reactivity?
          } else {
            console.error("could not find mission in addedMission:", m2);
          }
        })
      } finally {
        setTimeout(() => {
          //console.log("done loading")
          this.loading = false
        }, 500);
      }

      if (this.bannerData.description == null || this.bannerData.description == "") this.bannerData.description = this.bannerData.missions?.[0].description as string;
      if (this.bannerData.title == null || this.bannerData.title == "") this.bannerData.title = this.bannerData.missions?.[0].title as string;
    },

    sortAlpha(naturalize: boolean = false) {
      let list = [ ...this.bannerData.missions! ].sort((a, b) =>
        this.normalizeTitle(a.title, naturalize)
        .toLocaleLowerCase()
        .localeCompare(this.normalizeTitle(b.title, naturalize).toLocaleLowerCase()));
      if (confirm("Does this look correct?\n\n" + list.map(m => m.title).join("\n"))) {
        this.bannerData.missions = list;
      }
    },

    normalizeTitle(title: string, naturalize: boolean): string {
      // parse roman numerals
      if (this.fixRoman) {
        title = title.replace(/(\bM{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,4})(IL|IX|IV|V?I{0,4})\b)/g, x => {
          let n = x == "" ? "" : nomar(x);
          console.log(`roman ${x} -> arabic ${n}`)
          return n;
        })
      }
      // parse chinese numerals
      if (this.fixChinese) {
        let regex = new RegExp('([' + chineseNumbersConverter.characterList + ']+)', 'g');
        title = title.replace(regex, x => {
          if (x == "") return x;
          try {
            let n = new chineseNumbersConverter(x).toInteger();
            return n;
          } catch (err) {
            console.error("chinese number parsing failed", { x, err });
            return x;
          }
        })
      }
      // preprocess numbers
      if (naturalize) {
        title = title.replace(/(\d+)/g, x => sprintf("%010d", x));
      }
      // condense multiple spaces
      title = title.replace(/\s+/g, ' ');
      return title;
    },

    sortSmart() {
      let sequences = this.bannerData.missions!.map(m => {
        let title = this.normalizeTitle(m.title, false);
        return parseSequence(title);
      });
      let previewOrder = this.bannerData.missions!.map((m, i) => ({ m, i, seq: sequences[i]?.seq }));
      previewOrder.sort((a, b) => a.seq! - b.seq!);
      
      if (confirm("Does this look correct?\n\n" + previewOrder.map(m => m.m.title).join("\n"))) {
        this.bannerData.missions = previewOrder.map(x => x.m);
      }
    },

  },

  mounted() {
    this.reloadMissions();
  }

});

</script>

<style lang="scss" scoped>

.fs {
  box-sizing: border-box;
  border: 1px solid green;
}

.space-above {
  padding-top: 1em;
}

.selectHelper {
  color: var(--text-color-secondary);
  cursor: pointer;
  font-style: italic;
}

$tileSize: 64px;
$tilePadding: 1px;

.mosaic {

  display: grid;
  gap: $tilePadding;
  
  &.rowSize6 { grid-template-columns: repeat(6, 1fr); width: ($tileSize + 2 * $tilePadding) * 6; }
  &.rowSize5 { grid-template-columns: repeat(5, 1fr); width: ($tileSize + 2 * $tilePadding) * 5; }
  &.rowSize4 { grid-template-columns: repeat(4, 1fr); width: ($tileSize + 2 * $tilePadding) * 4; }
  &.rowSize3 { grid-template-columns: repeat(3, 1fr); width: ($tileSize + 2 * $tilePadding) * 3; }
  &.rowSize2 { grid-template-columns: repeat(2, 1fr); width: ($tileSize + 2 * $tilePadding) * 2; }
  &.rowSize1 { grid-template-columns: repeat(1, 1fr); width: ($tileSize + 2 * $tilePadding) * 1; }

  .mission {

    cursor: move;
    width: $tileSize;
    height: $tileSize;
    img {
      width: $tileSize;
      height: $tileSize;
      border-radius: $tileSize / 2;
      border: 2px solid #DBB970;
      margin: 0;
      padding: 0;
    }
  }
}

</style>