<template>
  <div class="appContent">

    <set-title title="Banners" />

    <p-accordion class="x-space-below" :activeIndex="null">
      <p-accordion-tab header="Bannergress notice!">

        <p>Rumors has it that <a style="text-decoration: underline" href="https://bannergress.com">Bannergress</a> (BG)
        is just around the corner, and a full export of all mission and banner data added here will be imported there. This
        luckily means that none of your hard work adding more than 33000 banners here is going to waste! 😻</p>

        <p>However, note that once BG goes live there will not be any automatic import of data, i.e. banners
        added here will not appear on BG automatically. At least not from now.</p>
        
        <p>I expect that at least
        during the initial few days there may be hiccups or things needing to be sorted out with BG,
        so nothing is changing at SpecOps.Quest in case chaos ensues elsewhere! (Also, additional
        rumors has it that BG does NOT have those wonderful 🌈 colored missions paths, so you're still
        welcome to use and look up banners here in safe surroundings!)</p>

        <p><small>- sspp0000xx! 🐈💖🧡💛💚💜💙</small></p>

      </p-accordion-tab>
    </p-accordion>

    <p-dialog :modal="true" :closeOnEscape="false" :closable="false" v-model:visible="loading">
      <template #header>
        Loading
      </template>
      Loading search results, please wait..
    </p-dialog>

    <p-accordion class="x-space-below" v-model:activeIndex="geoAccordionIndex">
      <p-accordion-tab>

        <template #header>
          <p-breadcrumb :home="bcHome" :model="bcItems" class="brot" />
        </template>

        <div v-if="country" class="p-grid geoDrill x-space-above">
          <div class="x-link p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2" v-for="z in geoDrillLast.children" @click="goCountryArray(z.arr)">
            {{ z.name }} ({{ z.count }})
          </div>
        </div>

        <div v-else>
          <div class="x-secondary-text geoSort">
            <small>Sort by &mdash;
              <span class="x-link" :class="{ active: !sortCountriesByName }" @click="sortCountriesByName = false"><i class="fas fa-sort-numeric-down" /> Count</span>
              &middot;
              <span class="x-link" :class="{ active: sortCountriesByName }" @click="sortCountriesByName = true"><i class="fas fa-sort-alpha-up" /> Name</span>
            </small> 
          </div>

          <div class="p-grid geoDrill x-space-above">
            <div
              class="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2 x-link"
              v-for="c in filteredCountries"
              :key="`country-${c.name}`"
              @click="goCountry(c)"
              >
              {{ c.name }} ({{c.count}})
            </div>
            <div
              class="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2 x-link"
              v-if="!showAllCountries && countries.length != filteredCountries.length"
              @click="showAllCountries = true"
              >
              <small class="x-secondary-text">..{{ countries.length - maxCountries }} more!</small>
            </div>
          </div>

        </div>

      </p-accordion-tab>
    </p-accordion>

    <p-accordion v-model:activeIndex="filter">
      <p-accordion-tab>
        <template #header>
          Search / Filter
        </template>
        <div class="p-grid">
          <div class="p-col-12">
            <div class="x-split-pri-left">
              <p-input-text v-model="filterTitle" placeholder="Search for title" style="width: 100%" @keypress.enter="search"></p-input-text>
              <div style="width: 3em">
                <p-button icon="fas fa-search" @click="search"></p-button>
              </div>
            </div>
          </div>
          <div class="p-col-4 filterOption" v-if="state && state.user">
            <p-checkbox id="filterRequireToDo" :binary="true" v-model="filterRequireToDo" />
            <label for="filterRequireToDo"> on my to-do list <!--<i class="icon" :class="icons.todo" />--></label>
          </div>
          <div class="p-col-4 p-text-center filterOption" v-if="state && state.user">
            <p-checkbox id="filterExcludeCompleted" :binary="true" v-model="filterExcludeCompleted" />
            <label for="filterExcludeCompleted"> exclude completed <!--<i class="icon" :class="icons.completed" />--></label>
          </div>
          <div class="p-col-4 p-text-right filterOption" v-if="state && state.user">
            <p-checkbox id="filterRequireFavorited" :binary="true" v-model="filterRequireFavorited" />
            <label for="filterRequireFavorited"> bookmarked <!--<i class="icon" :class="icons.bookmark" />--></label>
          </div>
        </div>

      </p-accordion-tab>
    </p-accordion>

    <div id="pageTop"></div>

    <div class="space-above p-grid">
      <div class="p-col-12">
        <i class="fas fa-sort"></i> Sort: 
        <separated-list :items="sortables" class="sortablesList" style="display: inline-block; margin-left: 1em">
          <template #separator>
            <div class="separator">&middot;</div>
          </template>
          <template v-slot:default="{ item }">
            <span
              class="sortable"
              :class="{ active: item == sort, reverse: item == sort && sortReverse }"
              @click="setSort(item)"
              >
              {{ item.name }}
            </span>
          </template>
        </separated-list>
      </div>
    </div>

    <BannerGrid :banners="banners.results" class="space-above"></BannerGrid><!-- :highlightProperty="sort && sort.name"  -->

    <p-paginator
      v-if="banners.numPages > 1"
      :first="banners.pageSize * (banners.page - 1)"
      :rows="banners.pageSize"
      :totalRecords="banners.count"
      @page="goPage"
      ></p-paginator>

    <site-footer />

  </div>
</template>

<script lang="ts">

const debounce = require('lodash.debounce');

import { icons } from '../lib/icons';

import { defineComponent, watchEffect, WatchStopHandle } from 'vue';
import { ISearchResults, IBanner, ICountryInfo, MissionInfo, IGeoAdminLevel } from '../../../shared/src/types';
import { BACKEND_KEY, BackendService } from '../services/backend-service';
import {
  formatTime,
  formatDistance,
  formatRating,
  formatEfficiency,
  formatAddress,
  parseBool,
  stripTrailingNulls,
} from '../lib/utils';

import BannerGrid from '../components/BannerGrid.vue';

interface ISortable {
  value: string;
  name: string;
  reverse: boolean;
}

function sortCountriesName(a: ICountryInfo, b: ICountryInfo): number {
  return (a.name || "").localeCompare(b.name || "");
}

function sortCountriesTotal(a: ICountryInfo, b: ICountryInfo): number {
  return b.count - a.count;
}

export default defineComponent({

  name: 'Banners',

  inject: [ 'state', BACKEND_KEY ],

  props: {
    mode: {
      type: String,
      default: 'banners'
    }
  },

  components: {
    BannerGrid
  },

  data() {
    return {

      leaving: false,

      geoAccordionIndex: null as null | number,
      showAllCountries: false,
      maxCountries: 17,

      countriesData: [] as ICountryInfo[],
      sortCountriesByName: false,

      requeryDebounced: Function,

      loading: false,

      filter: null as number | null,
      filterTitle: "",
      filterRequireToDo: false,
      filterExcludeCompleted: false,
      filterRequireFavorited: false,

      countryExpanderIndex: null as number | null,

      banners: { results: [] as IBanner[], count: 0, page: 0, pageSize: 1 } as ISearchResults<IBanner>,
      
      sortables: [
        { value: 'createdAt', name: 'Added', reverse: true },
        { value: 'title', name: 'Title', reverse: false },
        { value: 'numMissions', name: '#Missions', reverse: false },
        { value: 'numWaypoints', name: '#Waypoints', reverse: false },
        { value: 'numUniques', name: '#Uniques', reverse: true },
        { value: 'time', name: 'Time', reverse: false },
        { value: 'distance', name: 'Distance', reverse: false },
        { value: 'timeEfficiency', name: 'Efficency', reverse: true },
      ] as ISortable[],

      searchQuery: {} as any,

      icons

    }
  },

  computed: {

    rootPath(): string {
      return this.$route.path.startsWith("/banners") ? "/banners" : "/missions";
    },

    countries(): ICountryInfo[] {
      let all = [ ...this.countriesData ];
      all.sort(this.sortCountriesByName ? sortCountriesName : sortCountriesTotal);
      return all
        .filter(c => c.name != null); // there is apparently some record in here without a country name, in Crimea ?
    },

    filteredCountries(): ICountryInfo[] {
      return this.showAllCountries ? this.countries : this.countries.slice(0, this.maxCountries);
    },

    bcHome(): any {
      return {
        icon: 'fas fa-globe',
        label: ' ' + 'All countries',
        to: this.rootPath
      }
    },

    bcItems(): Array<any> {
      if (this.country) {
        let arr: any = [];
        let list: Array<any> = [];
        let geos = stripTrailingNulls(this.geo.map(g => g == "" || g == "-" ? null : g));
        geos.forEach((g,i) => {
          arr.push(g);
          if (g) {
            list.push({
              label: ' ' + g,
              icon: i == 0 ? "far fa-flag" : "", // "fas fa-puzzle-piece",
              to: this.rootPath + '/' + arr.map((g: any) => g == null ? "-" : g).join("/")
            })
          }
        })
        return list;
      } else return [];
    },

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
    },

    geoDrillLast(): Array<any> {
      let all = this.geoDrill;
      return all.length ? all[all.length - 1] : [];
    },

    geoDrill(): Array<any> {
      let list: Array<any> = [];
      let c = this.country;
      if (c) {

        if (!c.hierarchy) {
          this.backendService.getGeoCountry(c.name, this.mode).then(res => {
            console.log(res);
            c!.hierarchy = res;
          }).catch(err => {
            console.error("Error loading country geo details: " + err);
          });
          return [];
        }

        let cur = stripTrailingNulls(this.geo.map(g => g == "-" || g == "" ? null : g));
        let z = c.hierarchy!

        let arr: Array<string | null> = [ null, null, null, null, null, null, null, null, null ];
        let prev: any = null;
        for (let i = 0; cur.length > i; i++) {
          if (z == null) break;
          let c = cur[i];
          //console.log(`find ${cur[i]} in`, z);
          if (c == null) continue; // null levels
          let pz = z.find(x => x.name == cur[i]);
          //console.log("compare", i, cur[i], z, pz);
          if (pz) {
            if (prev != null) {
              let pa = prev.children.find((c: any) => c.name == pz!.name);
              if (pa != null) pa.active = true;
            }
            arr[pz.level] = pz.name;
            let carr = pz.children == null ? [] : pz.children.map(cz => {
              let cza = [ ...arr ];
              cza[cz.level] = cz.name;
              return { name: cz.name, count: cz.count, arr: cza };
            }) 
            prev = { arr: stripTrailingNulls(arr), children: carr, pz };
            list.push(prev);
            z = pz.children!;
            //console.log("NEXT LEVEL: " + z);
          } else {
            break;
          }
        }
      }
      //console.log("DRILL", list);
      return list;
    },

    geoFlat(): Array<any> {

      let list: Array<any> = [];

      function dive(level: IGeoAdminLevel, parent: Array<any>) {
        let here = [ ...parent ];
        here[level.level] = level.name;
        list.push({ array: here, geo: level });
        if (level.children) level.children.forEach(c => dive(c, here));
      }

      let c = this.country;
      if (c) {
        let cur = c.hierarchy?.[0]!;
        let init = [ null, null, null, null, null, null, null, null, null, null, null ];         
        dive(cur, init);
      }

      // console.dir(list);
      return list;
    },

    geo(): Array<string> {
      let p = this.$route.params;
      return [
        <string>p.country,
        <string>p.geo1,
        <string>p.geo2,
        <string>p.geo3,
        <string>p.geo4,
        <string>p.geo5,
        <string>p.geo6,
        <string>p.geo7,
        <string>p.geo8,
        <string>p.geo9
      ]
    },

    sort(): ISortable {
      let sortValue = <string>this.$route.query.sort;
      let sort = this.sortables.find(x => x.value == sortValue);
      return sort || this.sortables[0];
    },

    sortReverse(): boolean {
      let sortReverse = <string>this.$route.query.sortReverse;
      if (sortReverse == null) {
        return this.sort.reverse;
      } else {
        return parseBool(sortReverse);;
      }
    },

    country(): ICountryInfo | undefined {
      let id = (this.$route.params.country || "").toString();
      let c = this.countries.find(x => x.name == id);
      return c;
    },

    countryTreeNodes(): any {
      if (this.country?.hierarchy) {
        return this.makeTreeNodes(this.country!.hierarchy[0].children!);
      } else return [];
    },

    sortedBanners(): IBanner[] {
      return this.banners.results; // XXX sorting needs to be done server-side for pagination etc to work!
    },

    page(): number {
      let p = parseInt(<string>this.$route.query.page);
      return isNaN(p) ? 1 : Math.max(1, p);
    },

    query(): any {
      let query: any = {

        // sorting
        sort: this.sort?.value,
        sortReverse: this.sortReverse,

        // pagination
        page: this.page,

        // search
        ...this.searchQuery
      };

      return query;
    }

  },

  async mounted() {
    this.loading = true;
    try {
      this.loadQueryString();
      this.saveSearchQuery();
    } finally {
      this.requery();
    }
    this.countriesData = (await this.backendService.getGeoCountries(this.mode));
  },

  watch: {

    async query(to) {
      if (!this.leaving) { // for somer eason $route watchers trigger when leaving
        // console.log("QUERY UPDATED, REFRESHING..", to);
        this.requery();
      }
    }

  },

  created() {
    
    this.checkMobile();

    this.requeryDebounced = debounce(async () => {

      if (this.filterTitle != "" || this.filterExcludeCompleted || this.filterRequireFavorited || this.filterRequireToDo)
        this.filter = 0;

      let query = {
        ...this.query,
        pageSize: 50
      }

      let geo = stripTrailingNulls(this.geo.map(g => g == "-" || g == "" ? null : g));
      if (geo.length > 0) query.geo = geo;


      this.loading = true;
      try {
        let banners = await this.backendService.getBanners({ mode: this.mode, ...query });
        this.banners = banners;
      } catch (err) {
        alert("Error querying data: " + err.message);
      } finally {
        this.loading = false;
      }
    }, 250);

  },

  methods: {

    checkMobile() {
      let sm = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--primeflex-sm').trim());
      if (window.innerWidth >= sm) {
        let showGeo = this.$route.query.showGeo;
        // console.log("showGeo", showGeo);
        if (showGeo == null) {
          this.geoAccordionIndex = 0;
        } else {
          this.geoAccordionIndex = parseBool(showGeo) ? 0 : null;
        }
      }
    },

    async requery() {
      this.requeryDebounced();
    },

    goAllCountries() {
      this.update({ page: 1, showGeo: 1 }, {});
    },

    goCountryArray(a: Array<any>) {
      // console.log("GO", a);
      let params = [ "country", "geo1", "geo2", "geo3", "geo4", "geo5", "geo6", "geo7", "geo8", "geo9" ];
      let target: any = {};
      a = stripTrailingNulls(a);
      for (let i = 0; a.length > i; i++) {
        target[params[i]] = a[i] ?? "-";
      }
      // console.log(target);
      this.update({
        page: 1,
        showGeo: 1
      }, target);
    },

    goCountry(c: ICountryInfo) {
      this.update({
        page: 1,
        showGeo: 1
      }, {
        country: c.name
      })
    },

    goPage(e: any) {
      this.update({ page: e.page + 1});
      document.getElementById("pageTop")?.scrollIntoView();
    },

    saveSearchQuery() {
      this.searchQuery = {
        title: this.filterTitle,
        requireFavorited: this.filterRequireFavorited ? "1" : "0",
        excludeCompleted: this.filterExcludeCompleted ? "1" : "0",
        requireToDo: this.filterRequireToDo ? "1" : "0"
      }
    },

    loadQueryString() {
      this.filterTitle = <string>this.$route.query.title || "";
      this.filterRequireFavorited = parseBool(this.$route.query.requireFavorited);
      this.filterExcludeCompleted = parseBool(this.$route.query.filterExcludeCompleted);
      this.filterRequireToDo = parseBool(this.$route.query.filterRequireToDo);
    },  

    search() {
      this.saveSearchQuery();
      this.update();
    },

    makeTreeNodes(items: Array<IGeoAdminLevel>): Array<any> {
      return items.map(item => {
        let node: any = {
          key: item.name + '_' + item.level,
          label: item.name,
        };
        if (item.children) {
          node.children = this.makeTreeNodes(item.children);
        }
        return node;
      })
    },

    go(banner: IBanner) {
      this.$router.push({
        name: 'ViewBanner',
        params: <any>{
          bannerId: banner.guid
        }
      });
    },

    setSort(s: ISortable) {
      // console.log("SET SORT", this.sort.value, s.value);
      if (this.sort.value == s.value) {
        // console.log("TOGGLE SORT ORDER")
        this.update({
          ...this.query,
          sortReverse: !this.sortReverse
        })
      } else {
        this.update({
          ...this.query,
          sort: s.value,
          sortReverse: s.reverse
        })
      }
    },

    update(query: any = {}, params: any = null) {
      this.$router.push({ 
        name: <string>this.$route.name,
        params: params ? params : this.$route.params,
        query: {
          ... this.query,
          ...query
        }
      })
    },

    formatTime,

    formatDistance,

    formatAddress

  },

  beforeRouteLeave(to, from, next) {
    this.leaving = true;
    // console.log("BEFORE LEAVE", to, from );
    next()
  },

  beforeRouteUpdate(to, from, next) {
    // console.log("BEFORE UPDATE", to, from);
    next();
  }

});
</script>


<style lang="scss" scoped>

.geoSort {
  .active {
    font-weight: bold;
  }
}

.sortablesList {
 
  .separator {
    display: inline-block;
    padding: 0 0.5em;
  }

  .sortable {
    user-select: none;
    cursor: pointer;
    &.active {
      font-weight: bold;
      &:not(.reverse):after {
        content: "▲";
      }
      &.reverse:after {
        content: "▼";
      }
    }
  }  
  
}

.geoDrill {
  .active {
    font-weight: bold;
    background-color: var(--surface-300);
    text-decoration: underline;
    // &:before {
    //   content: "[ ";
    // }
    // &:after {
    //   content: "]";
    // }
  }
}

.filterOption {
  user-select: none;
  .icon {
    color: var(--text-color-secondary);
    padding-left: 0.5em;
  }
}

</style>

<style lang="scss" scoped>

.p-breadcrumb.brot {
  background-color: rgba(0,0,0,0);
  padding: 0;
  border: 0;
  border-radius: 0;
  background: inherit;
}

</style>
