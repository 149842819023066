<template>

  <div style="_background-color: red; width: 100%; height: 100%; overflow: hidden">
      <div style="display: flex; flex-direction: column; height: 100%; overflow: hidden">

        <!-- top -->

        <p-dialog :modal="true" :closeOnEscape="false" :closable="false" v-model:visible="loading">
          <template #header>
            Search
          </template>
          Loading missions, please wait..
        </p-dialog>

        <div style="flex: 0; padding: 1em">
          <div class="p-grid">
            <div class="p-col-12">
              Search for missions to add - ordering is done in the next step. Use * as a wildcard anywhere, e.g. <span class="exampleQuery">Oslo*</span> or <span class="exampleQuery">*Gothenburg*</span>
            </div>
          </div>
          <div class="x-split-pri-left">
            <p-input-text placeholder="Search by title, use * wildcards" v-model="filterTitle" @keydown.enter="search" />
            <div style="display: flex; flex-direction: row">
              <p-button icon="fas fa-search" @click="search" style="min-width: 3em" />
              <!--<p-button icon="fas fa-history" @click="searchRecent" style="margin-left: 0.5em; min-width: 3em" title="Search only missions you have recently refreshed" />-->
            </div>
          </div>

          <div class="p-grid space-above">
            <div class="p-col-12">
              <p-checkbox :binary="true" id="requireUnused" v-model="requireUnused" />
              <label for="requireUnused" title="Exclude missions that have been used in a banner already"> exclude missions already used in a banner</label>
            </div>
            <!--
            <div class="p-col-6">
              <p-checkbox :binary="true" id="requireRecent" v-model="requireRecent" /> 
              <label for="requireRecent" title="Limit search results to missions you have refreshed the details for recently (requires using API key)"> recently indexed by you via IITC</label>
            </div>
            -->
            <!-- <div class="p-col-4"><label title="Limit search results to missions you have marked as favorites"><p-checkbox :binary="true" v-model="requireFavorite" /> favorites only</label></div> -->
            <!--<div class="p-col-3"><label title="Exclude missions that have not been refreshed to fetch waypoint details"><p-checkbox :binary="true" :disabled="true" v-model="excludeNonDetailed" /> exclude non-detailed</label></div>-->
          </div>

          <!-- <div class="searching" v-if="loading">Searching...</div> -->
        </div>
        
        <!-- main part -->

        <div style="flex: 1; _background-color: gold; height: 100%; display: flex; flex-direction: row; overflow: hidden">

          <!-- left side -->

          <div style="flex: 20; height: 100%; _background-color: green; overflow: hidden; display: flex; flex-direction: column">

            <!-- search results -->

            <MissionList
              v-model:selection="leftSelection"
              style="flex: 1; overflow-y: auto; height: 100%"
              :missions="filteredMissions"
              :show-details="true"
              :selectable="true"
              :sortable="true"
              :show-type="false"
              :show-rating="false"
              :show-time="false"
              :show-waypoints="false"
              :show-intel="true"
              >
              <template #empty>
                <div v-if="filteredMissions.length == 0 && searchResults.results.length > 0" style="text-align: center">
                  <br>All the search results in this page have been added to the mission already.
                  <div v-if="searchResults.numPages > 1">(Remember to check the other search result pages!)</div>
                </div>
                <div v-else>
                </div>
              </template>
            </MissionList>

            <!-- pagination -->

            <div
              _v-if="searchResults.numPages > 1"
              style="text-align: center; flex: 0"
              >
              <p-paginator
                ref="paginator1"
                v-if="searchResults.numPages > 1"
                :first="0"
                :rows="searchResults.pageSize"
                :totalRecords="searchResults.count"
                @page="searchNext($event.page + 1)"
                />
              # Matches = {{searchResults.count}}
            </div>

          </div>

          <!-- middle part -->

          <div style="width: 64px; height: 100%; _background-color: cyan; display: flex; flex-direction: column; justify-content: center">
            <div style="text-align: center"><p-button :disabled="searchResults.results.length == 0" icon="fas fa-angle-double-right" title="Add all" @click="moveAllRight"></p-button><br><br></div>
            <div style="text-align: center"><p-button :disabled="leftSelection.length == 0" icon="fas fa-angle-right" title="Add" @click="moveRight"></p-button><br><br><br><br></div>
            <div style="text-align: center"><p-button :disabled="rightSelection.length == 0" icon="fas fa-angle-left" title="Remove" @click="moveLeft"></p-button><br><br></div>
            <div style="text-align: center"><p-button :disabled="bannerData.missions.length == 0" icon="fas fa-angle-double-left" title="Remove all" @click="moveAllLeft"></p-button></div>
          </div>

          <!-- right side --> 

          <div style="flex: 20; height: 100%; width: 100%; _background-color: green; overflow: hidden; display: flex; flex-direction: column">

            <!-- added missions -->

            <MissionList
              style="flex: 1; overflow-y: auto; height: 100%; width: 100%"
              v-model:missions="bannerData.missions"
              v-model:selection="rightSelection"
              :selectable="true"
              :allow-reorder="true"
              :sortable="true"
              :show-rating="false"
              :show-type="false"
              :show-time="false"
              :show-waypoints="false"
              :show-details="true"
              :show-intel="true"
              @row-dblclick="rightColumnDblClicked"
              />

            <!-- stats -->

            <div style="flex: 0; text-align: center">
              Number of missions: {{ bannerData.missions.length }}
            </div>

          </div>
        </div>
        
        <!-- bottom -->

        <div style="flex: 0; padding: 1em" class="p-grid">
          <div class="p-col-4 p-text-left">
            <p-button @click="$emit('goto-instructions')" label="Back" icon="fas fa-arrow-left" />
          </div>
          <div class="p-col-4 p-text-center">
            <p-button  v-if="mode == 'add'" @click="clearAll()" label="Clear all" icon="fas fa-trash" />
          </div>
          <div class="p-col-4 p-text-right">
            <p-button :disabled="bannerData.missions.length == 0" @click="$emit('goto-details')" label="Next" icon="fas fa-arrow-right" />
          </div>
        </div>
      </div>  
    </div>

</template>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IBanner, IMissionInfoDetailed, ISearchResults, MissionInfo } from '../../../shared/src/types';
import { formatTime, formatDistance, formatRating, formatAddress } from '../lib/utils';
import { BACKEND_KEY, BackendService, ISearchMissionsOptions } from '@/services/backend-service';

export default defineComponent({

  inject: [ BACKEND_KEY ],

  computed: {

    backendService(): BackendService {
      return (<any>this)[BACKEND_KEY];
      //return inject(BACKEND_KEY);
    },

    filteredMissions(): Array<MissionInfo> {
      let a = this.addedMissionsMap;
      //let f = new RegExp(this.filterTitle.trim(), "i");
      return this.searchResults.results.filter(m => {
        return ((this.filterIndexed && (<IMissionInfoDetailed>m).detailsModified) || !this.filterIndexed)
          //&& f.test(m.title)
          && !a[m.guid];
      })
    },

    addedMissionsMap(): {[guid: string]: MissionInfo} {
      let map: {[guid: string]: MissionInfo} = {};
      this.bannerData.missions!.forEach(m => map[m.guid] = m);
      return map;
    }

  },

  props: {
    step: {
      type: Number
    },
    mode: {
      type: String,
      default: 'add'
    },
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  watch: {
    banner: {
      immediate: true,
      handler(to) { this.bannerData = to; }
    }
  },

  data() {
    return {

      icons: Object.freeze(icons),
      bannerData: {} as IBanner,

      prevSearchOptions: {} as ISearchMissionsOptions,

      requireFavorite: false,
      requireRecent: false,
      requireUnused: true,
      excludeAlreadyUsed: false,
      excludeNonDetailed: true,

      loading: false,
      filterTitle: '',
      filterIndexed: true,
      searchResults: { results: [], count: 0, page: 0, pageSize: 1, numPages: 0 } as ISearchResults<MissionInfo>,

      leftSelection: [] as IMissionInfoDetailed[],
      rightSelection: [] as IMissionInfoDetailed[],

    }
  },

  methods: {

    formatTime,
    formatDistance,
    formatRating,
    formatAddress,

    clearAll() {
      if (confirm("Do you want to clear everything? (remove missions, clear title/description, etc?")) {
        this.bannerData.missions = [];
        this.bannerData.title = "";
        this.bannerData.description = "";
        this.$emit("update:banner", this.bannerData); // XXX: CLEAN UP THIS LATER.. well, this is not really bound to a temporary object so it's a b it useless
        this.leftSelection = [];
        this.rightSelection = [];
      }
    },

    async search() {
      this.prevSearchOptions = {};
      this.searchNext(0);
    },

    async searchNext(page: number) {
      this.loading = true;
      console.log("PAGE", page);
      try {      
        this.loading = true;
        this.searchResults = await this.backendService.searchMissions({
          ...this.prevSearchOptions,
          title: this.filterTitle,
          requireDetails: true,
          page: page,
          requireFavorite: this.requireFavorite,
          requireRecent: this.requireRecent,
          requireUnused: this.requireUnused
        });
      } finally {
        this.loading = false;
      }
    },

    moveAllRight() {
      this.searchResults.results.forEach(m => {
        let mr = this.bannerData.missions!.find(x => x.guid == m.guid);
        if (!mr)
          this.bannerData.missions!.push(<any>m)
      });
      this.leftSelection = [];
    },

    moveRight() {
      this.leftSelection.forEach(m => this.bannerData.missions!.push(m));
      this.leftSelection = [];
    },

    moveAllLeft() {
      this.bannerData.missions!.forEach(m => {
        if (!this.searchResults.results.find(x => x.guid == m.guid)) {
          this.searchResults.results.push(m);
        }
      })
      this.bannerData.missions = [];
      this.rightSelection = [];
    },

    moveLeft() {
      this.bannerData.missions = this.bannerData.missions!.filter(m => this.rightSelection.indexOf(m) == -1);
      this.rightSelection = [];
    },

    leftColumnDblClicked(m: IMissionInfoDetailed) {
      this.bannerData.missions!.push(m);
    },

    rightColumnDblClicked(m: IMissionInfoDetailed) {
      this.bannerData.missions = this.bannerData.missions!.filter(x => m != x);
      if (!this.searchResults.results.find(x => x.guid == m.guid)) {
        this.searchResults.results.push(m);
      }
    }
  }
});

</script>

<style lang="scss" scoped>

.fs {
  box-sizing: border-box;
  border: 1px solid green;
}

.space-above {
  padding-top: 1em;
}

</style>