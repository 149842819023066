<template>
  <div class="footer">&middot; site by <i>sspp0000xx</i>, but most importantly by <i>all of you</i> who have added banners! &middot;</div>
</template>

<style lang="scss" scoped>
.footer {
  color: #999;
  padding-top: 1em;
  text-align: center;
  font-size: 0.7em;
}
</style>

<script>
export default {
  name: 'SiteFooter'
}
</script>