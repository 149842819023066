import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Map from '../views/Map.vue';
import Map2 from '../views/Map2.vue';
import AddBanner from '../views/AddBanner.vue';
import EditBanner from '../views/EditBanner.vue';
import Banners from '../views/Banners.vue';
import ViewBanner from '../views/Banner.vue';
import BannerDetailsMissions from '../views/BannerDetailsMissions.vue';
import BannerDetailsMap from '../views/BannerDetailsMap.vue';
import BannerDetailsComments from '../views/BannerDetailsComments.vue';
import BannerDetailsExport from '../views/BannerDetailsExport.vue';
//import AddBannerSteps from '../views/AddBannerSteps.vue';
import AddBannerPreview from '../views/AddBannerPreview.vue';
import AddBannerDetails from '../views/AddBannerDetails.vue';
import AddBannerInstructions from '../views/AddBannerInstructions.vue';
import AddBannerSelectMissions from '../views/AddBannerSelectMissions.vue';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import EditProfile from '../views/EditProfile.vue';
import { inject } from 'vue';

import { IState, state } from '../state';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/banners',
    // name: 'Home',
    // component: Banners
  },
  {
    path: '/profile',
    name: 'EditProfile',
    component: EditProfile
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Log in'
    },
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/edit/:bannerId',
    name: 'Edit',
    component: AddBanner,
    props: { mode: 'edit' },
    children: [
      { path: 'instructions', component: AddBannerInstructions, props: { step: 0 }},
      { path: 'missions', component: AddBannerSelectMissions, props: { step: 1 }},
      { path: 'details', component: AddBannerDetails, props: { step: 2 }},
      { path: 'preview', component: AddBannerPreview, props: { step: 3 }},
      { path: '', redirect: to => {
          // console.log("redirect to edit details")
          return '/edit/' + to.params.bannerId + '/details'
        }
      }
    ]
  },
  {
    path: '/add_v2/:lat?/:lng?/:zoom?',
    name: 'AddV2',
    component: EditBanner
  },
  {
    path: '/add',
    name: 'Add',
    component: AddBanner,
    props: { mode: 'add' },
    children: [
      { path: 'instructions', component: AddBannerInstructions, props: { step: 0 }},
      { path: 'missions', component: AddBannerSelectMissions, props: { step: 1 }},
      { path: 'details', component: AddBannerDetails, props: { step: 2 }},
      { path: 'preview', component: AddBannerPreview, props: { step: 3 }},
      { path: '', redirect: to => {
          if (state && state.hideAddInstructions) {
            return '/add/missions'
          } else {
            return '/add/instructions'
          }
        }
      }
    ]
  },
  {
    path: '/banner/:bannerId',
    name: 'ViewBanner',
    redirect: to => {
      return '/banner/' + to.params.bannerId + '/missions'
    },
    component: ViewBanner,
    children: [
      { path: 'missions', component: BannerDetailsMissions },
      { path: 'map', component: BannerDetailsMap },
      { path: 'comments', component: BannerDetailsComments },
      { path: 'export', component: BannerDetailsExport },
      { path: '', redirect: to => {
        return '/banner/' + to.params.bannerId + '/missions'
      }}
    ]
  },
  {
    path: '/banners/:country?/:geo1?/:geo2?/:geo3?/:geo4?/:geo5?/:geo6?/:geo7?/:geo8?/:geo9?',
    name: 'Banners',
    component: Banners,
    props: {
      mode: 'banners'
    }
  },
  {
    path: '/missions/:country?/:geo1?/:geo2?/:geo3?/:geo4?/:geo5?/:geo6?/:geo7?/:geo8?/:geo9?',
    name: 'Missions',
    component: Banners,
    props: {
      mode: 'missions'
    }
  },
  {
    path: '/map0',
    name: 'Map0',
    component: Map
  },
  {
    path: '/map/:lat?/:lng?/:zoom?',
    name: 'Map',
    component: Map2
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router
