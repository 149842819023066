

import * as L from 'leaflet';
import { defineComponent, PropType } from 'vue';
import { IBanner } from '../../../shared/src/types';
import { icons } from '../lib/icons';
import { formatDistance } from '../lib/utils';

export default defineComponent({

  inject: [
    'layer', 'map'
  ],

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  data() {
    return {
      icons,
      frozen: { 
        marker: null! as L.Marker
      }
    }
  },

  mounted() {
    // console.log("marker loaded");
    let layer = (<any>this).layer() as L.LayerGroup<any>;
    let marker = L.marker(this.banner.point!, {
      icon: this.getIcon(this.banner)
    });
    marker.on('popupopen', ev => {
    })
    marker.bindPopup(<any>this.$refs.popup);
    layer.addLayer(marker);
    this.frozen = Object.freeze({ marker });
  },

  beforeUnmount() {
    // console.log("marker unloaded");
    let layer = (<any>this).layer() as L.LayerGroup<any>;
    layer.removeLayer(this.frozen.marker);
  },

  methods: {

    getIcon(banner: IBanner): L.Icon {

      let sz = 35;

      let completed = banner.userData && banner.userData.completedBy;
      let todo = banner.userData && banner.userData.toDoBy;
      let favorited = banner.userData && banner.userData.favoritedBy;

      // let fillColor = "hsl(0, 0%, 16%)";
      // let strokeColor = "hsl(170, 0%, 53%)";
      // let textColor = "hsl(170, 0%, 90%)";

      let hue = 0;
      let sat = 1;

      if (completed) {
        hue = 170;
      } else if (todo) {
        hue = 0;
      } else if (favorited) {
        hue = 45;
        sat = 0;
      } else {
        sat = 0;
      }

      let fillColor = `hsl(${hue}, ${27*sat}%, 45%)`;
      let strokeColor = `hsl(${hue}, ${27*sat}%, 75%)`;
      let textColor = `hsl(${hue}, ${69*sat}%, 100%)`;

      let svg = `<svg width="${sz}" height="${sz}" viewbox="-10 -10 120 120">
        <polygon
          points="50,0 0,25 0,75 50,100 100,75 100,25"
          style="fill: black; stroke: black; stroke-width: 20"
          />
        <polygon
          points="50,0 0,25 0,75 50,100 100,75 100,25"
          style="fill: ${fillColor}; stroke: ${strokeColor}; stroke-width: 10"
          />
        <text
          x="50"
          y="65"
          style="font: 45px sans-serif; fill: ${textColor}; text-anchor: middle"
          >${banner.numMissions}</text>
      </svg>`;

      var myIcon = L.divIcon({
        className: 'my-div-icon',
        iconSize: [ sz, sz ],
        iconAnchor: [ sz/2, sz/2 ],
        html: svg
      });

      return myIcon;

    },

    formatDistance,

    async toggleRoute(banner: IBanner) {

      /*

      let b = this.bannerDetails[banner.guid!];
      if (!b) {
        let bb = await this.backendService.getBanner(banner.guid!);
        b = this.bannerDetails[banner.guid!] = {
          banner: bb,
          routeLayerGroup: null!,
          routeVisible: false
        };
      }

      if (b.routeLayerGroup == null) {
        let lg = new L.LayerGroup<any>();
        let p: IMissionWaypointEx | undefined = undefined;

        let colors = colorRange(b.banner.missions!.length).map((c: string) => '#' + c);

        let lines: any[] = [];
        let connectors: any[] = [];

        b.banner.missions?.forEach((mission, i) => {
          let f = findFirstWaypoint(mission);
          if (p != null) {
            connectors.push([
              [ p!.point!.lat, p!.point!.lng ],
              [ f!.point!.lat, f!.point!.lng ]
            ]);
          }

          let waypoints = filterValidWaypoints(mission);
          let coords = waypoints.map(w => [ w.point.lat, w.point.lng ]);
          lines.push(coords);

          p = findLastWaypoint(mission);
        })

        let weight = 2;
        let weightExtra = 4;
        let radius = 4;
        let radiusExtra = 2;
        let bgColor = "black";
        lines.forEach((coords, i) => {
          let line = new L.Polyline(coords, { color: bgColor, weight: weight+weightExtra });
          lg.addLayer(line);
          let start = new L.CircleMarker(coords[0], {
            radius: radius+radiusExtra, color: bgColor, fillColor: bgColor, fill: true, fillOpacity: 1
          })
          lg.addLayer(start);
        });

        connectors.forEach(coords => {
          let cline = new L.Polyline(coords, { color: '#999', weight: weight, dashArray: '4,2' });
          lg.addLayer(cline);
        })

        lines.forEach((coords, i) => {
          let mission = b.banner.missions![i];
          let color = colors[i]
          let line = new L.Polyline(coords, { color: color, weight: weight });
          lg.addLayer(line);
          (<any>line).bindTooltip(mission.title);
          let start = new L.CircleMarker(coords[0], {
            radius: radius, color: color, fillColor: color, fill: true, fillOpacity: 1
          })
          lg.addLayer(start);
        });

        lg.addTo(this.frozen.map);
        b.routeLayerGroup = lg;
      } else {
        this.frozen.map.removeLayer(b.routeLayerGroup);
        b.routeLayerGroup = null!;
      }
      */

    }







  }

})

