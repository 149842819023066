

import { defineComponent, PropType } from 'vue';
import { IAgent } from '../../../shared/src/types';

export default defineComponent({

  name: 'AgentName',

  props: {
    agent: {
      type: Object as PropType<IAgent>,
      required: true
    }
  }

});

