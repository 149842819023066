<template>
  <table class="facts" style="width: 100%" v-if="banner">

    <tr class="newSection">
      <td><i :class="icons.agent"/> Created by</td>
      <td><div v-for="author in missionAuthors"><AgentName :agent="author" /></div></td>
    </tr>

    <tr class="newSection">
      <td><i :class="icons.order"/> Order</td>
      <td>
        <div class="orderList">
          <div v-for="item in missionOrders">{{ item }}</div>
        </div>
      </td>
    </tr>
    <tr>
      <td><i :class="icons.mission"/> Missions</td>
      <td>{{ banner.numMissions }}</td>
    </tr>
    <tr>
      <td><i :class="icons.banner"/> Rows</td>
      <td>{{ Math.ceil(banner.numMissions / banner.rowSize) }}</td>
    </tr>

    <tr class="newSection">
      <td><i :class="icons.geo"/> Location</td>
      <td>
        <div class="geoList">
          <div v-for="item in formatAddress2(banner)">
            <a :href="item.link">{{ item.name }}</a>
          </div>
        </div>
      </td>
    </tr>

    <tr class="newSection">
      <td><i :class="icons.distance"/> Distance</td>
      <td>{{ formatDistance(banner.distance) }}</td>
    </tr>
    <tr class="indent">
      <td>Distance last-first</td>
      <td>{{ formatDistance(banner.distanceEndsBegins) }}</td>
    </tr>
    <tr>
      <td><i :class="icons.time"/> Time (avg)</td>
      <td>{{ formatTime(banner.time) }}</td>
    </tr>

    <tr class="newSection">
      <td><i :class="icons.efficiency"/> Efficiency</td>
      <td></td>
    </tr>
    <tr class="indent">
      <td><i :class="icons.time"/> Time</td>
      <td>{{ formatEfficiency(banner.timeEfficiency) }}</td>
    </tr>
    <tr class="indent">
      <td><i :class="icons.distance"/> Distance</td>
      <td>{{ formatEfficiency(banner.distanceEfficiency / 1000) }}</td>
    </tr>

    <tr class="newSection">
      <td><i :class="icons.waypoint"/> Waypoints</td>
      <td>{{ banner.numWaypoints }}</td>
    </tr>
    <tr class="indent" v-for="(count, tx) in filteredWaypointObjectivesStats(banner.numWaypointObjectives)" :key="`wo-stats-${tx}`">
      <td>{{ tx }}</td>
      <td>{{ count }}</td>
    </tr>
    <tr>
      <td><i :class="icons.uniques"/> Uniques</td>
      <td>{{ banner.numUniques }}</td>
    </tr>

    <tr class="newSection">
      <td><i :class="icons.completed"/> Completed (avg)</td>
      <td>{{ Math.round(avgNumCompleted) }}</td>
    </tr>
    <tr>
      <td><i :class="icons.rating"/> Rating (avg)</td>
      <td>{{ formatRating(avgRating) }}</td>
    </tr>

  </table>

</template>

<style lang="scss" scoped>

table.facts {
  tr {
    vertical-align: top;
    &.indent {
      color: var(--text-color-secondary);
      td:first-child {
        padding-left: 1.25em;
      }
    }
    td {
      font-size: 0.9em;
    }
    td:first-child {
      text-align: left;
      padding-right: 1em;
    }
    td:last-child {
      text-align: right;
    }
    &.newSection {
      td {
        padding-top: 1em;
      }
    }
  }
}

</style>

<script lang="ts">

import { icons } from '../lib/icons';
import { formatDistance, formatTime, formatRating, formatEfficiency, formatAddress, formatAddress2, filteredWaypointObjectivesStats } from '../lib/utils';
import { IBanner, MissionOrder, IAgent, MissionOrderTexts } from '../../../shared/src/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  name: 'BannerStatsTable',

  components: {
  },

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  data() {
    return {
      icons
    }
  },

  computed: {

    avgNumCompleted(): number {
      let sum = this.banner!.missions!.reduce((p,m) => p + m.numCompleted, 0) as number;
      return sum / this.banner!.numMissions!;
    },

    avgRating(): number {
      let sum = this.banner!.missions!.reduce((p,m) => p + m.rating, 0) as number;
      return sum / this.banner!.numMissions!;
    },

    missionAuthors(): IAgent[] {
      let authors: {[name: string]: IAgent} = {};
      this.banner?.missions?.forEach(m => {
        authors[m.author.nickname] = m.author;
      })
      return Object.values(authors);
    },

    missionOrders(): string[] {
      let orders: any = {};
      this.banner?.missions?.forEach(m => {
        orders[m.type!] = m.type;
      })
      return Object.values(orders).map(o => MissionOrderTexts[<MissionOrder><unknown>o]);
    }
    
  },

  methods: {

    filteredWaypointObjectivesStats,
    formatDistance,
    formatTime,
    formatEfficiency,
    formatRating,
    formatAddress,
    formatAddress2

  }

})

</script>
