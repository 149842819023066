<template>
  <div class="p-grid banner_" :class="{ favorite: banner.userData?.favoritedBy, completed: banner.userData?.completedBy, todo: banner.userData?.toDoBy }">

    <FlexDetect v-model:size="flexSize" />

    <div class="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6">

      <div class="titl">
        <a :href="link">{{ banner.title }}</a>
        <div
          class="userPrefs"
          v-if="state && state.user && banner.userData"
          @click="$refs.op.toggle($event)"><i class="fas fa-cog" />
        </div>
      </div>

      <separated-list :items="formatAddress2(banner, 4)" class="geo">
        <template #default="{item}">
          <a :href="item.link">{{ item.name }}</a>
        </template>
      </separated-list>

      <div class="p-grid p-nogutter stats">
        <div class="p-col-6"><i :class="icons.mission"></i> {{ banner.numMissions }}</div>
        <div class="p-col-6"><i :class="icons.distance"></i> {{ formatDistance(banner.distance) }}</div>
        <div class="p-col-6"><i :class="icons.waypoint"></i> {{ banner.numWaypoints }} <span class="numUniques">({{ banner.numUniques }})</span></div>
        <div class="p-col-6"><i :class="icons.time"></i> {{ formatTime(banner.time) }}</div>
        <div class="p-col-6"><i :class="icons.efficiency"></i> D {{ formatEfficiency(banner.distanceEfficiency / 1000) }} &middot; T {{ formatEfficiency(banner.timeEfficiency) }}</div>
      </div>

      <BannerToolsPopup ref="op" :banner="banner" />
    </div>

    <a :href="link" class="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6">
      <BannerImage
        style="width: 100%"
        class="x-clickable"
        :banner="banner"
        :maxHeight="maxBannerHeight[flexSize]"
        :auto="true"
        :fillHeight="true"
        ></BannerImage>
    </a>
  </div>

</template>

<script lang="ts">

import { IBanner } from '../../../shared/src/types';
import { defineComponent, PropType } from 'vue';
import { formatTime, formatDistance, formatAddress, formatAddress2, formatRating, formatEfficiency  } from '../lib/utils';
import { icons } from '../lib/icons';

export default defineComponent({

  name: 'BannerGridEntry',

  components: {
  },  

  inject: [ 'state' ],

  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },

  data() {
    return {
      icons,
      flexSize: 'xl',
      maxBannerHeight: {
        'xl': '10em',
        'lg': '12em',
        'md': '12em',
        'sm': '20em',
        'xs': '20em',
      }
    }
  },

  computed: {
    link(): string {
      return `/banner/${this.banner.slug || this.banner.guid}`;
    }
  },

  methods: {

    select() {
      this.$emit('select', this.banner);
    },  

    formatTime,
    formatDistance,
    formatEfficiency,
    formatAddress,
    formatAddress2,
    formatRating,

  }

})

</script>

<style lang="scss" scoped>

$completed-color: rgba(0,255,0,0.05);
$todo-color: rgba(255, 132, 0, 0.10);
$bar-width: 15px;

@mixin gradient($color) {
  background: linear-gradient(135deg, $color 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 100%);
}

.banner_ { // because .banner is used by something else...?

  width: 100%;
  
  &.favorite {
    @include gradient(rgba(255,128,255,0.15));
  }

  &.todo {
    @include gradient(rgba(255,192,0,0.15));
  }

  &.completed {
    @include gradient(rgba(0,255,0,0.15));
  }

  .geo {
    padding-top: 0.5em;
    font-size: 0.8em;
    //color: var(--text-color-secondary);
  }

  .stats {
    padding-top: 0.5em;
    font-size: 0.8em;
    color: var(--text-color-secondary);
  }

}

.userPrefs {
  padding-left: 0.5em;
  cursor: pointer;
  color: var(--text-color-secondary);
  display: inline-block;
  width: 1.5em;
  font-size: 0.75em;
}

</style>