<template>

  <div v-if="banner">
    <div v-if="comments.length">
      <p-panel class="review x-space-below" v-for="review in banner.reviews" :key="`review-` + review.createdBy">
        <template #header>
          <div class="p-grid" style="width: 100%">
            <agent-name class="p-col-8" :agent="lookupUser(review.createdBy)" /><br>
            <small class="p-col-4 p-text-right x-text-secondary">{{ formatDate(review.createdAt) }}</small>
          </div>
        </template>
        <div class="p-grid">
          <div class="p-col-12"></div>
          <div class="p-col-12 message" v-html="formatText(review.comment)"></div>
        </div>
      </p-panel>
    </div>
    <div v-else>
      No comments!
    </div>
  </div>
  
</template>

<script lang="ts">

import { defineComponent, PropType } from 'vue';
import { icons } from '../lib/icons';
import { IAgent, IBanner, ICompletionReview, IUser, filterCommentReviews } from '../../../shared/src/types';

import { format, formatDistanceToNow } from 'date-fns'

export default defineComponent({
  props: {
    banner: {
      type: Object as PropType<IBanner>,
      required: true
    }
  },
  computed: {
    comments(): ICompletionReview[] {
      return filterCommentReviews(this.banner?.reviews);
    }
  },
  data() {
    return {
      icons: Object.freeze(icons)
    }
  },
  methods: {

    formatText(s: string): string {
      return (s || "").replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/(\r\n|\r|\n)/g, "<br>");
    },

    formatDate(n: number): string {
      return formatDistanceToNow(n) + " ago";
    },

    lookupUser(userId: string): IAgent {
      let user: IUser = this.banner.$refs?.users?.[userId]!;
      if (user) {
        return <IAgent>{
          nickname: user.agentName,
          team: user.agentFaction
        }
      } else {
        return <IAgent>{}
      }
    }
  }
});

</script>

<style lang="scss" scoped>

.review {
  .time {
  }
}

</style>